@charset "UTF-8";
@use 'sass:color';
@use '@craftcms/sass/mixins';

$base: 24px;
$tabPadding: 14px;
$tabWidth: $base * 11;
$gridColor: var(--gray-100);

@mixin workspace-bg {
  background-color: var(--gray-050);
  background-image: linear-gradient(to right, $gridColor 1px, transparent 0),
    linear-gradient(to bottom, $gridColor 1px, transparent 1px);
  background-size: $base $base;
}

.layoutdesigner {
  container-type: inline-size;
}

.fld-container {
  display: flex;
  align-items: stretch;
  position: relative;
  @include mixins.input-styles;
  overflow: hidden;
  box-shadow: none;
  padding-inline-end: $tabWidth + 1;
  min-height: 500px;

  .errors > & {
    border: 1px solid var(--error-color) !important;
  }

  .fld-workspace {
    flex: 1;
    border-start-start-radius: calc(var(--small-border-radius) - 1px);
    border-start-end-radius: 0;
    border-end-end-radius: 0;
    border-end-start-radius: calc(var(--small-border-radius) - 1px);
    padding-inline: $base 0;
    padding-block: $base;
    @include workspace-bg;
    background-position: -1px -1px;
    box-shadow: inset 0 1px 3px -1px color.adjust(mixins.$grey200, $lightness: -10%);

    .fld-tabs {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
    }
  }

  &:not(:has(.fld-library)) {
    padding-inline-end: 0;

    .fld-workspace {
      padding-inline-end: $base;
    }
  }

  .fld-library {
    position: absolute;
    inset-block-start: 0;
    inset-inline-end: 0;
    height: 100%;
    width: $tabWidth + 1;
    padding: $tabPadding;
  }
}

.fld-new-tab-btn:active {
  background-color: var(--gray-050);
}

.fld-library-menu {
  width: $tabWidth;
  min-height: 200px;
  margin-inline-start: $tabPadding * -1;
  padding: $tabPadding;
}

.fld-library {
  display: flex;
  flex-direction: column;

  .btngroup {
    margin-block-end: $tabPadding;
  }

  .fld-field-library {
    margin: -3px;
    padding: 3px;
    flex: 1;
    min-height: 0;
    overflow: auto;

    .fld-field-group {
      margin-block-start: $tabPadding;

      & > *:not(:first-child) {
        margin-block-start: var(--s);
      }
    }
  }

  .fld-ui-library > *:not(:first-child) {
    margin-block-start: var(--s);
  }

  .filtered {
    display: none;
  }
}

.layoutdesigner .fld-library,
.fld-tab .tabs .tab,
.fld-tab .fld-tabcontent,
.fld-new-tab-btn,
.fld-new-tab-btn:hover {
  background-color: var(--white);
  box-shadow:
    0 0 0 1px color.adjust(mixins.$grey900, $alpha: -0.9),
    0 2px 5px -2px color.adjust(mixins.$grey900, $alpha: -0.8);
}

.fld-tab .settings::before,
.fld-element .settings::before {
  margin-block-start: -2px;
  font-size: 16px;
  opacity: 0.5;
}

.fld-tab .settings:hover::before,
.fld-tab .settings.active::before,
.fld-element .settings:hover::before,
.fld-element .settings.active::before {
  opacity: 1;
}

.fld-tab {
  width: $tabWidth + $base;
  padding-inline: 0 $base + 1;
  padding-block: 0 $base;
  box-sizing: border-box;

  .tabs {
    margin-block: -10px 0;
    margin-inline: -12px;
    padding-block: 10px 0;
    padding-inline: 12px;
    overflow: hidden;
    display: flex;

    .tab {
      display: flex;
      align-items: center;
      gap: var(--xs);
      max-width: calc(100% - 10px);
      box-sizing: border-box;
      padding-block: 8px;
      padding-inline: $tabPadding;
      border-radius: var(--medium-border-radius) var(--medium-border-radius) 0 0;

      body:not(.dragging) &.draggable {
        cursor: move;
        cursor: grab;
      }

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .fld-tabcontent {
    padding: $tabPadding;
    border-start-start-radius: 0;
    border-start-end-radius: var(--medium-border-radius);
    border-end-end-radius: var(--medium-border-radius);
    border-end-start-radius: var(--medium-border-radius);

    & > .fld-element,
    & > .fld-add-btn {
      &:not(:first-child) {
        margin-block-start: var(--s);
      }
    }

    .fld-add-btn:not([aria-expanded='true']) {
      display: none;
    }
  }

  &.fld-insertion {
    .tabs .tab,
    .fld-tabcontent {
      margin: -2px;
      border: 2px dashed var(--hairline-color);
      box-shadow: none;
      @include workspace-bg;
    }

    .tabs .tab {
      background-position: -1px -1px;
    }

    .fld-tabcontent {
      background-position: -1px -13px;
    }
  }
}

.fld-tab-caboose {
  min-height: 24px;
}

.fld-element {
  position: relative;
  display: flex;
  align-items: center;
  padding: var(--s);
  gap: var(--s);
  box-shadow: inset 0 0 0 1px var(--hairline-color);
  border-radius: var(--medium-border-radius);
  background-color: var(--white);

  body:not(.dragging) & {
    cursor: move;
    cursor: grab;
  }

  &.fld-insertion {
    box-sizing: border-box;
    border: 2px dashed var(--hairline-color);
    border-radius: var(--medium-border-radius);
    background: none;
    box-shadow: none;
  }

  &.draghelper {
    @include mixins.shadow;
  }

  &.fld-field {
    color: var(--medium-text-color);
    background-color: var(--gray-100);

    &:not(.draghelper) {
      box-shadow: none;
    }

    .field-name {
      display: flex;
      flex-direction: column;
      gap: var(--xs);
    }
  }

  .fld-element-icon {
    text-align: center;

    &,
    svg {
      width: 16px;
      height: 16px;
    }

    svg {
      @include mixins.svg-mask(var(--ui-control-color));
    }
  }

  .field-name {
    flex: 1;
    overflow: hidden;

    .fld-element-label,
    .fld-attribute {
      flex: 1;
      display: flex;
      align-items: center;
      gap: var(--xs);
    }

    .fld-element-label h4,
    .fld-attribute .smalltext {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .fld-element-label h4 {
      font-weight: normal;
      color: var(--text-color);
      margin: 0;
    }
  }
}

.fld-hr,
.fld-br {
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;

  &::before {
    position: absolute;
    display: block;
    inset-block-start: calc(50% - 2px);
    inset-inline-start: 0;
    width: 100%;
    height: 4px;
    content: '';
    font-size: 0;
    border-radius: 2px;
  }

  .smalltext {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--gray-100);
    border-radius: var(--large-border-radius);
    padding-block: 0;
    padding-inline: var(--s);
    height: var(--touch-target-size);
  }
}

.fld-hr::before {
  background-color: var(--gray-100);
}

.fld-br::before {
  background-image: repeating-linear-gradient(
    to right,
    var(--gray-100),
    var(--gray-100) calc(100% / 19),
    transparent calc(100% / 19),
    transparent calc(100% / 9.5),
    var(--gray-100) calc(100% / 9.5)
  );
}

.fld-element-settings-body {
  flex: 1;
  margin-block: -24px 0;
  margin-inline: var(--neg-padding);
  padding-block: 24px;
  padding-inline: var(--padding);
  overflow: hidden auto;
  position: relative;
}

.fld-element-settings-footer {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-block: 0 -24px;
  margin-inline: var(--neg-padding);
  padding-block: 5px;
  padding-inline: var(--padding);
  @include mixins.pane;
  background-color: var(--gray-050);
  z-index: 3;

  & > .ee-site-select {
    flex: 1;
  }

  & > .btn {
    margin-inline-start: 5px;
  }

  & > .spinner {
    margin-inline: 0 var(--neg-padding);
    margin-block: 0 0;
  }
}

@container (width < #{$base + $tabWidth + $base + $tabWidth + 1}) {
  .fld-container {
    padding-inline-end: 0;

    .fld-workspace {
      padding-inline-end: $base;
    }

    & > .fld-library {
      display: none;
    }
  }

  .fld-tab {
    .fld-tabcontent {
      .fld-add-btn {
        display: inline-flex !important;
      }
    }
  }
}
