.craft-tooltip {
  position: fixed;
  white-space: normal;
  opacity: 0;
  transform: translateY(-4px);
  width: max-content;
  max-width: 240px;
  transition:
    opacity 100ms ease-in-out,
    transform 150ms ease-in-out;
  /* stylelint-disable */
  /* We need to keep these non-logical so floating-ui can override them */
  top: 0;
  left: 0;
  /* stylelint-enable */
  z-index: 99;
}

.craft-tooltip > .inner {
  position: relative;
  display: inline-block;
  background-color: var(--white);
  color: var(--gray-700);
  border: 1px solid var(--dark-hairline-color);
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  border-radius: var(--medium-border-radius);
  padding-block: var(--xs);
  padding-inline: var(--s);
  pointer-events: none;
  user-select: none;
  font-weight: 400;
}

.craft-tooltip .arrow {
  position: absolute;
  background: var(--white);
  width: 8px;
  height: 8px;
  transform: rotate(45deg);
  border-style: solid;
  border-width: 1px;
  border-color: transparent;

  // Not using logical properties here because `data-placement` will change based on direction via JS
  &[data-placement^='bottom'] {
    border-color: var(--dark-hairline-color) transparent transparent
      var(--dark-hairline-color);
  }

  &[data-placement^='top'] {
    border-color: transparent var(--dark-hairline-color)
      var(--dark-hairline-color) transparent;
  }

  &[data-placement^='right'] {
    border-color: transparent transparent var(--dark-hairline-color)
      var(--dark-hairline-color);
  }

  &[data-placement^='left'] {
    border-color: var(--dark-hairline-color) var(--dark-hairline-color)
      transparent transparent;
  }
}
