@charset "UTF-8";

@import '@craftcms/sass/mixins';

.cp-chart {
  margin: 0;
  height: 100%;
  position: relative;
}

.cp-chart svg {
  width: 100%;
  height: 100%;
}

.cp-chart .axis path,
.cp-chart .axis line {
  fill: none;
  stroke: var(--hairline-color);
  shape-rendering: crispedges;
}

.cp-chart .ticks-axis path,
.cp-chart .ticks-axis line {
  fill: none;
  stroke: none;
  shape-rendering: crispedges;
}

.cp-chart .ticks-axis g.tick text {
  fill: var(--black);
  font-size: 11px;
}

.cp-chart .axis text {
  fill: rgb(0 0 0 / 60%);
}

.cp-chart .grid-line .tick {
  stroke: rgb(0 0 0 / 8%);
  stroke-opacity: 0.7;
  shape-rendering: crispedges;
}

.cp-chart .grid-line .tick line {
  stroke: rgb(0 0 0 / 8%);
  stroke-opacity: 0.7;
  shape-rendering: crispedges;
}

.cp-chart .grid-line path {
  stroke-width: 0;
}

.tooltip {
  border-radius: 3px;
  padding-block: 7px;
  padding-inline: 10px;
  background: transparentize($white, 0.1);
  position: absolute;
  z-index: 1000000;
  @include shadow;
  user-select: none;
  pointer-events: none;
}
