@charset "UTF-8";
@use 'sass:color';
@use '@craftcms/sass/mixins';

.lp-editor-container,
.lp-preview-container {
  position: fixed;
  inset-block-start: 0;
  height: 100%;
  background-color: var(--white);
  z-index: 100;

  & > header {
    padding-block: 5px;
    padding-inline: var(--pane-padding, var(--xl));
    background-color: var(--gray-100);
    box-shadow: 0 1px 0 color.adjust(mixins.$grey700, $alpha: -0.8);
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    height: 44px;

    @supports (backdrop-filter: blur(10px)) {
      & {
        position: absolute;
        z-index: 2;
        inset-block-start: 0;
        inset-inline-start: 0;
        width: 100%;
        background-color: color.adjust(mixins.$grey100, $alpha: -0.25);
        backdrop-filter: blur(10px);

        & + .lp-content {
          padding-block-start: #{44 + 24}px;
        }
      }
    }

    .btn,
    .spinner,
    .checkmark-icon {
      margin-block-end: 0;
    }

    .btn[data-icon='xmark'] {
      margin-inline: calc(-12rem / 16) calc(var(--s) * -1);

      &:not(:hover, :active, [aria-expanded='true']) {
        background-color: transparent;
      }
    }

    .lp-toolbar {
      min-width: 0;
      margin-inline-end: -1rem;

      .pane-tabs {
        margin-inline: 0 !important;

        [role='tablist'] {
          padding-block: 0;
          padding-inline: 12px;

          &.scrollable {
            mask-image: linear-gradient(
              to right,
              rgb(0 0 0 / 0%),
              rgb(0 0 0) 12px,
              rgb(0 0 0) calc(100% - 12px),
              rgb(0 0 0 / 0%) 100%
            );
          }

          [role='tab'] {
            background-color: transparent;
          }
        }

        .menubtn:not(.action-btn) {
          --focus-ring: var(--inner-focus-ring);
          margin-inline: 0;

          &:not(:empty, .btn-empty)::after {
            margin-inline-start: 0;
          }
        }
      }
    }
  }
}

.lp-editor-container {
  display: flex;
  flex-direction: column;

  .lp-content {
    flex: 1;
    padding: var(--pane-padding, var(--xl));
    overflow: auto;
    box-sizing: border-box;
    position: relative;

    &:not(.loading) > .spinner {
      display: none;
    }

    &.loading > .spinner {
      position: absolute;
      inset-block-start: calc(50% - 17px);
      inset-inline-start: calc(50% - 12px);
      color: var(--ui-control-color);
    }

    .matrixblock > .fields > .flex-fields > .field > .status-badge {
      inset-inline-start: calc(var(--m) * -1);
    }
  }

  .lp-draghandle {
    position: absolute;
    z-index: 101;
    inset-block-start: 0;
    inset-inline-end: -2px;
    width: 4px;
    height: 100%;
    cursor: col-resize;
  }

  & > footer {
    padding-block: 5px;
    padding-inline: var(--pane-padding, var(--xl));
    box-shadow: inset 0 1px 0 var(--hairline-color);
    background-color: var(--gray-100);
    justify-content: end;
    height: 44px;
  }
}

.lp-preview-container {
  display: flex;
  flex-direction: column;
  box-shadow: -1px 0 0 color.adjust(mixins.$grey700, $alpha: -0.8);

  &.dragging::after {
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 0;
    width: 100%;
    height: 100%;
    content: '';
    font-size: 0;
  }

  .lp-preview-header {
    display: flex;

    @supports (not (backdrop-filter: blur(10px))) {
      background-color: color.adjust(mixins.$grey100, $alpha: -0.25);
    }

    .buttons {
      margin: 0;

      .btn + .btn {
        margin-inline-start: 7px;
      }
    }

    & + .lp-iframe-container {
      padding-block-start: 0;

      @supports (backdrop-filter: blur(10px)) {
        padding-block-start: 44px;
      }
    }

    .lp-device-type {
      .btn {
        &::before {
          width: 27px;
          height: 22px;
          display: block;
          content: '';
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          transition-duration: 0.3s;
        }

        // SVGs
        &.lp-device-type-btn--phone::before {
          background-image: url('../images/preview/icon-phone.svg');
        }

        &.lp-device-type-btn--tablet::before {
          background-image: url('../images/preview/icon-tablet.svg');
        }

        &.lp-device-type-btn--desktop::before {
          background-image: url('../images/preview/icon-desktop.svg');
        }
      }
    }
  }

  .lp-device-type__label {
    &::before {
      width: 27px;
      height: 22px;
      display: block;
      content: '';
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      transition-duration: 0.3s;
    }

    // SVGs
    &.lp-device-type__label--phone::before {
      background-image: url('../images/preview/icon-phone.svg');
    }

    &.lp-device-type__label--tablet::before {
      background-image: url('../images/preview/icon-tablet.svg');
    }

    &.lp-device-type__label--desktop::before {
      background-image: url('../images/preview/icon-desktop.svg');
    }
  }

  .lp-preview-container__bumper-link {
    inset-block: unset 0;
  }

  .lp-device-mask {
    display: none;
  }

  .lp-iframe-container {
    flex: 1;
    overflow: auto;

    .lp-preview {
      display: block;
      width: 100%;
      min-height: 100%;
    }

    &:not(.lp-iframe-container--has-device-preview) {
      // Preview container
      .lp-device-preview-container {
        width: auto !important;
        height: 100% !important;
        transform: none !important;
        margin: 0 !important;
      }
    }

    &--has-device-preview {
      flex: none;
      padding-block-start: 0 !important;
      position: relative;
      inset-block-start: 0;
      height: calc(100% - 44px);
      width: 100%;
      background-color: var(--gray-100);

      @supports (backdrop-filter: blur(10px)) {
        inset-block-start: 44px;
      }

      // Preview container
      .lp-device-preview-container {
        position: absolute;
        inset-block-start: 50%;
        inset-inline-start: 50%;
        z-index: 2;
        overflow: auto;
        opacity: 1;
        transition: opacity 0.3s;
      }

      // Device mask
      .lp-device-mask {
        display: block;
        position: absolute;
        inset-block-start: 50%;
        inset-inline-start: 50%;
        z-index: 1;
        background-image: url('../images/preview/chrome-phone-top.svg'),
          url('../images/preview/chrome-phone-bottom.svg');
        background-position:
          top center,
          bottom center;
        background-repeat: no-repeat;
        background-size: contain;
        background-color: #fff;
        box-shadow: 0 0 58px rgb(6 67 117 / 30%);
        border-radius: 18px;
      }

      // Tablet
      &.lp-iframe-container--tablet {
        .lp-device-mask {
          background-image: url('../images/preview/chrome-tablet-top.svg'),
            url('../images/preview/chrome-tablet-bottom.svg');
        }
      }
    }

    // Animate rotations
    @media screen and (prefers-reduced-motion: no-preference) {
      &--rotating {
        .lp-device-mask {
          transition-duration: 0.3s;
          transition-property: transform;
        }

        .lp-device-preview-container {
          opacity: 0;
          transition: opacity 0s;
        }
      }
    }
  }
}
