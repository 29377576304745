@charset "UTF-8";
@use 'sass:color';
@use '@craftcms/sass/mixins';

$sidebarWidth: calc(226rem / 16);
$sidebarZIndex: 100;
$detailsWidth: 350px;
$minFullUiWidth: calc(1200rem / 16);
$minXlUiWidth: calc(1536rem / 16);
$minDesktopWidth: calc(600rem / 16);
$minHorizontalUiWidth: $minFullUiWidth - $sidebarWidth;

// Colors
$badgeBackgroundColor: var(--gray-200);
$badgeColor: var(--gray-800);

:root {
  --2xs: 2px;
  --xs: 4px;
  --s: 8px;
  --m: 14px;
  --l: 18px;
  --xl: 24px;
  --padding: var(--xl);
  --neg-padding: calc(var(--padding) * -1);
  --sidebar-width: #{$sidebarWidth};
  --global-sidebar-width: #{$sidebarWidth};
  --header-height: calc(44rem / 16);
  --details-width: #{$detailsWidth};

  // Sidebar
  --sidebar-bgColor: var(--gray-150);

  // Nav items
  --nav-item-indicator-size: var(--xs);
  --nav-item-badge-bgColor: var(--medium-text-color);
  --nav-item-badge-fgColor: var(--white);
  --nav-item-fgColor-active: var(--text-color);
  --nav-item-bgColor-active: var(--gray-200);
  --nav-item-fgColor-hover: var(--text-color);
  --nav-item-bgColor-hover: var(--gray-200);
  --nav-item-prefix-width: calc(30rem / 16);
  --nav-item-prefix-ratio: 1;
  --nav-item-trigger-size: var(--touch-target-size);
  --nav-item-gutter-width: calc(10rem / 16); // Left and right padding
}

html {
  text-size-adjust: 100%;
  min-height: 100vh;
}

body {
  min-height: 100vh;

  &.dragging * {
    cursor: move !important;
    cursor: grabbing !important;
  }

  :focus {
    outline-style: solid;
    outline-color: transparent;
    box-shadow: var(--focus-ring);
  }
}

.reduce-focus-visibility {
  :focus:not(:focus-visible) {
    box-shadow: none;
  }

  :focus-visible {
    box-shadow: var(--focus-ring);
  }
}

.skip-link {
  position: absolute;
  margin: 5px;
  inset-inline-start: -10000px;
  inset-block-start: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  z-index: $sidebarZIndex + 1;

  &:focus {
    inset-inline-start: unset;
    inset-inline-start: 0;
    width: auto;
    height: auto;
    background-color: var(--gray-100) !important;
  }
}

#global-skip-link {
  @media only screen and (min-width: $minFullUiWidth) {
    @include mixins.two-color-focus-ring;
  }
}

#global-container {
  position: relative;
  display: flex;
  min-height: 100vh;

  @media screen and (prefers-reduced-motion: no-preference) {
    transition: inset-inline-start 100ms cubic-bezier(0.33, 1, 0.68, 1);
  }
}

#global-header {
  display: grid;
  grid-template-columns: 1fr min-content min-content;
  align-items: center;
}

/* global sidebar */
$sidebarLinkStaticColor: var(--gray-100);
$sidebarLinkSecondaryColor: var(--gray-200);
$systemInfoBgColor: color.adjust(mixins.$grey800, $lightness: -5%);
$systemInfoHoverBgColor: color.adjust(mixins.$grey800, $lightness: -10%);

#system-info {
  display: grid;
  grid-template-columns: var(--nav-item-prefix-width) auto;
  gap: 10px;
  height: calc(45rem / 16);
  padding-block: 0;
  padding-inline: var(--nav-item-gutter-width);
  position: relative;
  flex: 0 0 calc(45rem / 16);
  flex-direction: row;
  align-items: center;
  color: currentcolor;
  border-block-end: 1px solid var(--hairline-color);
  background-clip: padding-box;

  &::after {
    display: none;
  }
}

div#system-info {
  cursor: default;
}

a#system-info {
  &:focus,
  &:hover {
    text-decoration: none;
    background-color: var(--gray-200);
  }
}

#site-icon {
  --size: var(--nav-item-prefix-width); // Matches .sidebar-action__prefix
  height: var(--size);

  img,
  svg {
    display: block;
    width: var(--size);
    height: var(--size);
    border-radius: 4px;
  }

  svg {
    @include mixins.svg-mask(var(--medium-text-color));
  }
}

#system-name {
  h2,
  .h2 {
    margin: 0;
    width: 100%;
    overflow: hidden;
    max-height: 40px;
    position: relative;

    // Multi-line text overflow ellipsis
    // (https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-line-clamp)
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
}

@media only screen and (min-width: $minFullUiWidth) {
  [data-sidebar='collapsed'] {
    #system-info:hover #system-name {
      opacity: 1;
      transform: translateY(-50%) translateX(0);
    }

    #system-name {
      position: absolute;
      inset-inline-start: calc(100% + (var(--s) * 2));
      inset-block-start: 50%;
      transform: translateY(-50%) translateX(-5px);
      opacity: 0;
      @include mixins.pane;
      color: var(--text-color);
      border-radius: var(--medium-border-radius);
      padding-block: var(--xs);
      padding-inline: var(--s);
      white-space: nowrap;
      pointer-events: none;

      @media screen and (prefers-reduced-motion: no-preference) {
        transition:
          opacity 50ms cubic-bezier(0.33, 1, 0.68, 1),
          transform 150ms cubic-bezier(0.33, 1, 0.68, 1);
      }
    }
  }
}

#global-footer {
  --link-color: #1453db;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-block: var(--m) var(--xl);
  padding-inline: var(--m);
  gap: var(--m);

  body.has-debug-toolbar & {
    padding-block-end: calc(var(--xl) + 42px);
  }

  a {
    white-space: nowrap;
  }
}

#app-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: var(--m);
}

#trial-info {
  padding-block: 16px;
  padding-inline: var(--xl);
  border-radius: var(--large-border-radius);
  background-color: var(--gray-150);
  box-shadow:
    inset 0 1px 0 rgb(255 255 255 / 50%),
    0 0 0 1px hsl(212deg 30% 80%),
    0 2px 12px color.adjust(mixins.$grey200, $alpha: -0.5);
  text-align: center;
}

#app-version {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: var(--gray-600);
  text-align: center;
  gap: var(--s);

  a {
    color: var(--gray-600);
    text-decoration: underline;

    &.go:hover::after {
      color: var(--gray-600);
    }
  }
}

#edition-logo {
  position: relative;
  inset-block-start: -1px;
  user-select: none;
  border: 1px solid var(--gray-600);
  border-radius: 3px;
  display: inline-flex;
  box-sizing: content-box;
  font-size: 11px;
  padding-block: 6px;
  padding-inline: 7px;
  line-height: 8px;
  font-weight: 600;
  letter-spacing: 1.7px;
  padding-inline-end: 5px;
  text-transform: uppercase;
}

#devmode {
  margin-block-start: auto;
  flex: 0 0 4px;
  width: 100%;
  min-height: 4px; // fixes Windows scaling bug (https://github.com/craftcms/cms/issues/3259)
  background:
    url('../images/dev-mode.svg') repeat-x 21px 0,
    var(--gray-900);
  cursor: help;
}

#page-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

#alerts {
  position: relative; // to keep it above #global-header's shadow
  background-color: var(--red-050);
  border-inline-start: 6px solid var(--error-color);
  color: var(--error-color);

  & > li {
    padding-block: 10px;
    padding-inline: 24px;
    box-shadow: inset 0 -1px 0 0 var(--red-100);

    .resolvable-alert-buttons {
      margin-block-start: var(--m);
    }

    .btn,
    a.go {
      display: inline-flex;
      align-items: center;
      border: 1px solid color.adjust(mixins.$errorColor, $alpha: -0.5);
      border-radius: var(--medium-border-radius);
      padding-block: 4px;
      padding-inline: 5px;
      margin-block: 0;
      margin-inline: 2px;
      color: var(--error-color);
      height: calc(30rem / 16);

      .flex & {
        margin: 0;
      }

      &:hover {
        border-color: var(--error-color);
      }
    }

    a:not(.btn) {
      color: var(--error-color);
      text-decoration: underline;

      &.go {
        text-decoration: none;
        white-space: nowrap;

        &::after {
          color: var(--error-color);
          font-size: 15px;
          margin-block-start: 1px;
        }
      }
    }

    .btn {
      background-color: transparent;
    }
  }
}

#global-header .btn.menubtn,
li.crumb .btn.menubtn,
.btn.action-btn {
  padding: 0;
  width: var(--touch-target-size);
  height: var(--touch-target-size);

  &:not(:hover, :active, [aria-expanded='true']) {
    background-color: transparent;
  }
}

#global-header {
  width: 100%;
  margin-block-end: var(--m);
  border-block-end: 1px solid var(--hairline-color);

  .flex {
    height: var(--header-height);
    align-items: center;
  }

  .btn {
    --ui-control-color: var(--gray-400);
    --ui-control-hover-color: var(--gray-800);
    --ui-control-active-color: var(--gray-700);

    &:hover {
      color: var(--ui-control-hover-color);
    }
  }
}

.nav-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  min-width: 44px;
  height: var(--header-height);
  border-inline-end: 1px solid var(--medium-hairline-color);
  line-height: 26px;
  color: currentcolor;
  text-align: center;

  &::before {
    @include mixins.icon;
    content: 'menu';
    font-size: 16px;
    line-height: 0;
  }

  &:hover {
    color: var(--link-color);
  }
}

#crumbs {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 13px;
  margin: 0 !important;
  max-width: 100%;
  overflow: hidden;

  &.empty {
    display: none;
  }

  nav {
    margin: 0;
  }
}

#primary-nav-toggle {
  &:focus-visible {
    box-shadow: var(--inner-focus-ring);
  }
}

#crumb-list {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  max-width: 100%;
  height: var(--header-height);

  --arrow-padding: 10;
  --arrow-height: 22;
  --arrow-angle: 35;
  --arrow-width: 17.1111; // height/(45/angle)
  --arrow-c: 27.871; // sqrt(height^2+width^2)

  body.rtl & {
    --arrow-angle: -35;
  }

  li.crumb {
    position: relative;
    padding-inline-start: calc(var(--arrow-width) * 0.5rem / 16);
    cursor: default;
    color: var(--light-text-color);

    &:not(.current) {
      padding-inline-end: calc(var(--arrow-width) * 1.125rem / 16);

      &::before,
      &::after {
        display: block;
        content: '';
        width: 1px;
        height: calc(var(--arrow-c) * 1rem / 16);
        position: absolute;
        inset-inline-end: calc(var(--arrow-width) * 0.5rem / 16);
        background: var(--medium-hairline-color);
        pointer-events: none;
      }

      &::before {
        inset-block-start: calc(
          (var(--arrow-height) - var(--arrow-c)) / 2 * 1rem / 16
        );
        transform: rotate(calc(var(--arrow-angle) * -1deg));
      }

      &::after {
        inset-block-end: calc(
          (var(--arrow-height) - var(--arrow-c)) / 2 * 1rem / 16
        );
        transform: rotate(calc(var(--arrow-angle) * 1deg));
      }
    }
  }

  & .menu--disclosure {
    li {
      display: block;

      &::after {
        content: none;
      }
    }
  }
}

#crumb-menu-trigger {
  min-width: var(--touch-target-size);

  &::before {
    width: 100%;
  }
}

#crumb-menu {
  .chip {
    min-height: 44px;
  }
}

li.crumb {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: var(--xs);

  [data-icon] {
    position: relative;
    inset-block-start: -1px;
  }

  .menubtn {
    position: relative;
    margin-block: 0;
    margin-inline: calc(var(--xs) * -1 / 2);
  }

  [data-icon='ellipsis']::before {
    margin-block-start: 3px;
    font-size: 17px;
  }

  .chip {
    padding: 0;
    background-color: transparent;

    --custom-bg-color: initial !important;
    --custom-text-color: initial !important;
    --custom-sel-bg-color: initial !important;

    .label-link {
      max-width: 15em;

      &:focus-visible {
        box-shadow: var(--inner-focus-ring);
      }

      & > span:not(.context-label) {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &:has(.menubtn) > a {
    margin-inline-end: 0 !important;
    padding-inline-end: var(--xs) !important;
  }

  & > a {
    flex: 1;
    display: flex !important;
    align-items: center;
    flex-wrap: nowrap;
    gap: var(--xs);
    white-space: nowrap;

    .menu--disclosure & {
      min-height: 34px;
    }
  }

  a,
  button {
    display: inline-flex;
    align-items: center;
    min-height: var(--touch-target-size);

    &:not(:hover) {
      color: var(--text-color);

      svg {
        @include mixins.svg-mask(var(--text-color));
      }
    }

    &:hover {
      text-decoration: none;
    }
  }

  a {
    position: relative;
  }

  button {
    cursor: pointer;
  }
}

li.breadcrumb-toggle-wrapper {
  &::after {
    content: none;
  }
}

#account-menu {
  ul {
    li {
      a {
        max-width: calc(100% + 28px) !important;
      }
    }
  }
}

#announcements-btn {
  @include mixins.header-btn;
  width: calc(28rem / 16 + 10px);
  position: relative;

  &.unread::after {
    content: '';
    display: block;
    position: absolute;
    inset-block-start: calc(3rem / 16);
    inset-inline-end: 5px;
    width: calc(7rem / 16);
    height: calc(7rem / 16);
    border-radius: 50%;
    background-color: var(--blue-600);
  }

  svg {
    width: 1rem;
    height: 1rem;
    @include mixins.svg-mask(var(--ui-control-color));
  }

  &:hover,
  &:active,
  &.active {
    svg {
      @include mixins.svg-mask(var(--ui-control-active-color));
    }
  }
}

#announcements {
  max-width: 350px;

  .announcement {
    &:not(:first-child) {
      margin-block-start: 20px;
    }

    .announcement-label-container {
      margin-block: -3px 3px;
      display: flex;
      align-items: center;

      .announcement-icon svg {
        display: block;
        width: 15px;
        height: 15px;
      }

      .announcement-label {
        margin-inline-start: 5px;
        font-size: 12px;
        color: var(--light-text-color);
      }
    }

    &.unread .announcement__heading::after {
      content: '';
      display: block;
      position: absolute;
      inset-block-start: 7px;
      inset-inline-start: -13px;
      width: 7px;
      height: 7px;
      border-radius: 4px;
      background-color: var(--blue-600);
    }

    p {
      margin: 0;
    }
  }

  .announcement__header {
    display: grid;
  }

  .announcement__heading {
    position: relative;
    margin-block: 0 3px;
    margin-inline: 0;
    order: 1;
  }
}

#user-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-inline: var(--s) calc(var(--padding) + 1px);
  padding-block: 5px;
  height: 100%;
  background-color: transparent;
  border-radius: 0;

  &::after {
    border-color: var(--ui-control-color);
    transition: border-color linear 100ms;
  }

  &:hover::after {
    border-color: var(--ui-control-hover-color);
  }

  &[aria-expanded='true']::after {
    border-color: var(--ui-control-active-color);
  }
}

.header-photo {
  img,
  svg {
    display: block;
    width: calc(30rem / 16);
    height: calc(30rem / 16);
    max-width: none;
    border-radius: 50%;
    box-shadow:
      0 0 0 1px color.adjust(mixins.$grey900, $alpha: -0.95),
      0 0 0 color.adjust(mixins.$grey900, $alpha: -1);
    transition: box-shadow linear 150ms;

    #user-info:hover & {
      box-shadow:
        0 0 0 1px color.adjust(mixins.$grey900, $alpha: -0.95),
        0 2px 10px -2px color.adjust(mixins.$grey900, $alpha: -0.7);
    }
  }
}

/* main container */
#main-container {
  position: relative;
  flex-grow: 1;
}

#notifications {
  position: fixed;
  inset-block-end: 8px;
  inset-inline-start: 8px;
  width: calc(100% - 16px);
  z-index: 101;

  body.has-debug-toolbar & {
    inset-block-end: 50px;
  }

  @media only screen and (min-width: $minHorizontalUiWidth) {
    width: 350px;
  }

  .notification {
    display: flex;
    position: relative;
    @include mixins.pane;
    padding-block: 10px;
    padding-inline: 14px;
    border-radius: var(--large-border-radius);
    box-shadow:
      0 0 0 1px color.adjust(mixins.$grey1000, $alpha: -0.85),
      0 2px 12px color.adjust(mixins.$grey1000, $alpha: -0.85);

    --color: var(--notice-color);

    &[data-type='success'] {
      --color: var(--success-color);
    }

    &[data-type='error'] {
      --color: var(--error-color);
    }

    & + .notification {
      margin-block-start: 12px;
    }

    .notification-icon {
      width: 18px;
      margin-block-start: -2px;
      margin-inline-end: 10px;
      font-size: 18px;
      color: var(--color);
    }

    .notification-main {
      overflow: hidden;
    }

    .notification-body {
      display: grid;
      grid-template-columns: 20px 1fr;
      gap: var(--s);
      width: 100%;

      .notification-message {
        font-weight: bold;
        word-break: normal;
        overflow-wrap: anywhere;
      }

      .notification-details {
        margin-block-start: var(--xs);

        .chip {
          border: 1px solid mixins.$hairlineColor;
        }
      }
    }

    .notification-close-btn {
      position: relative;
      inset-block-start: -2px;
      margin-inline-start: 10px;
    }
  }
}

#header-container {
  margin-block-end: var(--m);
}

@media only screen and (min-width: $minFullUiWidth) {
  #primary-nav-toggle {
    display: none;
  }

  #crumbs:not(.empty) #primary-nav-toggle + nav ul li:first-child,
  #crumbs.empty + .context-menu-container {
    margin-inline-start: var(--padding);
    padding-inline-start: 0;
  }
}

#global-header,
#header {
  .flex {
    max-width: 100%;

    &.flex-nowrap {
      min-width: 0;
    }
  }
}

#header {
  display: flex;
  align-items: flex-start;
  place-content: stretch space-between;
  flex-wrap: nowrap;
  gap: var(--s);
  padding-block: 5px;
  padding-inline: var(--padding);
  position: relative;
  z-index: 2;
  box-sizing: border-box;
  background-color: color.adjust(mixins.$grey300, $alpha: -1);
  box-shadow: 0 1px 0 color.adjust(mixins.$grey800, $alpha: -1);
  transition:
    background-color linear 100ms,
    box-shadow linear 100ms;

  body.fixed-header & {
    position: fixed;
    z-index: 12;
    inset-block-start: 0;
    background-color: var(--gray-100);
    box-shadow: 0 1px 0 color.adjust(mixins.$grey700, $alpha: -0.9);
  }

  @supports (backdrop-filter: blur(10px)) {
    & {
      transition:
        background-color linear 100ms,
        box-shadow linear 100ms,
        backdrop-filter linear 100ms;

      body.fixed-header & {
        background-color: color.adjust(mixins.$grey100, $alpha: -0.25);
        backdrop-filter: blur(10px);
      }
    }
  }

  h1 {
    line-height: 34px;
    margin-block: 0;
    min-width: 0;
  }

  .buttons,
  .btngroup {
    margin-block-start: 0;
  }
}

#page-title {
  &.has-toolbar {
    @media only screen and (min-width: $minHorizontalUiWidth) {
      min-width: calc(#{$sidebarWidth} - var(--xl) - var(--s)) !important;
    }
  }
}

#content-heading {
  font-size: 0.875rem;
  font-weight: normal;

  &::before {
    content: '/';
    display: inline-block;
    margin-inline: 0.5rem;
  }

  @media only screen and (min-width: $minHorizontalUiWidth) {
    display: none;
  }
}

#toolbar {
  flex: 1;
  align-items: flex-start;
  justify-content: space-between;

  .icon::before,
  .texticon::before,
  .menubtn::after,
  [data-icon]::before,
  [data-icon-after]::after {
    color: currentcolor;
    opacity: 0.8;
  }

  .text {
    border-radius: var(--large-border-radius);

    &::placeholder {
      color: var(--light-text-color);
    }

    &:not(:focus) {
      background-clip: border-box;
    }
  }
}

#action-buttons {
  .action-btn {
    width: var(--ui-control-height);
    height: var(--ui-control-height);
  }

  @media only screen and (width <= 400px) {
    .btngroup .btn:first-child {
      flex-basis: 100%;
    }
  }

  @media only screen and (max-width: $minHorizontalUiWidth - calc(1rem / 16)) {
    flex-direction: row-reverse;
    justify-content: flex-start;
    margin-block-start: 10px;
  }
}

#main-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-block: 0 48px;
  padding-inline: var(--padding);
  box-sizing: border-box;

  // for the Dashboard and any other full-page grids:
  & > .grid:only-child {
    flex: 1;
  }
}

#sidebar-toggle-container {
  display: none;
}

#sidebar-container {
  flex: 0 0 $sidebarWidth;
  width: $sidebarWidth;
  box-sizing: border-box;

  @media only screen and (min-width: $minFullUiWidth) {
    margin-inline-start: calc(var(--xl) * -1);
  }
}

#sidebar {
  position: relative;
  box-sizing: border-box;
  padding-block: 0 var(--m);
  padding-inline: 40px;
  width: $sidebarWidth;
  background-color: transparent;

  &.fixed {
    position: fixed;
  }

  nav {
    margin-inline: -31px;

    li {
      a {
        position: relative;

        &:focus {
          z-index: 1;
        }

        &:not(.sel) {
          &:hover {
            background-color: var(--gray-200);
          }
        }
      }
    }
  }

  .skip-link {
    margin-block: 0;
    margin-inline: var(--s);
  }

  @media only screen and (min-width: $minFullUiWidth) {
    padding-block: 0 var(--m);
    padding-inline: 31px;
  }
}

#content-container {
  flex: 1 1 auto;

  /*
  Admittedly I'm not totally sure why this is necessary, but it seems to be.
  We have to give #content-container some kind of "hard" width for it to properly
  clip the content when table cells are long.
   */
  width: 400px;
}

.content-grid {
  --size-main-content: 100%;
  position: relative;
  display: grid;
  grid-template-columns: minmax(var(--size-main-content), 1fr) var(
      --touch-target-size
    );

  &:has(.content-grid__toggle) {
    --size-main-content: calc(100% - var(--touch-target-size));
  }
}

.content-grid__main {
  grid-column: 1/1;
}

.content-pane {
  @include mixins.pane;
  border-radius: var(--large-border-radius);
  position: relative;
  background-clip: padding-box;
  padding-block: var(--xl);
  padding-inline: var(--padding);
  word-wrap: break-word;

  & > hr {
    margin-inline: calc(var(--xl) * -1);
  }
}

#footer,
.footer {
  padding-block: 6px;
  padding-inline: var(--pane-padding, var(--xl));
  min-height: 44px;
  line-height: calc(32rem / 16);

  .buttons {
    height: 32px;
    display: flex;
    align-items: center;
  }

  .btn {
    padding-block: 4px;
    height: var(--ui-control-height--small);
  }
}

#footer {
  position: sticky;
  inset-block-end: 0;
  z-index: 1;
  margin-block: calc(var(--s) + 1px) calc(var(--xl) * -1 + 2px);
  margin-inline: calc(var(--padding) * -1 + 2px);
  background-color: var(--gray-050);
  border-radius: var(--small-border-radius);
  box-sizing: border-box;
  border-block-start: 2px solid var(--white);

  &:empty {
    display: none;
  }

  &.stuck {
    border-radius: 0;

    @supports (backdrop-filter: blur(10px)) {
      & {
        background-color: color.adjust(mixins.$grey100, $alpha: -0.25);
        backdrop-filter: blur(10px);
      }
    }
  }

  .has-debug-toolbar & {
    transition: padding linear 100ms;

    &.stuck {
      padding-block-end: 52px;
    }
  }

  & > *,
  .flex > * {
    margin-block-end: 0;
  }
}

#details-container[data-state='collapsed'] {
  display: none;
}

#details-toggle > button {
  position: sticky;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  inset-block-start: 0;
  height: 100%;
  max-height: 100vh;
  width: var(--touch-target-size);
  border-radius: var(--ui-control-border-radius);

  .details-toggle__inner {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--hairline-color);
    width: calc(var(--touch-target-size) - var(--s));
    height: calc(var(--touch-target-size) * 2);
    border-radius: var(--ui-control-border-radius);
    background-color: var(--gray-100);
    position: relative;
    z-index: 10;
  }

  &::before {
    content: '';
    position: absolute;
    inset-block: 0;
    height: 100%;
    width: 1px;
    background-color: var(--hairline-color);
  }

  &:hover,
  &:focus,
  &:focus-visible {
    .details-toggle__inner {
      background-color: var(--gray-200);
    }

    &::before {
      transform: scaleX(300%);
    }
  }

  &:focus-visible,
  &:focus {
    box-shadow: none;

    .details-toggle__inner {
      box-shadow: var(--focus-ring);
    }
  }

  .toggle-icon {
    .rtl & {
      transform: rotate(180deg);
    }
  }

  &[aria-expanded='true'] .toggle-icon--open {
    display: none;
  }

  &[aria-expanded='false'] .toggle-icon--close {
    display: none;
  }
}

#details-container {
  display: block;
  flex: 0 1 auto;
  width: calc(var(--details-width));
  position: sticky;
  inset-block-start: 0;
}

#details {
  display: none;
}

#details {
  display: block;
  width: $detailsWidth;
  z-index: 1;

  &.fixed {
    position: fixed;
  }

  .details {
    .meta {
      border-radius: var(--large-border-radius);

      &:not(.read-only, .warning) {
        @include mixins.pane;
      }

      &.read-only {
        color: var(--medium-text-color);
        background-color: transparent !important;
      }

      & > .field,
      & > .data {
        &:first-child {
          &,
          & > .status-badge {
            border-start-start-radius: var(--large-border-radius);
          }

          border-start-end-radius: var(--large-border-radius);
        }

        &:last-child {
          &,
          & > .status-badge {
            border-end-start-radius: var(--large-border-radius);
          }

          border-end-end-radius: var(--large-border-radius);
        }
      }
    }

    & > .field {
      & > .input > .text.fullwidth {
        @include mixins.pane;
      }
    }

    .notes {
      border-radius: var(--large-border-radius);
      padding-block: var(--m);
    }
  }
}

// Hide the sidebar at < 1200
@media only screen and (max-width: $minFullUiWidth - calc(1rem/16)) {
  #global-container {
    inset-inline-start: -$sidebarWidth;
    width: calc(100vw + #{$sidebarWidth});

    .showing-nav & {
      inset-inline-start: 0;
    }
  }

  #crumbs {
    display: flex !important;
  }

  #header {
    width: 100vw;
  }

  #main-content {
    width: 100vw;

    &.has-sidebar {
      padding-inline-start: 0;
    }
  }
}

// Rearrange #main-content to flow vertically at < 999
@media only screen and (max-width: $minHorizontalUiWidth - calc(1rem/16)) {
  #header {
    display: block;

    .flex:not(#toolbar) {
      margin-block-start: 10px;
    }
  }

  #toolbar {
    flex-wrap: wrap !important;

    & > * {
      margin-block-start: 10px !important;
    }
  }

  body.fixed-header #header .flex:first-child {
    margin-block-start: 0;
  }

  #main-content {
    display: block;

    &.has-sidebar {
      padding-block: 0 48px;
      padding-inline: var(--padding);
    }
  }

  #sidebar {
    nav {
      margin-inline: -40px;
    }

    .skip-link {
      margin: 0;
    }
  }

  #source-actions {
    margin-block-start: var(--s);
    margin-inline-start: -9px;
  }

  #sidebar-toggle-container {
    display: block;
    margin-block-end: var(--s);
  }

  #sidebar-toggle {
    &::after {
      inset-block-start: 0;
      transform: rotate(45deg);

      body.rtl & {
        transform: rotate(135deg);
      }
    }
  }

  #content-container {
    min-height: 0 !important;
  }

  .content-grid,
  .content-grid:has(.content-grid__toggle) {
    --size-main-content: 100%;
  }

  #sidebar-container,
  #details-container {
    width: auto !important;
    padding-inline-start: 0 !important;
    display: block;
  }

  .content-grid__toggle {
    display: none;
  }

  #sidebar,
  #details {
    position: relative !important;
    overflow-y: visible !important;
    max-height: none !important;
    width: auto;
  }

  #sidebar {
    margin-block-end: var(--m);

    body.showing-sidebar & {
      @include mixins.pane;
      padding-block: 0.5rem;
      border-radius: var(--large-border-radius);
    }

    body:not(.showing-sidebar) & {
      display: none;
    }
  }

  #details-container {
    margin-inline-end: 0;
  }

  #content-container {
    width: auto !important;
    max-width: none !important;
  }

  #details {
    padding: 0 !important;
    margin-block-start: var(--m);

    .details {
      margin-block-end: auto;
    }
  }
}

// Tighten up the padding at < 768
@media only screen and (width <= 767px) {
  :root {
    --padding: var(--s);
    --neg-padding: calc(var(--s) * -1);
  }

  #tabs ul li {
    a {
      padding-inline: var(--m);
    }

    & + li {
      margin-inline-start: -7px;
    }
  }

  .card-grid {
    display: flex !important;
    flex-direction: column;
  }
}

#settingsmenu ul li a {
  padding-inline-start: 46px;

  &::before {
    margin-inline: -22px 0;
    margin-block: 1px 0;
    font-size: 15px;
  }

  img {
    width: 16px;
    height: 16px;
    position: absolute;
    margin-inline-start: -23px;
    margin-block-start: 1px;
  }
}

/* grids */
.grid {
  position: relative;
  min-height: 1px; // Required for Grid.js to run

  &::after {
    @include mixins.clearafter;
  }

  & > .item {
    display: none;
    box-sizing: border-box;
  }
}

/* Sidebar */
.sidebar {
  width: $sidebarWidth;
  box-sizing: border-box;
  font-size: 13px;

  &:not(.drag-helper) {
    padding-block: var(--m);
    padding-inline: 0;
    background-color: var(--gray-050);
  }

  .heading {
    position: relative;
    margin-block: 0;
    margin-inline: var(--xl);

    &:not(:first-child) {
      margin-block-start: var(--m);
    }

    & > span {
      display: inline-block;
      position: relative;
      z-index: 1;
      padding-block: 0;
      padding-inline: 5px;
      margin-block: 0;
      margin-inline: -5px;
      text-transform: uppercase;
      color: var(--medium-text-color);
      font-size: 11px;
      font-weight: bold;
    }

    & > ul {
      margin-block: 2px;
      margin-inline: calc(var(--xl) * -1);
    }
  }

  @mixin nav-level($level) {
    & > a {
      padding-inline-start: 24px + 14 * $level;
    }

    & > .toggle {
      inset-inline-start: calc(var(--m) * #{$level});
    }
  }

  nav {
    padding-block: 0;
    padding-inline: var(--s);

    & > ul {
      & > li:not(.heading),
      & > li.heading > ul > li {
        @include nav-level(0);

        & > ul.nested > li {
          @include nav-level(1);

          & > ul.nested > li {
            @include nav-level(2);

            & > ul.nested > li {
              @include nav-level(3);

              & > ul.nested > li {
                @include nav-level(4);
              }
            }
          }
        }
      }
    }

    li {
      position: relative;

      &:not(.has-subnav) > a {
        &:not(.sel):hover {
          text-decoration: none;
        }

        &.sel,
        &.active-drop-target {
          cursor: default;
          background-color: var(--gray-500);
          @include mixins.dark-btn-light-bg-focus-ring;

          &,
          & + .toggle {
            color: var(--white);
          }
        }

        &.sel {
          @include mixins.light-on-dark-text;

          --focus-ring: var(--focus-ring-outset);
        }
      }

      a {
        position: relative;
        margin-block-end: 1px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-block: 7px;
        padding-inline: var(--xl);
        min-height: var(--xl);
        box-sizing: border-box;
        color: var(--text-color);
        cursor: pointer;
        user-select: none;
        border-radius: var(--large-border-radius);
        word-break: break-word;

        .status {
          flex-shrink: 1;
        }

        .icon {
          position: relative;
          inset-block-start: 1px;
          margin-inline-end: 10px;
          flex-shrink: 1;
          width: calc(18 / 16 * 1rem);
          height: calc(18 / 16 * 1rem);
          word-break: normal;
          transition:
            margin-inline-start linear 150ms,
            margin-inline-end linear 150ms;
          opacity: 0.85;

          &.icon-mask {
            svg {
              @include mixins.svg-mask(currentColor);
            }

            span[data-icon] {
              color: currentcolor;
            }
          }

          svg {
            width: 1rem;
            height: 1rem;
          }

          span[data-icon] {
            font-size: 16px;

            &::before {
              display: block;
            }
          }
        }

        .label {
          flex: 1;
        }

        .badge {
          margin-inline: 10px -4px;
          margin-block: 0 0;
          flex-shrink: 1;
          padding-block: 0;
          padding-inline: 6px;
          font-size: calc(11rem / 16);
          line-height: 16px;
          border-radius: var(--s);
          background-color: var(--medium-text-color);
          color: var(--white);
        }
      }

      .toggle {
        position: absolute;
        z-index: 1;
        height: 34px;
        inset-block-start: 0;
        width: var(--xl);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      ul {
        display: none;
      }

      &.heading,
      &.expanded {
        & > ul {
          display: block;
        }
      }
    }
  }
}

.error-summary {
  @include mixins.pane;
  margin-block-end: var(--l);
  padding-block: var(--l);
  padding-inline: var(--xl);
  border-radius: var(--large-border-radius);
  background-color: var(--gray-050);

  .notification-icon {
    color: var(--error-color);
    margin-block: -2px 0;
    margin-inline: 0 6px;
    float: inline-start;
  }

  h2 {
    margin-block-start: 0;
  }

  & > ul.errors li,
  & > ul.errors li a {
    color: var(--text-color);

    em {
      font-weight: 600;
    }

    &:hover {
      color: var(--link-color);
    }
  }
}

.slideout {
  .error-summary {
    border: 1px solid var(--error-color);
    box-shadow: none;
    background-color: rgb(var(--error-color-rgb) / 3%);
    padding: var(--m);
  }

  // Slideouts with tabs need slightly different margins
  .flex-fields .error-summary {
    margin-block: 0 var(--row-gap);
    margin-inline: var(--row-gap);
  }
}

.content.has-sidebar:not(.hiding-sidebar) {
  margin-inline-start: $sidebarWidth;
}

#content-notice {
  margin-block: 0;
  margin-inline: calc(var(--xl) * -1);
  padding-block: 0;
  padding-inline: var(--xl);

  &:not(:last-child) {
    padding-block-end: 5px;
    border-block-end: 1px solid var(--hairline-color);
    margin-block-end: 5px;
  }
}

/* flexbox field layouts */
#content :not(.meta) > .flex-fields,
#content > .flex-fields,
.so-content .flex-fields,
.lp-content .flex-fields,
.draghelper .flex-fields {
  --row-gap: var(--xl);
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  margin-block: 0 calc(var(--padding) * -1);
  margin-inline: calc(var(--padding) * -1);
  width: calc(100% + var(--padding) * 2);

  & > :not(.error-summary) {
    margin-block: 0 var(--row-gap) !important;
    margin-inline: 0 !important;
    padding-block: 0;
    padding-inline: var(--padding);
    box-sizing: border-box;
  }

  & > h2 {
    flex: 4 0 100%;

    &:not(:nth-child(-n + 1 of :not(.hidden))) {
      padding-block-start: var(--xl);
      border-block-start: 1px solid var(--hairline-color);
    }
  }

  & > hr,
  & > .line-break {
    flex: 4 0 100%;
  }

  & > .line-break:not(:first-child) {
    margin-block-end: 0 !important;
  }

  & > :not(h2, hr, .line-break) {
    &,
    &:last-child {
      position: relative;
      width: 100%;

      &::before {
        position: absolute;
        inset-block-start: 0;
        inset-inline-start: -1px;
        width: 1px;
        height: 100%;
        content: '';

        // same BG color as the hairline around the content pane’s 1px shadow
        background-color: color.adjust(mixins.$grey200, $alpha: -0.5);
      }
    }
  }
}

#content :not(.meta) > .flex-fields,
#content > .flex-fields,
.draghelper.drag-in-content .flex-fields {
  & > :not(h2, hr, .line-break) {
    &,
    &:last-child {
      // 4 cols for >= 1536px
      @media only screen and (min-width: $minXlUiWidth) {
        &.width-25 {
          width: 25%;
        }

        &.width-50 {
          width: 50%;
        }

        &.width-75 {
          width: 75%;
        }
      }

      // 2 cols for 600 - 1535px
      @media only screen and (min-width: $minDesktopWidth) and (max-width: $minXlUiWidth - calc(1rem/16)) {
        &.width-25,
        &.width-50 {
          width: 50%;
        }
      }
    }
  }
}

.so-content .flex-fields,
.lp-content .flex-fields,
.draghelper.drag-in-slideout .flex-fields {
  & > :not(h2, hr, .line-break) {
    &,
    &:last-child {
      // 2 cols for >= 1200
      @media only screen and (min-width: $minDesktopWidth * 2) {
        &.width-25,
        &.width-50 {
          width: 50%;
        }
      }
    }
  }
}
