@charset "UTF-8";
@use 'sass:color';
@use 'variables';
@use '@craftcms/sass/mixins';

@mixin striped-bg($size, $color: mixins.$grey100) {
  // h/t https://css-tricks.com/stripes-css/
  $halfSize: $size * 0.5;

  body.ltr & {
    background: repeating-linear-gradient(
      135deg,
      var(--white),
      var(--white) $halfSize,
      $color $halfSize,
      $color $size
    );
  }

  body.rtl & {
    background: repeating-linear-gradient(
      45deg,
      var(--white),
      var(--white) $halfSize,
      $color $halfSize,
      $color $size
    );
  }
}

@font-face {
  font-family: Craft;
  src:
    url('../fonts/Craft.woff2') format('woff2'),
    url('../fonts/Craft.woff') format('woff'),
    url('../fonts/Craft.ttf') format('truetype'),
    url('../fonts/Craft.svg#Craft') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

/* ----------------------------------------
/*  Basic stuff
/* ---------------------------------------- */

:root {
  --ui-control-color: var(--gray-550);
  --ui-control-hover-color: var(--gray-600);
  --ui-control-active-color: var(--gray-700);
  --ui-control-static-bg-color: #{color.adjust(
      mixins.$inputColor,
      $alpha: -0.75
    )};
  --ui-control-bg-color: var(--ui-control-static-bg-color);
  --ui-control-hover-bg-color: #{color.adjust(mixins.$inputColor, $alpha: -0.7)};
  --ui-control-active-bg-color: #{color.adjust(mixins.$inputColor, $alpha: -0.5)};
  --ui-control-border-radius: var(--large-border-radius);
  --ui-control-height: calc(34rem / 16);
  --ui-control-height--small: calc(30rem / 16);
  --focus-ring-alpha: 0.85;
  --light-focus-hsl: #{color.hue(mixins.$lightFocusColor)},
    #{color.saturation(mixins.$lightFocusColor)},
    #{color.lightness(mixins.$lightFocusColor)};
  --medium-focus-hsl: #{color.hue(mixins.$mediumFocusColor)},
    #{color.saturation(mixins.$mediumFocusColor)},
    #{color.lightness(mixins.$mediumFocusColor)};
  --dark-focus-hsl: #{color.hue(mixins.$darkFocusColor)} #{color.saturation(
      mixins.$darkFocusColor
    )} #{color.lightness(mixins.$darkFocusColor)};
  --focus-ring: #{0 0 0 1px hsl(var(--dark-focus-hsl)),
    0 0 0 3px hsl(var(--dark-focus-hsl) / var(--focus-ring-alpha))};
  --focus-ring-outset: inset 0 0 0 1px currentcolor,
    0 0 0 3px hsl(var(--dark-focus-hsl) / var(--focus-ring-alpha));
  --inner-focus-ring: inset 0 0 0 1px hsl(var(--dark-focus-hsl)),
    inset 0 0 0 3px hsl(var(--dark-focus-hsl) / var(--focus-ring-alpha));
  --touch-target-size: calc(24rem / 16);
  --icon-size: 1rem;
  --elements-busy-top-position: calc(50% - var(--size, 20px) / 2);
  --lh: 1.42em;
}

body,
html {
  box-shadow: var(--light-focus-ring);
  background-color: var(--gray-100);
}

html.noscroll,
html.noscroll body {
  overflow: hidden;
}

body {
  width: 100vw;
  overflow-x: hidden;
  font-size: calc(14 / 16 * 1rem);
  line-height: var(--lh);
  color: var(--text-color);
  -webkit-font-smoothing: subpixel-antialiased;
}

body.rtl {
  direction: rtl;
}

body,
input,
select,
textarea {
  @include mixins.sans-serif-font;
}

.first,
h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child,
p:first-child,
blockquote:first-child,
hr:first-child,
.pane:first-child,
.grid:first-child,
fieldset:first-child,
.field:first-child,
.toolbar:first-child,
.buttons:first-child,
.condition-container:first-child {
  margin-block-start: 0 !important;
}

.last,
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
p:last-child,
blockquote:last-child,
.pane:last-child,
.grid:last-child,
.meta:last-child,
fieldset:last-child,
.field:last-child,
.toolbar:last-child,
.buttons:last-child,
.condition-container:last-child {
  margin-block-end: 0 !important;
}

.mt-0 {
  margin-block-start: 0 !important;
}

.mt-2xs {
  margin-block-start: var(--2xs) !important;
}

.mt-xs {
  margin-block-start: var(--xs) !important;
}

.mt-s {
  margin-block-start: var(--s) !important;
}

.mt-m {
  margin-block-start: var(--m) !important;
}

.mt-l {
  margin-block-start: var(--l) !important;
}

.mt-xl {
  margin-block-start: var(--xl) !important;
}

.mb-0 {
  margin-block-end: 0 !important;
}

.mb-2xs {
  margin-block-end: var(--2xs) !important;
}

.mb-xs {
  margin-block-end: var(--xs) !important;
}

.mb-s {
  margin-block-end: var(--s) !important;
}

.mb-m {
  margin-block-end: var(--m) !important;
}

.mb-l {
  margin-block-end: var(--l) !important;
}

.mb-xl {
  margin-block-end: var(--xl) !important;
}

.ml-0 {
  margin-inline-start: 0 !important;
}

.ml-2xs {
  margin-inline-start: var(--2xs) !important;
}

.ml-xs {
  margin-inline-start: var(--xs) !important;
}

.ml-s {
  margin-inline-start: var(--s) !important;
}

.ml-m {
  margin-inline-start: var(--m) !important;
}

.ml-l {
  margin-inline-start: var(--l) !important;
}

.ml-xl {
  margin-inline-start: var(--xl) !important;
}

.mr-0 {
  margin-inline-end: 0 !important;
}

.mr-2xs {
  margin-inline-end: var(--2xs) !important;
}

.mr-xs {
  margin-inline-end: var(--xs) !important;
}

.mr-s {
  margin-inline-end: var(--s) !important;
}

.mr-m {
  margin-inline-end: var(--m) !important;
}

.mr-l {
  margin-inline-end: var(--l) !important;
}

.mr-xl {
  margin-inline-end: var(--xl) !important;
}

.mx-0 {
  margin-inline: 0 !important;
}

.mx-2xs {
  margin-inline: var(--2xs) !important;
}

.mx-xs {
  margin-inline: var(--xs) !important;
}

.mx-s {
  margin-inline: var(--s) !important;
}

.mx-m {
  margin-inline: var(--m) !important;
}

.mx-l {
  margin-inline: var(--l) !important;
}

.mx-xl {
  margin-inline: var(--xl) !important;
}

.my-0 {
  margin-block: 0 !important;
}

.my-2xs {
  margin-block: var(--2xs) !important;
}

.my-xs {
  margin-block: var(--xs) !important;
}

.my-s {
  margin-block: var(--s) !important;
}

.my-m {
  margin-block: var(--m) !important;
}

.my-l {
  margin-block: var(--l) !important;
}

.my-xl {
  margin-block: var(--xl) !important;
}

.p-0 {
  padding: 0 !important;
}

.p-2xs {
  padding: var(--2xs) !important;
}

.p-xs {
  padding: var(--xs) !important;
}

.p-s {
  padding: var(--s) !important;
}

.p-m {
  padding: var(--m) !important;
}

.p-l {
  padding: var(--l) !important;
}

.p-xl {
  padding: var(--xl) !important;
}

.pt-0 {
  padding-block-start: 0 !important;
}

.pt-2xs {
  padding-block-start: var(--2xs) !important;
}

.pt-xs {
  padding-block-start: var(--xs) !important;
}

.pt-s {
  padding-block-start: var(--s) !important;
}

.pt-m {
  padding-block-start: var(--m) !important;
}

.pt-l {
  padding-block-start: var(--l) !important;
}

.pt-xl {
  padding-block-start: var(--xl) !important;
}

.pb-0 {
  padding-block-end: 0 !important;
}

.pb-2xs {
  padding-block-end: var(--2xs) !important;
}

.pb-xs {
  padding-block-end: var(--xs) !important;
}

.pb-s {
  padding-block-end: var(--s) !important;
}

.pb-m {
  padding-block-end: var(--m) !important;
}

.pb-l {
  padding-block-end: var(--l) !important;
}

.pb-xl {
  padding-block-end: var(--xl) !important;
}

.pl-0 {
  padding-inline-start: 0 !important;
}

.pl-2xs {
  padding-inline-start: var(--2xs) !important;
}

.pl-xs {
  padding-inline-start: var(--xs) !important;
}

.pl-s {
  padding-inline-start: var(--s) !important;
}

.pl-m {
  padding-inline-start: var(--m) !important;
}

.pl-l {
  padding-inline-start: var(--l) !important;
}

.pl-xl {
  padding-inline-start: var(--xl) !important;
}

.pr-0 {
  padding-inline-end: 0 !important;
}

.pr-2xs {
  padding-inline-end: var(--2xs) !important;
}

.pr-xs {
  padding-inline-end: var(--xs) !important;
}

.pr-s {
  padding-inline-end: var(--s) !important;
}

.pr-m {
  padding-inline-end: var(--m) !important;
}

.pr-l {
  padding-inline-end: var(--l) !important;
}

.pr-xl {
  padding-inline-end: var(--xl) !important;
}

.px-0 {
  padding-inline: 0 !important;
}

.px-2xs {
  padding-inline: var(--2xs) !important;
}

.px-xs {
  padding-inline: var(--xs) !important;
}

.px-s {
  padding-inline: var(--s) !important;
}

.px-m {
  padding-inline: var(--m) !important;
}

.px-l {
  padding-inline: var(--l) !important;
}

.px-xl {
  padding-inline: var(--xl) !important;
}

.py-0 {
  padding-block: 0 !important;
}

.py-2xs {
  padding-block: var(--2xs) !important;
}

.py-xs {
  padding-block: var(--xs) !important;
}

.py-s {
  padding-block: var(--s) !important;
}

.py-m {
  padding-block: var(--m) !important;
}

.py-l {
  padding-block: var(--l) !important;
}

.py-xl {
  padding-block: var(--xl) !important;
}

.center-absolute {
  position: absolute;
  inset-block-start: 50%;
  inset-inline-start: 50%;
  transform: translate(-50%, -50%);
}

.pointer {
  cursor: pointer !important;
}

.no-scroll {
  overflow: hidden !important;
}

.draghelper {
  box-sizing: border-box;
  list-style-type: none;
}

.offset-drag-helper {
  transition:
    margin linear 200ms,
    padding linear 200ms;
}

body.dragging .offset-drag-helper {
  margin-block: -50px 0;
  margin-inline: -50px 0;
  padding-block: 60px 0;
  padding-inline: 60px 0;
}

img {
  max-width: 100%;
}

.text,
table.editable textarea {
  body.rtl .ltr & {
    text-align: start !important;
    direction: ltr !important;
  }

  body.ltr .rtl & {
    text-align: end !important;
    direction: rtl !important;
  }
}

/* icons */
.icon::before,
.menu ul.padded li a.sel::before,
.menu ul.padded li .menu-item.sel::before,
.menu ul.padded li .menu-option.sel::before,
.menu .flex.padded.sel::before,
.texticon::before,
#help::before,
.secure::before,
.insecure::before,
.go::after,
.required::after,
.preview-btn::before,
.view-btn::before,
.action-btn::before,
[data-icon]::before,
[data-icon-after]::after {
  @include mixins.icon;
}

.badge-icon {
  position: relative;
  inset-block-start: -1px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 1rem;
  height: 1rem;
  box-sizing: border-box;
  border: 1px solid var(--indicator-border-color);
  border-radius: var(--small-border-radius);
  color: var(--indicator-icon-color);
  font-size: 9px;
}

.secure::before,
.insecure::before {
  margin-block-start: -3px;
  font-size: 14px;
}

[data-icon]::before {
  content: attr(data-icon);
}

[data-icon-after]::after {
  content: attr(data-icon-after);
}

[data-icon-size='large'] {
  &[data-icon]::before,
  &[data-icon-after]::after {
    font-size: 20px;
  }
}

[data-icon-size='small'] {
  &[data-icon]::before,
  &[data-icon-after]::after {
    font-size: 14px;
  }
}

[data-icon-size='puny'] {
  &[data-icon]::before,
  &[data-icon-after]::after {
    font-size: 12px;
  }
}

body.rtl [data-icon='list']::before,
body.rtl [data-icon-after='list']::after {
  content: 'listrtl';
}

body.rtl [data-icon='structure']::before,
body.rtl [data-icon-after='structure']::after {
  content: 'structurertl';
}

.icon.secure::before {
  content: 'secure';
}

.icon.insecure::before {
  content: 'insecure';
}

.icon.add::before {
  content: 'plus';
}

.icon.edit::before {
  content: 'edit';
}

.icon.settings::before {
  content: 'settings';
}

.icon.search::before {
  content: 'search';
}

.icon.expand::before {
  content: 'expand';
}

.icon.collapse::before {
  content: 'collapse';
}

.help::before {
  content: 'help';
  color: var(--pink-400);
}

.preview-btn,
.view-btn {
  &::before {
    margin-inline-end: var(--xs);
  }
}

.preview-btn::before {
  content: 'view';
}

.view-btn::before {
  body.ltr & {
    content: 'share';
  }

  body.rtl & {
    content: 'shareleft';
  }
}

.action-btn::before {
  content: 'ellipsis';
}

/* headings */
h1,
.h1 {
  margin-block-end: 24px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.2;
}

h2,
.h2 {
  margin-block: 14px;
  margin-inline: 0;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.2;
}

h3,
.h3 {
  margin-block: 14px;
  margin-inline: 0;
  font-weight: bold;
  line-height: 1.2;
}

h4,
.h4 {
  margin-block: 14px;
  margin-inline: 0;
  font-weight: bold;
  line-height: 1.2;
  color: var(--medium-text-color);
}

h5,
.h5 {
  margin-block: 14px 3px;
  margin-inline: 0;
  line-height: 1.2;
  color: var(--medium-text-color);
}

h6,
.h6 {
  @include mixins.h6-styles;
}

h1[data-icon]::before {
  margin-inline: 0 10px;
  margin-block: -8px 0;
}

h2[data-icon]::before {
  margin-inline: 0 6px;
  margin-block: -4px 0;
  font-size: 19px;
}

/* horizontal rule */
hr {
  margin-block: 24px;
  margin-inline: 0;
  border: none;
  border-block-start: 1px solid var(--hairline-color);
  height: 0;
  color: transparent;
}

.pane hr {
  margin-block: 24px;
  margin-inline: -24px;
}

/* paragraphs */
p {
  margin-block: 1em;
  margin-inline: 0;
}

h5 + p {
  margin-block-start: 0;
}

sup {
  vertical-align: super;
  font-size: smaller;
}

sub {
  vertical-align: sub;
  font-size: smaller;
}

.indent {
  margin-inline-start: 14px;
}

/* lists */
.bullets {
  padding-inline-start: 40px;
  list-style-type: square;
}

ol {
  padding-inline-start: 40px;
  list-style-type: decimal;
}

/* code */
code,
.code {
  @include mixins.fixed-width-font;

  &.smalltext {
    font-size: 0.8em !important;
  }
}

.code {
  input,
  textarea {
    @include mixins.fixed-width-font;
    font-size: 1em !important;
  }
}

pre code {
  display: block;
  overflow-x: auto;
}

/* links */
a {
  color: var(--link-color);
  cursor: pointer;

  &[aria-current] {
    cursor: default;
  }

  body.underline-links &,
  &:hover {
    text-decoration: underline;
  }

  .cp-icon svg {
    @include mixins.svg-mask(var(--icon-color, var(--link-color)));
  }
}

a:not([href]) {
  color: inherit;
  cursor: inherit;

  body.underline-links &,
  &:hover {
    text-decoration: none;
  }
}

a.sel,
li.sel > a {
  cursor: default !important;
  text-decoration: none;
}

.go::after {
  font-size: 11px;
  margin-block-start: -1px;
  padding-inline-start: 4px;
  color: var(--link-color);
  opacity: 0.9;

  body.ltr & {
    content: 'circlerarr';
  }

  body.rtl & {
    content: 'circlelarr';
  }
}

button {
  cursor: pointer;
}

/* status icons */
.checkmark-icon,
.alert-icon {
  padding: 5px;
  margin-block-end: 0 !important;
  line-height: 10px;
  border-radius: 20px;
  cursor: pointer;

  &::before {
    @include mixins.icon;
  }
}

.checkmark-icon {
  p & {
    display: inline-block;
  }

  background-color: var(--gray-200);

  &::before {
    content: 'check';
    color: var(--success-color);
  }
}

.alert-icon {
  background-color: var(--gray-200);

  &::before {
    content: 'alert';
    color: var(--error-color);
  }
}

.revision-status-hud {
  max-width: 400px;

  .http-error {
    border-radius: var(--medium-border-radius);
    border: 1px solid var(--hairline-color);
    color: var(--light-text-color);
    background-color: var(--gray-050);
    padding-block: 7px;
    padding-inline: 14px;
  }
}

.draft-notice {
  display: flex;
  align-items: center;
  place-content: stretch center;
  gap: var(--s);
  color: var(--blue-800);

  #content-notice & {
    display: inline-flex;
    justify-content: flex-start;
  }

  .so-notice > & {
    margin-inline: calc(var(--xl) * -1) 0;
    margin-block: -5px;
    padding-inline: var(--xl) 0;
    padding-block: 5px;
    border-end-start-radius: var(--large-border-radius);

    body.ltr & {
      background-image: linear-gradient(
        to bottom right,
        color.adjust(mixins.$blue600, $alpha: -0.8),
        color.adjust(mixins.$blue600, $alpha: -1) 50%
      );
    }

    body.rtl & {
      background-image: linear-gradient(
        to left,
        color.adjust(mixins.$blue600, $alpha: -0.8),
        color.adjust(mixins.$blue600, $alpha: -1)
      );
    }
  }

  p {
    flex: 1;
    margin: 0;
  }

  .draft-icon {
    position: relative;
    flex-shrink: 0;
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    border: 2px solid color.adjust(mixins.$blue800, $alpha: -0.8);
    box-sizing: border-box;
    box-shadow: 0 1px 1px 1px var(--white);

    &::before {
      position: relative;
      inset-inline-start: 1px;
      color: var(--blue-800) !important;
      font-size: 18px;
    }

    &::after {
      content: '';
      font-size: 0;
      position: absolute;
      inset: -2px;
      border-radius: 100%;
      box-shadow: inset 0 2px 0 color.adjust(mixins.$blue900, $alpha: -0.8);
    }
  }

  .discard-changes-btn {
    margin-inline-start: var(--xs);
    background-color: transparent !important;
    color: var(--blue-800) !important;
    border: 2px solid var(--blue-600);

    &:hover,
    &:focus {
      border-color: color.adjust(mixins.$blue600, $lightness: -5%);
    }

    &:active {
      border-color: color.adjust(mixins.$blue600, $lightness: -10%);
    }
  }
}

.revision-notice {
  display: flex;
  align-items: center;
  place-content: stretch center;
  gap: var(--s);
  color: var(--gray-600);

  #content-notice & {
    display: inline-flex;
    justify-content: flex-start;
  }

  p {
    flex: 1;
    margin: 0;
  }

  .revision-icon {
    position: relative;
    flex-shrink: 0;
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    border: 2px solid color.adjust(mixins.$grey800, $alpha: -0.8);
    box-sizing: border-box;
    box-shadow: 0 1px 1px 1px var(--white);

    &::before {
      position: relative;
      inset-inline-start: 1px;
      color: var(--grey-800) !important;
      font-size: 18px;
    }

    &::after {
      content: '';
      font-size: 0;
      position: absolute;
      inset: -2px;
      border-radius: 100%;
      box-shadow: inset 0 2px 0 color.adjust(mixins.$blue900, $alpha: -0.8);
    }
  }
}

/* toggles */
button.toggle {
  appearance: none;
  color: inherit;
  background: none;
  border: none;
  padding: 0;
}

.toggle::before,
a.fieldtoggle::before {
  @include mixins.angle(right);
  transition: transform linear 100ms;
}

.toggle.expanded::before,
a.fieldtoggle.expanded::before,
.sidebar nav li.expanded > .toggle::before,
.structure li:not(.collapsed) > .row > .toggle::before {
  transform: rotate(45deg) !important;
}

a.fieldtoggle {
  display: block;
  position: relative;
  margin-block: 14px;
  margin-inline: 0;
  padding-inline-start: 12px;
  color: var(--text-color);
  text-decoration: none;
}

a.fieldtoggle::before {
  display: block;
  position: absolute;
  inset-block-start: 7px;
  inset-inline-start: -1px;
}

/* emphasis */
em,
i {
  font-style: italic;
}

ul.errors em {
  font-style: normal;
}

strong,
b,
i em {
  font-weight: bold;
}

/* readable blocks */
.readable {
  @include mixins.readable;
}

/* text styles */
.leftalign {
  text-align: start;
}

.topalign {
  vertical-align: top;
}

.rightalign {
  text-align: end;
}

.centeralign {
  text-align: center !important;
}

.nowrap {
  white-space: nowrap;
}

.break-word {
  word-wrap: break-word;
}

.light {
  color: var(--medium-text-color) !important;
  font-weight: normal;
}

.extralight {
  color: var(--light-text-color) !important;
}

.smalltext {
  font-size: 12px;
  line-height: 1.2;
}

.largetext {
  font-size: 16px;
  line-height: 1.2;
}

.zilch {
  padding-block: 100px;
  padding-inline: 0;
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  color: var(--light-text-color);

  &.small {
    padding-block: 24px;
    padding-inline: 0;
    font-size: 16px;
  }
}

input.checkbox + label.smalltext {
  padding-block-start: 2px;
}

.required::after {
  content: 'asterisk';
  margin-inline: 5px 0;
  margin-block: -2px 0;
  font-size: 12px;
  color: var(--rose-500);
}

.scrollpane {
  overflow: auto;
}

.left {
  float: inline-start;
}

.right {
  float: inline-end;
}

th,
td {
  text-align: start;
  vertical-align: middle;
}

body table[dir='rtl'] {
  th,
  td {
    text-align: end;
  }
}

body table[dir='ltr'] {
  th,
  td {
    text-align: start;
  }
}

th.right,
td.right {
  float: none;
  text-align: end;
}

.clear {
  display: block;
  clear: both;
  height: 0;
}

.fullwidth {
  width: 100%;
}

.token {
  @include mixins.token-styles;
}

.token[data-name='*'] {
  position: relative;
  width: 10px;
}

.token[data-name='*'] span {
  opacity: 0;
}

.token[data-name='*']::before {
  @include mixins.icon;
  display: block;
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  width: 100%;
  font-size: 9px;
  line-height: 17px;
  content: 'asterisk';
  text-indent: 0;
}

.token:focus {
  @include mixins.active-token-styles;
}

// Override .token for Prism
.highlight {
  .token {
    display: inline;
    border: none;
    border-radius: 0;
    padding: 0;
    font-size: inherit;
    line-height: inherit;
    text-shadow: none;
    background: transparent;
    box-shadow: none;
  }
}

.pane.highlight {
  pre[class*='language-'] {
    overflow: visible;

    & > code.diff-highlight .token:not(.prefix) {
      margin-block: 0;
      margin-inline: -24px;
      padding-block: 0;
      padding-inline: 24px;
    }
  }
}

.success {
  color: var(--success-color) !important;
}

.notice,
.warning {
  &.with-icon,
  .icon {
    &::before {
      margin-inline: 0 2px;
      margin-block: -2px 0;
      @include mixins.icon;
      width: 1em;
    }
  }

  &.has-icon {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;

    .icon {
      flex-shrink: 1;
    }
  }
}

.notice {
  color: var(--notice-color) !important;

  a {
    text-decoration: underline;
  }

  &.with-icon,
  .icon {
    &::before {
      content: 'lightbulb';
    }
  }
}

.warning {
  color: var(--warning-color) !important;

  &.with-icon,
  .icon {
    &::before {
      content: 'alert';
    }
  }
}

.error {
  color: var(--error-color) !important;
}

.icon.move:not(.disabled) {
  cursor: move;
}

.icon.move::before {
  content: 'move';
  color: var(--custom-text-color, var(--ui-control-color));
}

.icon.move:not(.disabled):hover::before {
  color: var(--link-color);
}

.icon.delete {
  display: inline-block;
  line-height: inherit;

  &::before {
    content: 'remove';
    color: var(--ui-control-color);
  }

  &:not(.disabled) {
    cursor: pointer;

    &:hover::before {
      color: var(--disabled-color);
    }

    &:active::before {
      color: var(--red-800);
    }
  }
}

.hidden {
  display: none !important;
}

// Visually hide without hiding from screen readers
.visually-hidden {
  @include mixins.visually-hidden;
}

.invisible {
  visibility: hidden;
}

.clearafter::after {
  @include mixins.clearafter;
}

.info {
  vertical-align: bottom;
  display: inline-block;
  width: 1em;
  height: 1.375em;
  text-align: center;
  cursor: pointer;
  overflow: hidden;

  &::before {
    @include mixins.icon;
    vertical-align: baseline;
    width: 100%;
    line-height: 1.375;
    color: var(--ui-control-color);
  }

  &:not(.warning) {
    &::before {
      content: 'info';
    }

    &:hover::before {
      color: var(--link-color);
    }
  }

  &.warning {
    &::before {
      content: 'alert';
    }

    &:hover::before {
      color: var(--warning-color);
    }
  }
}

.info-hud {
  table {
    max-width: 280px;
    table-layout: auto;
  }

  td {
    word-wrap: break-word;
    width: 100%;
  }
}

@media (width <= 450px) {
  .info-hud {
    table {
      table-layout: fixed;
      width: 100%;
    }
  }
}

/* ----------------------------------------
/*  Content
/* ---------------------------------------- */

.content {
  position: relative;
}

.content::after {
  @include mixins.clearafter;
}

.customize-sources-modal {
  padding-inline-start: 200px;
  overflow: visible !important;

  & > .cs-sidebar {
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 0;
    margin: 0;
    padding-block: 10px;
    padding-inline: 0;
    border: none;
    width: 200px;
    height: calc(100% - 44px);
    box-sizing: border-box;
    background-color: var(--gray-050);
    overflow: auto;
    box-shadow: inset -1px 0 0 var(--hairline-color);
    border-start-start-radius: var(--large-border-radius);

    & > .btn {
      margin-inline: 14px 0;
      margin-block: 10px 0;
      display: block;
      width: calc(100% - 28px);
    }
  }

  &.sidebar-hidden {
    padding-inline-start: 0;

    & > .cs-sidebar {
      display: none;
    }
  }

  & > .source-settings {
    position: relative;
    height: calc(100% - 44px);
    box-sizing: border-box;
    padding: 24px;
    overflow: auto;
  }

  & > .footer {
    position: absolute;
    inset-block-end: 0;
    inset-inline-start: 0;
    width: 100%;
  }
}

.customize-sources-item {
  --light-text-color: var(--gray-600);
  --selected-item-color: var(--white);
  display: flex;
  width: calc(100% - 1px);
  box-sizing: border-box;
  align-items: center;
  position: relative;
  margin-block-start: -1px;
  background-color: var(--gray-100);
  border: solid var(--hairline-color);
  border-width: 1px 0;
  user-select: none;
  cursor: default;

  & + .customize-sources-item {
    &.heading {
      margin-block-start: 10px;
    }

    &:not(.heading) {
      border-block-start: 1px solid var(--hairline-color);
    }
  }

  &.sel {
    @include mixins.dark-btn-light-bg-focus-ring;

    --ui-control-color: var(--selected-item-color);
    background-color: var(--dark-sel-color);
    color: var(--selected-item-color);
    z-index: 1;
  }

  .label {
    flex: 1;
  }

  &.heading {
    .label {
      text-transform: uppercase;
      color: var(--light-text-color);
      font-size: 12px;
      font-weight: bold;
    }

    &.sel .label {
      color: var(--selected-item-color);
    }
  }
}

.customize-sources-item__move {
  position: absolute;
  inset-block-start: 50%;
  inset-inline-end: 0;
  transform: translate(-50%, -50%);
  justify-content: center;
  align-items: center;

  &.icon {
    display: flex;
    text-decoration: none;
  }
}

.customize-sources-item__btn {
  padding-block: 8px;
  padding-inline: 14px;
  padding-inline-end: 30px;
  margin: 3px;
}

.checkbox-select-item {
  display: flex;
  align-items: flex-start;
  align-content: stretch;
  margin-block-end: 4px;

  .move {
    margin-block-start: -3px;
    margin-inline-end: 10px;
    position: relative;
    z-index: 1;
  }
}

ul.path {
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;

  li {
    display: inline-flex;
    align-items: center;

    &:not(:last-child)::after {
      margin-inline: 2px 5px;
      margin-block: 0;
      @include mixins.angle(right, var(--light-text-color), calc(1rem / 16));
    }
  }
}

/* ----------------------------------------
/*  Icon lists
/* ---------------------------------------- */

ul.icons {
  margin-block-start: 20px;
  display: flex;
  flex-wrap: wrap;

  li {
    margin-block: 0 10px;
    margin-inline: 0 4px;

    a {
      display: block;
      position: relative;
      padding-block: 60px 10px;
      padding-inline: 5px;
      width: 110px;
      text-align: center;
      color: var(--text-color);
      border-radius: 4px;
      border: 1px solid var(--white);

      &::before {
        display: block;
        position: absolute;
        inset-block-start: 0;
        inset-inline-start: 0;
        width: 100%;
        font-size: 40px;
        line-height: 60px;
      }

      .icon {
        position: absolute;
        inset-block-start: 12px;
        inset-inline-start: 0;
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        img,
        svg {
          // give Users, Addresses, Categories, and Filesystems icons a little extra breathing room
          max-width: 50px;
          height: 40px;
        }

        &.icon-mask svg {
          @include mixins.svg-mask(var(--text-color));
        }
      }

      &:hover {
        text-decoration: none;
        background-color: var(--gray-050);
        border-color: var(--gray-100);

        .icon.icon-mask svg {
          @include mixins.svg-mask(var(--link-color));
        }
      }
    }
  }
}

@media only screen and (width <= 380px) {
  ul.icons li a {
    width: 96px;
  }
}

@media only screen and (width <= 320px) {
  ul.icons li a {
    width: 75px;
  }
}

/* ----------------------------------------
/*  Buttons
/* ---------------------------------------- */

.toolbar {
  position: relative;
  margin-block-end: var(--s);
  min-height: 34px;

  &.flex,
  .flex {
    align-items: flex-start;
  }

  .text {
    border-radius: var(--large-border-radius) !important;
  }
}

.flex {
  display: flex;
  align-items: center;
  align-content: stretch;
  gap: var(--s);

  &.flex-gap-xs {
    gap: var(--xs);
  }

  &.flex-gap-m {
    gap: var(--m);
  }

  &.flex-gap-l {
    gap: var(--l);
  }

  &.flex-gap-xl {
    gap: var(--xl);
  }

  &:not(.flex-nowrap) {
    flex-wrap: wrap;
  }

  & > * {
    &.label {
      white-space: nowrap;
    }
  }

  .centeralign & {
    justify-content: center;
  }
}

.inline-flex {
  display: inline-flex !important;
  align-items: center;
  align-content: stretch;
  gap: var(--s);
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

.gap-2xs {
  gap: var(--2xs);
}

.gap-xs {
  gap: var(--xs);
}

.gap-s {
  gap: var(--s);
}

.gap-m {
  gap: var(--m);
}

.gap-l {
  gap: var(--l);
}

.gap-xl {
  gap: var(--xl);
}

.flex-grow {
  flex: 1;
  max-width: 100%;
}

.flex-justify {
  justify-content: space-between;
}

.flex-justify-start {
  justify-content: flex-start;
}

.flex-justify-end {
  justify-content: flex-end;
}

.flex-justify-center {
  justify-content: center;
}

.flex-start {
  align-items: flex-start;
}

.flex-end {
  align-items: flex-end;
}

.flex-center {
  align-items: center;
}

.flex-stretch {
  align-items: stretch;
}

.spacer {
  width: 14px;
}

.buttons {
  display: flex;
  gap: 7px;
  position: relative;
  margin-block: 24px;
  margin-inline: 0;
  align-items: center;

  .hud-footer > &,
  .footer > & {
    margin: 0;
  }
}

.btn {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--ui-control-border-radius);
  padding-block: 7px;
  padding-inline: 10px;
  border: none;
  text-align: center;
  white-space: nowrap;
  user-select: none;
  box-sizing: border-box;
  appearance: none;
  color: currentcolor;
  font-size: inherit;
  background-color: var(--ui-control-bg-color);

  &:not([aria-current]) {
    cursor: pointer;
  }

  &.chromeless {
    background-color: transparent;
    height: auto;
    padding: 0;

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
    }

    &:hover,
    &:active {
      text-decoration: underline;
    }
  }

  &.hairline,
  &.link-btn {
    border: 1px solid var(--medium-hairline-color);
    background-color: transparent;

    &:active,
    &[aria-expanded='true'] {
      background-color: var(--ui-control-static-bg-color);
    }
  }

  &.hairline {
    &:hover,
    &:active,
    &[aria-expanded='true'] {
      border-color: var(--dark-hairline-color);
    }
  }

  &.hairline-dark {
    border: 1px solid var(--dark-hairline-color);

    &:not(:hover, :active, [aria-expanded='true']) {
      background-color: transparent;
    }
  }

  &.link-btn {
    color: var(--link-color);

    &:hover,
    &:active,
    &[aria-expanded='true'] {
      border-color: var(--link-color);
    }
  }

  &.wrap {
    height: auto;
    min-height: 34px;
    white-space: initial;
    text-align: start;
  }

  &:not(.disabled, .loading, .dashed, [aria-disabled], [aria-current]) {
    &:focus,
    &.focus,
    &:hover {
      --ui-control-bg-color: var(--ui-control-hover-bg-color);
    }

    &:active,
    &.active,
    &[aria-expanded='true'] {
      --ui-control-bg-color: var(--ui-control-active-bg-color);
    }
  }

  &[type='color'] {
    padding: 6px !important;
    width: 36px;
  }

  &.loading {
    cursor: default;
  }

  &:hover {
    text-decoration: none;
  }

  &:empty,
  &.btn-empty {
    padding-inline: 12px;
  }

  &[data-icon]:not(:empty, .btn-empty)::before,
  &.icon:not(:empty, .btn-empty)::before,
  &.menubtn:not(.action-btn)[data-icon]:empty::before,
  &.menubtn:not(.action-btn)[data-icon].btn-empty::before,
  &.menubtn:not(.action-btn).icon:empty::before,
  &.menubtn:not(.action-btn).icon.btn-empty::before {
    margin-inline-end: 5px;
  }

  &:not(.loading) .spinner {
    display: none;
  }

  &.loading {
    &::before,
    &::after,
    .label {
      visibility: hidden;
    }
  }

  div.checkbox {
    margin-block-start: 2px;
  }

  [data-icon] {
    margin-block-start: -3px;
    margin-inline-end: 5px;

    &.light::before {
      color: var(--gray-300);
    }
  }

  .cp-icon svg {
    @include mixins.svg-mask(currentColor); // Matches color of font icons
  }
}

.disabled:not(.status, .status-label) {
  opacity: 0.25;
  pointer-events: none;
  user-select: none;
}

.noteditable {
  cursor: not-allowed;

  .element,
  .lightswitch-container,
  & + .colorhex {
    cursor: not-allowed;
  }
}

.btn,
.spinner {
  height: var(--ui-control-height);
}

.btn[data-icon-after]:not(:empty, .btn-empty)::after,
.menu-toggle:not(:empty, .btn-empty)::after,
.menubtn:not(.action-btn, :empty, .btn-empty)::after,
.menubtn:not(.action-btn).icon::after {
  margin-inline-start: 6px;
}

.btn[data-icon]::before,
.btn[data-icon-after]::after,
.btn.icon::before {
  position: relative;
}

.btn.small[data-icon]::before,
.btn.small[data-icon-after]::after,
.btn.icon.small::before {
  font-size: 10px;
}

/* button groups */
.btngroup {
  position: relative;
  z-index: 1;
  display: flex;
  white-space: nowrap;
  align-items: center;
  border-radius: var(--large-border-radius);

  &.fullwidth .btn {
    flex: 1;
  }

  &.disabled .btn {
    cursor: default;
  }

  .btn {
    &:focus {
      z-index: 1;
    }

    &:not(.dashed, :last-child, .btngroup-btn-last) {
      margin-inline-end: 1px;
    }

    &:not(:first-child, .btngroup-btn-first) {
      border-start-start-radius: 0;
      border-end-start-radius: 0;
    }

    &:not(:last-child, .btngroup-btn-last) {
      border-start-end-radius: 0;
      border-end-end-radius: 0;
    }
  }
}

.btngroup--exclusive {
  .btn[aria-pressed='true']:not(.disabled, .loading, .dashed, [aria-disabled]) {
    --focus-ring: var(--focus-ring-outset);
    background-color: var(--gray-500);
    color: var(--white);
    @include mixins.dark-btn-light-bg-focus-ring;
  }
}

.copytext {
  position: relative;
  z-index: 1;
  display: flex;
  white-space: nowrap;
  align-items: center;

  .text {
    border-start-end-radius: 0;
    border-end-end-radius: 0;
    min-width: 0;
  }

  .btn {
    border-start-start-radius: 0;
    border-end-start-radius: 0;
  }
}

.copytextbtn {
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  border: 1px solid var(--hairline-color);
  border-radius: var(--small-border-radius);
  padding-block: var(--xs);
  padding-inline: calc(var(--xs) + 1px);
  cursor: pointer;
  color: var(--medium-text-color);
  gap: var(--xs);
  line-height: 1;

  .copytextbtn__icon {
    font-size: calc(12rem / 16);
    display: inline-flex;
    justify-content: center;
    padding: 0;
    width: 13px;
    height: 13px;
    background: none;
    color: var(--light-text-color);
  }

  &:hover,
  &:focus {
    border-color: var(--medium-hairline-color);
    color: var(--text-color);

    .copytextbtn__value {
      color: var(--text-color);
    }
  }

  &.small {
    padding-block: 2px;
    padding-inline: var(--xs);

    .copytextbtn__value {
      font-size: calc(11rem / 16);
    }
  }
}

/* menu buttons */
.menu-toggle,
.menubtn:not(.action-btn) {
  display: inline-flex;
  align-items: center;
  user-select: none;

  &::after {
    @include mixins.angle;
    position: relative;
  }

  &.btn {
    &::after {
      inset-block-start: -1px;
    }

    &:not(.disabled, .inactive) {
      &:active,
      &.active {
        &::after {
          border-color: var(--ui-control-active-color);
        }
      }
    }

    &.submit {
      &::after {
        border-color: var(--white) !important;
        opacity: 0.8;
      }

      &:not(.disabled, .inactive) {
        &:hover,
        &.hover,
        &:active,
        &.active {
          &::after {
            opacity: 1;
          }
        }
      }
    }
  }

  &:not(.btn, .icon) {
    height: 17px;

    &::after {
      inset-block-start: -2px;
      border-color: currentcolor;
    }
  }

  &:empty,
  &.btn-empty {
    padding-inline: 8px;
  }
}

@keyframes rotator {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(1turn);
  }
}

/* spinner */
.spinner {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--size, 24px);
  height: var(--size, 34px);

  &::before {
    display: block;
    content: '';
    font-size: 0;
    animation: rotator 0.7s linear infinite;
    box-sizing: border-box;
    width: var(--size, 20px);
    height: var(--size, 20px);
    object-fit: scale-down;
    border-radius: 50%;
    border: 2px solid transparent;
    border-inline-end-color: currentcolor;
    border-block-end-color: currentcolor;
    opacity: 0.8;
  }

  &.small {
    --size: 12px;
  }

  &.big {
    --size: 48px;
  }

  &.spinner-absolute {
    position: absolute;
    width: var(--size, 20px);
    height: var(--size, 20px);
    inset-block-start: var(--elements-busy-top-position);
    inset-inline-start: var(--elements-busy-top-position);
    z-index: 101;
  }
}

.btn + .spinner {
  margin-inline-start: 7px;
}

.buttons .btn + .spinner,
.buttons .btngroup + .spinner {
  margin-inline-start: 0;
}

.buttons.right .btn + .spinner {
  margin-inline-end: var(--neg-padding);
}

/* small buttons */
.btngroup.small .btn,
.btn.small {
  padding-block: 0 !important;
  padding-inline: var(--s) !important;
  font-size: 12px;
  line-height: 22px;
}

.btngroup.small,
.btngroup.small input.btn,
.btn.small,
.btn.small + .spinner {
  height: 22px;
}

/* big buttons */
.btngroup.big .btn,
.btn.big {
  padding-block: 0;
  padding-inline: 14px;
  font-size: 14px;
  line-height: 36px;
}

.btn.big[data-icon]::before,
.preview-btn::before,
.view-btn::before {
  margin-inline-start: -2px;
}

.btngroup.big,
.btngroup.big input.btn,
.btn.big,
.btn.big + .spinner {
  height: 36px;
}

/* huge buttons */
.btn.huge {
  padding: var(--l);
  line-height: 48px;
  height: 48px;
}

/* special buttons */
.btn {
  &.submit,
  &.secondary {
    color: var(--white) !important;

    --ui-control-active-color: var(--white);
    --focus-ring: var(--focus-ring-outset);
    @include mixins.light-on-dark-text;
  }

  &.submit {
    background-color: var(--primary-color) !important;

    &:not(.disabled, .inactive, .loading) {
      &:hover,
      &.hover,
      &:focus {
        background-color: color.adjust(
          mixins.$primaryColor,
          $lightness: -5%
        ) !important;
      }

      &:active,
      &.active,
      &[aria-expanded='true'] {
        background-color: color.adjust(
          mixins.$primaryColor,
          $lightness: -10%
        ) !important;
      }
    }
  }

  &.caution {
    background-color: var(--red-050) !important;
    color: var(--error-color);

    &:hover,
    &.hover,
    &:focus {
      background-color: color.adjust(
        mixins.$red050,
        $lightness: -5%
      ) !important;
    }

    &:active,
    &.active,
    &[aria-expanded='true'] {
      background-color: var(--red-100) !important;
    }
  }
}

.secondary-buttons .btn.submit,
.btn.secondary {
  background-color: var(--secondary-color) !important;
}

.secondary-buttons .btn.submit:not(.disabled, .inactive, .loading):hover,
.secondary-buttons .btn.submit:not(.disabled, .inactive, .loading).hover,
.secondary-buttons .btn.submit:not(.disabled, .inactive, .loading):focus,
.btn.secondary:not(.disabled, .inactive, .loading):hover,
.btn.secondary:not(.disabled, .inactive, .loading).hover,
.btn.secondary:not(.disabled, .inactive, .loading):focus {
  background-color: color.adjust(
    mixins.$secondaryColor,
    $lightness: -5%
  ) !important;
}

.secondary-buttons .btn.submit:not(.disabled, .inactive, .loading):active,
.secondary-buttons .btn.submit:not(.disabled, .inactive, .loading).active,
.secondary-buttons
  .btn.submit:not(.disabled, .inactive, .loading)[aria-expanded='true'],
.btn.secondary:not(.disabled, .inactive, .loading):active,
.btn.secondary:not(.disabled, .inactive, .loading).active,
.btn.secondary:not(.disabled, .inactive, .loading)[aria-expanded='true'] {
  background-color: color.adjust(
    mixins.$secondaryColor,
    $lightness: -10%
  ) !important;
}

div.btn.submit {
  position: relative;
  overflow: hidden;
}

div.btn.submit input {
  position: absolute;
  inset-inline-start: 100%;
}

/* dashed buttons */
.btn.dashed {
  border: 1px dashed var(--medium-hairline-color);
  background-color: transparent;

  .btngroup &:not(:last-child, .btngroup-btn-last) {
    border-inline-end: 1px solid transparent;
    margin-inline-end: -1px;
  }

  &:focus {
    background-color: color.adjust(mixins.$grey200, $alpha: -0.9);
    border-color: transparent;

    .reduce-focus-visibility &:not(:focus-visible) {
      border: 1px dashed var(--medium-hairline-color);
    }
  }

  &:not(.disabled) {
    &:active,
    &.active,
    &[aria-expanded='true'] {
      background-color: color.adjust(mixins.$grey200, $alpha: -0.75);
    }
  }
}

/* chevron buttons */
.chevron-btns {
  display: flex;
  justify-content: flex-start;

  .btn {
    position: relative;
    padding: 0;
    border-radius: 0;
    background-color: transparent;

    &.current-step,
    &.active-drop-target {
      color: var(--white);

      --text-color: var(--white);
      --ui-control-color: var(--white);
      --ui-control-bg-color: var(--gray-500);
      --ui-control-hover-bg-color: #{color.adjust(
          mixins.$grey500,
          $lightness: -5%
        )};
      --ui-control-active-bg-color: #{color.adjust(
          mixins.$grey500,
          $lightness: -10%
        )};
    }

    &.current-step {
      @include mixins.light-on-dark-text;
      @include mixins.light-focus-ring;
    }

    &:focus {
      z-index: 1;
      box-shadow: none;

      .label {
        z-index: 1;
        box-shadow: var(--focus-ring);
      }

      .reduce-focus-visibility &:not(:focus-visible) .label {
        box-shadow: none;
      }
    }

    .reduce-focus-visibility &:focus-visible .label {
      box-shadow: var(--focus-ring);
    }

    &:not(.has-action-menu) {
      padding-inline-end: calc(var(--ui-control-height) / 2);
      margin-inline-end: 2.8px;

      &:not(.current-step[data-disclosure-trigger]) .btn-body {
        padding-inline-end: 5px;
      }
    }

    &.current-step[data-disclosure-trigger] .btn-body {
      padding-inline-start: 8px;

      .label {
        padding: 3px;

        &::after {
          @include mixins.angle;
          position: relative;
        }
      }
    }

    .btn-body {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: var(--ui-control-height);
      background-color: var(--ui-control-bg-color);

      [data-icon] {
        --ui-control-height: calc((13rem / 16)); // 1rem == 16px
        text-align: center;
        margin-inline-end: 0;
      }
    }

    .label {
      display: inline-flex;
      align-items: center;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.has-action-menu .btn-body {
      padding-inline-end: 1px;
      margin-inline-end: 0;
    }

    .chevron-right,
    .chevron-left {
      position: absolute;
      inset-block-start: 0;
      height: 100%;
      width: calc(var(--ui-control-height) / 2);
    }

    .chevron-left {
      inset-inline-start: 0;
      border-block-start: calc(var(--ui-control-height) / 2) solid
        var(--ui-control-bg-color);
      border-inline-start: calc(var(--ui-control-height) / 2) solid transparent;

      &::after {
        display: block;
        content: '';
        font-size: 0;
        position: absolute;
        inset-block-end: 0;
        inset-inline-end: 0;
        border-block-end: calc(var(--ui-control-height) / 2) solid
          var(--ui-control-bg-color);
        border-inline-start: calc(var(--ui-control-height) / 2) solid
          transparent;
      }
    }

    .chevron-right {
      inset-inline-end: 0;
      border-block-start: calc(var(--ui-control-height) / 2) solid transparent;
      border-block-end: calc(var(--ui-control-height) / 2) solid transparent;
      border-inline-start: calc(var(--ui-control-height) / 2) solid
        var(--ui-control-bg-color);
    }
  }

  ol {
    position: relative;
    padding-inline-start: 0;
    list-style-type: none;
    display: flex;

    li {
      &.first-step .btn {
        .btn-body {
          padding-inline-start: 14px;
          border-start-start-radius: var(--ui-control-border-radius);
          border-start-end-radius: 0;
          border-end-end-radius: 0;
          border-end-start-radius: var(--ui-control-border-radius);
        }

        .chevron-left {
          display: none;
        }
      }

      &:not(.first-step) .btn {
        margin-inline-start: calc(-1 * (var(--ui-control-height) / 2));
        padding-inline-start: calc(var(--ui-control-height) / 2);

        .btn-body {
          padding-inline-start: 7px;
        }
      }
    }
  }
}

/* color inputs */
.color-input-container {
  position: relative;

  .color-hex-indicator {
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 7px;
    width: 1em;
    line-height: 34px;
    text-align: center;
    user-select: none;
  }
}

.color-input {
  @include mixins.fixed-width-font;
  padding-inline: calc(7px + 1em) 9px;
}

.color {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  width: 34px;
  height: 34px;
  border-radius: 17px;
  padding: 0;

  &:not(.static, .noteditable) {
    cursor: pointer;
  }

  &:not(.small) {
    @include mixins.checkered-bg(17px);
  }

  &.small {
    width: 16px;
    height: 16px;
    @include mixins.checkered-bg(8px);
  }

  .color-preview {
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 0;
    width: 100%;
    height: 100%;
    border-radius: 17px;
    box-shadow: inset 0 0 0 1px color.adjust(mixins.$black, $alpha: -0.85);

    &:focus-within {
      @include mixins.input-focused-styles;
    }

    & > .color-preview-input {
      position: absolute;
      inset-inline-start: 0;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      border: none;
      opacity: 0;
    }
  }
}

.colorhex {
  display: inline-block;
  margin-inline-start: 5px;
  vertical-align: middle;
  color: var(--medium-text-color);
}

/* lightswitch */
.lightswitch-outer-container {
  display: flex;

  .lightswitch-inner-container {
    border: 1px solid var(--hairline-color);
    border-radius: var(--small-border-radius);
    display: flex;
    align-items: center;
    max-width: 100%;
    padding-inline: 7px;

    span {
      flex-grow: 1;
      padding-block: 7px;
      padding-inline: 0;
      color: var(--medium-text-color);
      cursor: default;
      overflow: auto;
      overflow-wrap: break-word;
      hyphens: auto;

      &[data-toggle='on'] {
        margin-inline-start: 7px;
      }

      &[data-toggle='off'] {
        margin-inline-end: 7px;
        text-align: end;
      }
    }
  }
}

.lightswitch {
  display: block;
  position: relative;
  border: none !important;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
  background-image: linear-gradient(to right, var(--gray-400), var(--gray-400));
  transition: background-image linear 100ms;

  &.on {
    background-image: linear-gradient(
      to right,
      var(--enabled-color),
      var(--enabled-color)
    );
  }

  &.indeterminate {
    background-image: linear-gradient(
      to right,
      var(--enabled-color),
      var(--gray-300)
    );
  }

  .lightswitch-container {
    position: relative;
    height: 100%;

    .handle {
      position: absolute;
      inset-block-start: 1px;
      background-color: var(--white);
    }
  }

  &:not(.small) {
    border-radius: 11px;
    min-width: 34px;
    width: 34px;
    height: 22px;

    .lightswitch-container {
      margin-inline-start: -12px;
      width: 46px;

      .handle {
        border-radius: 10px;
        width: 20px;
        height: 20px;
        inset-inline-start: calc(50% - 10px);
      }
    }
  }

  &.small {
    border-radius: 9px;
    width: 28px;
    height: 18px;

    .lightswitch-container {
      margin-inline-start: -10px;
      width: 38px;

      .handle {
        border-radius: 8px;
        width: 16px;
        height: 16px;
        inset-inline-start: calc(50% - 8px);
      }
    }
  }

  table & {
    display: inline-block;
    margin-block-end: -5px;
  }

  &.on {
    .lightswitch-container {
      margin-inline-start: 0;
    }
  }

  &.indeterminate {
    &:not(.small) {
      .lightswitch-container {
        margin-inline-start: 0.5 * -12px;
      }
    }

    &.small {
      .lightswitch-container {
        margin-inline-start: 0.5 * -10px;
      }
    }
  }
}

/* pagination */
.pagination {
  table.data + & {
    margin-block-start: 24px;
  }

  .page-link {
    width: calc(30rem / 16);
    height: calc(30rem / 16);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--medium-border-radius);

    &::after {
      position: relative;
      transition: border-color linear 100ms;
    }

    &.prev-page::after {
      @include mixins.angle(left, var(--light-text-color));
      inset-inline-end: -1px;
    }

    &.next-page::after {
      @include mixins.angle(right, var(--light-text-color));
      inset-inline-start: -1px;
    }

    &:not(.disabled) {
      transition: box-shadow linear 100ms;
      box-shadow: inset 0 0 0 1px var(--hairline-color);
      cursor: pointer;

      &:hover {
        text-decoration: none;
        box-shadow: inset 0 0 0 1px var(--link-color);

        &::after {
          border-color: var(--link-color);
        }
      }

      @include mixins.focus-styles {
        box-shadow:
          inset 0 0 0 1px var(--hairline-color),
          var(--focus-ring);
      }
    }

    &.disabled {
      opacity: 1;

      &::after {
        border-color: var(--hairline-color);
      }
    }
  }
}

/* action buttons */
.actions {
  float: inline-end;
}

.actions > li {
  float: inline-start;
}

.actions > li + li {
  margin-inline-start: 10px;
}

h1 + .actions {
  margin-block-start: -100px;
}

h2 + .actions {
  margin-block-start: -54px;
}

/* ----------------------------------------
/*  Tables
/* ---------------------------------------- */

.tablepane {
  --pane-y-padding-default: calc(var(--xl) - 2px);
  --pane-x-padding-default: calc(var(--padding) - 2px);
  margin-block: calc(var(--pane-padding, var(--pane-y-padding-default)) * -1)
    calc(
      var(--pane-padding, var(--padding)) * -1 + var(--pane-padding, var(--m))
    );
  margin-inline: calc(var(--pane-padding, var(--pane-x-padding-default)) * -1);
  padding: 0 !important;
  overflow-x: auto;

  table.data {
    th,
    td {
      &:first-child:not(.checkbox-cell, .vuetable-th-slot-checkbox) {
        padding-inline-start: var(
          --pane-padding,
          var(--pane-x-padding-default)
        );
      }

      &:last-child {
        padding-inline-end: var(--pane-padding, var(--pane-x-padding-default));
      }
    }
  }
}

table {
  &.fixed-layout {
    table-layout: fixed;
  }

  th.thin,
  td.thin {
    width: 0.01% !important;
    white-space: nowrap;
  }

  thead {
    th {
      font-weight: bold;
      text-align: start;
      vertical-align: top;
    }
  }

  // plain tables
  &:not(.data) {
    th,
    td {
      padding-block: 7px;

      &:not(:first-child) {
        padding-inline-start: 12px;
      }

      &:not(:last-child) {
        padding-inline-end: 12px;
      }
    }
  }

  // data tables
  &.data {
    th,
    td {
      position: relative;
      padding-inline: 12px;
      box-sizing: border-box;

      &.checkbox-cell,
      &.vuetable-th-slot-checkbox {
        width: var(--checkbox-size) !important;
        min-width: var(--checkbox-size);
        box-sizing: content-box;
        position: relative;

        input.checkbox + label,
        div.checkbox {
          position: absolute;
          inset-block-start: calc(50% - 8rem / 16);
        }
      }
    }

    th {
      font-weight: bold;
    }

    thead:first-child,
    tbody:first-child,
    tfoot:first-child,
    caption + thead,
    caption + tbody,
    caption + tfoot {
      tr:first-child {
        th,
        td {
          &:first-child {
            border-start-start-radius: var(
              --border-radius,
              var(--small-border-radius)
            );
            border-end-start-radius: var(
              --border-radius,
              var(--small-border-radius)
            );
          }

          &:last-child {
            border-start-end-radius: var(
              --border-radius,
              var(--small-border-radius)
            );
            border-end-end-radius: var(
              --border-radius,
              var(--small-border-radius)
            );
          }
        }
      }
    }

    thead {
      th,
      td {
        width: auto;
        background-color: var(--gray-050);
        cursor: default;
      }

      th:not(.orderable),
      td {
        padding-block: var(--s);
      }

      th {
        white-space: nowrap;
        vertical-align: middle;

        &.orderable {
          position: relative;
          padding: 0 !important;

          body:not(.dragging) &:not(.ordered):hover {
            background-color: var(--gray-100);
          }

          &.ordered {
            background-color: var(--light-sel-color);

            &:not(.loading) {
              button::after {
                @include mixins.angle(up);
                position: absolute;
                inset-inline-end: var(--m);
                inset-block-start: calc(50% - 3px);
              }

              &.desc button::after {
                transform: rotate(45deg);
              }
            }
          }

          button {
            position: relative;
            font: inherit;
            width: 100%;
            padding-block: var(--pane-padding, var(--m));
            padding-inline: var(--m) 35px;
            text-align: start;

            &:focus {
              z-index: 1;
            }
          }
        }

        &:not(.loading) {
          .spinner {
            display: none;
          }
        }

        .spinner {
          position: absolute;
          inset-block-start: calc(50% - 6px);
          inset-inline-end: 8px;

          --size: 12px;
        }
      }
    }

    tbody {
      tr {
        --hover-bg-color: var(--gray-050);
        --selected-bg-color: var(--dark-sel-color);

        &:not(.disabled) {
          &:hover {
            th,
            td {
              background-color: var(--hover-bg-color);
            }
          }

          &:focus {
            position: relative;
            z-index: 1;
          }

          &.sel,
          &.active-drop-target {
            --text-color: var(--white);
            --medium-dark-text-color: var(--white);
            --medium-text-color: var(--white);
            --light-text-color: var(--white);
            --link-color: var(--white);
            --ui-control-color: var(--white);
            @include mixins.custom-color-focus-ring(
              hsl(var(--light-focus-hsl))
            );

            .status-label .status-label-text {
              --status-label-text-color: var(--white) !important;
            }

            th,
            td {
              color: var(--white);
              background-color: var(--selected-bg-color);
            }

            .icon,
            .cp-icon,
            [data-icon],
            [data-icon-after] {
              --icon-color: var(--white) !important;
            }
          }
        }
      }

      th,
      td {
        padding-block: 7px;
        max-width: 33vw;
      }

      td {
        &.timestamp {
          text-align: end;
          vertical-align: bottom;
          white-space: nowrap;
          color: var(--light-text-color);
        }
      }
    }

    thead + tbody tr,
    tr + tr {
      th,
      td {
        border-block-start: 1px solid transparent;
      }
    }

    tr.sel:not(.draggee) + tr.sel:not(.draggee) {
      th,
      td {
        border-block-start-color: var(--gray-300);
      }
    }
  }

  // collapsable data tables for small screens
  // based on Aaron Gustafson's technique: http://blog.easy-designs.net/archives/2013/02/02/responsive-tables/
  &.collapsed {
    width: auto;

    &,
    tbody,
    tbody tr,
    tbody th,
    tbody td {
      display: block;
      border: none;
      padding: 0;
      text-align: start;
      width: auto !important;
      white-space: normal;
    }

    thead {
      display: none;
    }

    tbody {
      tr {
        padding-block: 6px;
        padding-inline: 0;
        border-block-end: 1px dotted var(--hairline-color);

        &::after {
          @include mixins.clearafter;
        }
      }

      th,
      td {
        padding-block: 2px !important;
        padding-inline: 0 !important;
      }

      td {
        &:empty {
          display: none;
        }
      }

      [data-title] {
        margin-inline-end: 0;

        &::before {
          margin-inline-end: 5px;
          content: attr(data-title) ':';
          font-weight: bold;
        }

        form {
          display: inline-block;
        }
      }
    }
  }
}

.datatablesorthelper,
.editabletablesorthelper {
  background-color: var(--white);
  @include mixins.shadow;
}

.datatablesorthelper,
.datatablesorthelper .chip,
.datatablesorthelper a {
  cursor: move !important;
}

.datatablesorthelper tr:first-child th,
.datatablesorthelper tr:first-child td {
  border-block-start: none !important;
}

.datatablesorthelper tr:last-child th,
.datatablesorthelper tr:last-child td {
  border-block-end: none !important;
}

.datatablesorthelper th .toggle {
  display: none !important;
}

.chip,
.card {
  color: var(--custom-text-color, var(--text-color));
  background-color: var(--custom-bg-color, var(--gray-050));

  &.hairline {
    border: 1px solid var(--medium-hairline-color);
    background-color: transparent;
  }
}

/* chips */
.chip {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: default;
  user-select: none;
  font-weight: normal;
  padding-block: 0;
  padding-inline: var(--s);
  max-width: 100%;
  gap: var(--xs);
  border-radius: var(--large-border-radius);

  &.small {
    flex-direction: row;
    min-height: calc(26rem / 16 + 8px);

    --thumb-size: calc(30rem / 16);

    & > .thumb {
      margin-inline: -2px 2px;
      margin-block: 0;

      &.cp-icon {
        --thumb-size: 1rem; // 16px
      }
    }
  }

  &.large {
    flex-direction: column;

    --thumb-size: 120px;
    min-width: calc(var(--s) * 2 + var(--thumb-size));
    width: 100%;
    padding-block: var(--xs);

    .chip-content {
      width: 100%;
    }
  }

  .chip-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--s);
    max-width: 100%;

    .status,
    .icon {
      margin-inline-end: 0;
    }

    .icon {
      margin-block: -4px 0;
      margin-inline: -1px;
    }

    .chip-label {
      padding-block: var(--2xs);
      padding-inline: 0;
    }

    .chip-actions {
      margin-inline-start: auto;
    }
  }

  &:has(.thumb):not(.large) .chip-content {
    min-width: calc(100% - var(--thumb-size));
  }

  &.sel,
  li.sel &,
  li.active-drop-target &,
  .card.sel & {
    --text-color: var(--white);
    --custom-text-color: var(--white) !important;
    --medium-dark-text-color: var(--white);
    --medium-text-color: var(--white);
    --light-text-color: var(--white);
    --link-color: var(--white);
    --ui-control-color: var(--white);
    @include mixins.custom-color-focus-ring(hsl(var(--light-focus-hsl)));
    color: var(--white);
    background-color: var(
      --custom-sel-bg-color,
      var(--dark-sel-color)
    ) !important;

    .icon,
    .cp-icon,
    [data-icon],
    [data-icon-after] {
      --icon-color: var(--white) !important;
    }
  }

  &.sel,
  .sel & {
    cursor: default;

    .thumb {
      filter: brightness(0.85);

      img {
        box-shadow:
          0 0 0 1px color.adjust(mixins.$darkSelColor, $alpha: -0.9),
          0 6px 4px -4px color.adjust(mixins.$darkSelColor, $alpha: -0.8);
      }
    }
  }

  .offset-drag-helper & {
    background-color: var(--gray-050);
    @include mixins.shadow;
  }

  & > .thumb {
    flex: 0 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--thumb-size);
    height: var(--thumb-size);

    &.rounded {
      img,
      svg {
        border: var(--xs) solid transparent;
        border-radius: 50%;
      }
    }

    &.checkered img {
      @include mixins.checkered-bg(8px);
    }

    img,
    svg {
      display: block;
      flex-shrink: 0;
      border-radius: var(--small-border-radius);
      max-width: 100%;
      max-height: 100%;
    }

    svg {
      width: 100%;
    }
  }
}

.cp-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--icon-size);
  height: var(--icon-size);
  max-width: var(--icon-size);

  svg {
    width: var(--icon-size);
    height: var(--icon-size);
    max-width: var(--icon-size) !important;
    max-height: var(--icon-size) !important;
    @include mixins.svg-mask(var(--icon-color, var(--ui-control-color)));
  }

  &.large {
    --icon-size: calc(20rem / 16);
  }

  &.small {
    --icon-size: calc(14rem / 16);
  }

  &.puny {
    --icon-size: calc(12rem / 16);
  }
}

.chips,
.cards {
  display: flex;

  --custom-bg-color: initial;
  --custom-text-color: initial;
  --custom-sel-bg-color: initial;

  &:not(.inline-chips) {
    flex-direction: column;
  }

  li {
    max-width: 100%;
  }
}

.cards {
  gap: var(--s);
}

.chips {
  align-items: flex-start;
  gap: var(--xs);

  &.chips-small {
    li {
      height: calc(34rem / 16);
    }
  }
}

.inline-chips {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: var(--s);

  .chip {
    margin: 0 !important;
  }
}

.elements.inline-chips {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
}

/** cards */
.card {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding: var(--m);
  gap: var(--s);
  border-radius: var(--large-border-radius);
  cursor: default;
  user-select: none;
  width: 100%;

  &::after {
    border-radius: var(--large-border-radius);
  }

  &.sel,
  li.sel & {
    --text-color: var(--white);
    --custom-text-color: var(--white) !important;
    --medium-dark-text-color: var(--white);
    --medium-text-color: var(--white);
    --light-text-color: var(--white);
    --link-color: var(--white);
    --ui-control-color: var(--white);
    @include mixins.custom-color-focus-ring(hsl(var(--light-focus-hsl)));
    color: var(--white);
    background-color: var(
      --custom-sel-bg-color,
      var(--dark-sel-color)
    ) !important;

    & > .thumb {
      background-color: var(--dark-sel-color) !important;
      filter: brightness(0.85);
    }

    .icon,
    .cp-icon,
    [data-icon],
    [data-icon-after] {
      --icon-color: var(--white) !important;
    }

    .status-label .status-label-text {
      --status-label-text-color: var(--white) !important;
    }
  }

  .ck-content & {
    ul {
      padding-inline: 0 !important;
      list-style-type: none;
    }
  }

  & > .thumb {
    position: relative;
    display: flex;
    align-items: stretch;
    margin: calc(var(--m) * -1);
    margin-inline-end: 0;
    width: 128px;
    min-width: 128px; // needed for flex
    min-height: calc(var(--m) * 2 + var(--lh) * 3 + var(--xs));
    background-color: var(--gray-100);
    overflow: hidden;

    &::after {
      display: block;
      content: '';
      font-size: 0;
      position: absolute;
      inset-block: 0;
      @include mixins.shadow;
      width: 10px;
      inset-inline-end: -10px;
    }

    &,
    &::after {
      border-start-start-radius: var(--large-border-radius);
      border-start-end-radius: 0;
      border-end-end-radius: 0;
      border-end-start-radius: var(--large-border-radius);
    }

    img,
    svg {
      width: 100%;
      max-height: 128px;
      object-fit: cover;
    }

    & + .card-content {
      margin-inline-start: calc(var(--m) - var(--s));
    }
  }

  & > .cp-icon {
    margin-inline: -2px -4px;
    margin-block: 3px 0;
  }

  .card-content,
  .card-content .card-body {
    display: flex;
    flex-direction: column;
    gap: var(--xs);
    flex: 1;
  }

  .card-content {
    flex: 1 0 0 !important;
    overflow: hidden;
    position: relative;

    --focus-ring: var(--inner-focus-ring);

    .card-heading {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;
      min-height: calc(22rem / 16);

      .label {
        font-weight: 600;
      }
    }

    .card-body {
      &:empty {
        display: none;
      }

      & > *:not(:has(> .no-truncate)) {
        --max-lines: 2;
        margin: 0;
        max-height: calc(var(--lh) * var(--max-lines));
        overflow: hidden;
        text-wrap: pretty;
      }

      .checkbox-preview-label {
        display: inline;
        font-style: italic;
        margin-inline-start: 5px;
      }
    }
  }

  & > .card-actions-container {
    display: flex;
    align-items: flex-start !important;
    position: relative;
  }
}

.card-grid {
  display: grid !important;
  gap: var(--s) !important;
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));

  & > li {
    border-radius: var(--large-border-radius);

    & > .card {
      margin: 0 !important;
      height: 100%;
    }
  }
}

// Adjust the columns when in live preview
.lp-editor-container .card-grid {
  grid-template-columns: minmax(0, 1fr);
}

.context-label,
.context-menu-container {
  display: inline-flex;
  align-items: center;
  margin-inline-start: var(--xs);
  height: var(--touch-target-size);
  padding-block: 0;
  padding-inline: var(--s);
  font-size: 11px;
  font-weight: normal;
  text-decoration: none !important;
  color: var(--medium-dark-text-color);
  border-radius: calc(var(--touch-target-size) / 2);
  border: 1px solid var(--medium-hairline-color);
}

.context-menu-container {
  padding-inline-end: 0;

  .menubtn:not(.action-btn) {
    margin-inline: 0 -1px;
    margin-block: 0;
    padding-inline-end: 2px !important;
    border-inline-start: 1px solid var(--medium-hairline-color);
    border-start-start-radius: 0;
    border-start-end-radius: calc(var(--touch-target-size) / 2);
    border-end-end-radius: calc(var(--touch-target-size) / 2);
    border-end-start-radius: 0;

    &:hover {
      border-color: var(--dark-hairline-color) !important;
    }
  }

  > * {
    display: inline-block;
    padding-inline-end: var(--s);
  }
}

// fix for context menus that are only children
.context-menu-container .menubtn:not(.action-btn):only-child,
.context-menu-container
  .visually-hidden:first-child
  + .menubtn:not(.action-btn):last-child {
  margin-block: 0;
  margin-inline: calc(var(--s) * -1);
  border-inline-start: none !important;
  border-radius: calc(var(--touch-target-size) / 2) !important;
}

.chip,
.card {
  // prevent double focus ring (chip/card already gets it)
  .checkbox::before {
    box-shadow: none !important;
  }

  .label {
    display: flex;
    align-items: center;
    gap: var(--xs);
    overflow: hidden;

    .label-link {
      flex: 1 1 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:focus {
        box-shadow: var(--inner-focus-ring);
      }
    }

    // label links inside cards and chip should be coloured as regular a tags despite not having href attribute
    a.label-link {
      color: var(--link-color);
    }

    .segment::after {
      display: inline-flex !important;
      position: relative;
      inset-block-start: -2px;
      margin-inline: 2px 5px;
      margin-block: 0;
      @include mixins.angle(right, var(--light-text-color), calc(1rem / 16));

      .sel & {
        @include mixins.angle(right, var(--white), calc(1rem / 16));
      }
    }
  }

  & > .chip-content > .chip-actions,
  & > .card-actions-container > .card-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--s);
    min-height: calc(22rem / 16);

    &:empty {
      display: none;
    }

    & > .status {
      margin: 0;
    }

    & > .action-btn,
    & > .move {
      height: var(--touch-target-size);
      width: var(--touch-target-size);
      margin-block: 0;
      margin-inline: -4px;

      &::before {
        color: var(--custom-text-color, var(--ui-control-color));
      }
    }

    .action-btn {
      background-color: transparent;

      &::before {
        margin-inline-end: 0 !important;
      }

      &:hover,
      &:active,
      &[aria-expanded='true'] {
        backdrop-filter: brightness(90%);
      }
    }

    .move {
      margin-block-start: -3px !important;
    }
  }

  &.error .label .label-link {
    color: var(--error-color);
  }
}

.element-index {
  .source-path {
    background-color: var(--gray-050);
    border-radius: 3px;

    --ui-control-bg-color: var(--light-sel-color);
    --ui-control-hover-bg-color: #{color.adjust(
        mixins.$lightSelColor,
        $lightness: -5%
      )};
    --ui-control-active-bg-color: #{color.adjust(
        mixins.$lightSelColor,
        $lightness: -10%
      )};

    .chevron-btns {
      display: inline-flex;
      position: relative;
      background: mixins.$white;

      --ui-control-height: calc((30rem / 16)); // 1rem == 16px

      &::before {
        display: block;
        content: '';
        font-size: 0;
        position: absolute;
        inset-block-start: 0;
        width: 0;
        height: 0;
        inset-inline-end: 0;
        border-block-start: calc(var(--ui-control-height) / 2) solid
          mixins.$grey050;
        border-inline-start: calc(var(--ui-control-height) / 2) solid
          transparent;
      }

      &::after {
        display: block;
        content: '';
        font-size: 0;
        position: absolute;
        inset-block-start: calc(var(--ui-control-height) / 2);
        inset-inline-end: 0;
        border-block-end: calc(var(--ui-control-height) / 2) solid
          mixins.$grey050;
        border-inline-start: calc(var(--ui-control-height) / 2) solid
          transparent;
      }

      .btn {
        --ui-control-border-radius: 3px;
      }
    }

    .btn.settings {
      box-shadow: 0 0 0 2px var(--white);
    }
  }

  &.main {
    .source-path {
      margin-block: -22px 24px;
      margin-inline: calc(var(--padding) * -1 + 2px);
    }

    .inline-editing {
      textarea,
      input[type='email'],
      input[type='text'].fullwidth,
      input[type='url'],
      .link-input {
        min-width: 10rem;
      }

      .link-input .flex-nowrap,
      .icon-picker {
        flex-wrap: wrap;
      }

      .money-container input[type='text'].fullwidth,
      input[type='text'][inputmode].fullwidth {
        min-width: 5rem;
        width: 5rem;
      }

      .lightswitch-outer-container .lightswitch-inner-container span {
        overflow: initial;
      }

      .selectize.select:not(.fullwidth) {
        width: 10rem;
      }

      .flex:has(input[type='text'][inputmode].fullwidth) {
        flex-wrap: nowrap;
      }
    }
  }

  .elementselectormodal & {
    .source-path {
      margin-block-end: 2px;
    }
  }

  &.pane {
    .toolbar {
      position: relative;
      z-index: 2;
      margin-block: calc(var(--pane-padding) * -1) var(--pane-padding) !important;
      margin-inline: calc(var(--pane-padding) * -1) !important;
      border-radius: calc(var(--large-border-radius) - 1px)
        calc(var(--large-border-radius) - 1px) 0 0;
      padding: var(--pane-padding);
      box-shadow: 0 1px 0 var(--hairline-color);
      background-color: var(--gray-050);
    }

    .header {
      padding-block: var(--pane-padding);
      background-color: transparent;
      border-block-end: 1px solid var(--hairline-color);
    }

    .elements {
      --border-radius: 0;

      .tableview table.data thead {
        th,
        td {
          border-block-end: 1px solid var(--hairline-color);

          &:not(.ordered) {
            background-color: transparent;
          }
        }
      }
    }

    .footer {
      border-radius: 0 0 calc(var(--large-border-radius) - 1px)
        calc(var(--large-border-radius) - 1px) !important;
      box-shadow: none;
      border-block-start: 1px solid var(--hairline-color);
      background: transparent;
    }
  }

  craft-proxy-scrollbar {
    position: sticky;
    width: calc(100% + var(--xl) * 2);
    margin-inline: calc(var(--xl) * -1);
  }
}

.elements {
  position: relative;

  &:not(.busy) {
    .update-spinner {
      display: none;
    }
  }

  &.busy {
    min-height: 72px;

    &::after {
      display: block;
      content: '';
      font-size: 0;
      position: absolute;
      z-index: 100;
      inset-block-start: 0;
      inset-inline-start: calc(var(--pane-padding, 24px) * -1);
      width: calc(100% + var(--pane-padding, 24px) + var(--pane-padding, 24px));
      height: 100%;
      background: color.adjust(mixins.$white, $alpha: -0.25);
      border-radius: var(--large-border-radius);
    }
  }

  .header {
    margin-block: calc(var(--xl) * -1 + 2px) var(--xl);
    margin-inline: calc(var(--padding) * -1 + 2px);
    padding: var(--s);
    background-color: var(--gray-050);
    box-shadow: none;
    border-radius: var(--border-radius, var(--small-border-radius));

    &::after {
      content: '';
    }

    .selectallcontainer {
      display: flex;
      align-items: center;
      gap: 5px;
      cursor: default;
    }
  }

  .tableview tr.draggee th,
  .tableview tr.draggee td,
  .thumbsview li.draggee {
    opacity: 0.25;
  }

  // table views
  .tableview {
    th {
      .move,
      .toggle {
        display: flex;
        position: absolute;
        justify-content: center;
        align-items: center;
        inset-block-start: 50%;
        transform: translateY(-50%);
        margin-inline-start: calc(var(--touch-target-size) * -1);
        width: var(--touch-target-size);
        height: var(--touch-target-size);
      }

      .move {
        font-size: 11px;
        text-decoration: none;
      }

      .toggle {
        text-align: center;
        padding: 4px;
        border-radius: 50%;
      }

      .move + .toggle {
        margin-inline-start: calc(var(--touch-target-size) * -2);
      }

      .enabled-label {
        margin-inline-start: var(--xs);
      }
    }

    .chip {
      background-color: transparent;
    }

    tr.disabled {
      opacity: 1;

      & > th > div > *,
      & > td {
        opacity: 0.25;
      }

      & > th > div > button.toggle {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  // thumbs views
  .thumbsview {
    display: grid;
    gap: var(--xs);
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));

    li {
      position: relative;
      margin-inline: 0 1px;
      margin-block: 0 1px;
      max-width: 226px;
      border-radius: var(--large-border-radius);

      &:hover {
        .chip {
          background-color: var(--gray-050);
        }
      }

      &.sel {
        a {
          cursor: pointer !important;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .chip.large {
      width: 100%;
    }

    .chip-content {
      max-width: 100%;
    }
  }
}

.tableview {
  .inline-chips {
    .chip {
      padding: 0;
      background-color: transparent;
    }
  }
}

.export-form {
  position: relative;

  .spinner {
    position: absolute;
    inset-block-end: 0;
    inset-inline-end: -24px;
  }
}

/* structures */
.structure {
  position: relative;
  z-index: 1;

  li {
    padding-inline-start: 8px;

    &.collapsed > ul {
      display: none;
    }

    .row:hover > .icon,
    &.draghelper > .row .move,
    .add.active {
      opacity: 1;
    }

    &.draghelper {
      & > .row {
        .add {
          opacity: 0;
        }

        .move::before {
          color: var(--link-color);
        }
      }
    }

    &.draginsertion {
      position: relative;
      margin-inline: 8px 0;
      margin-block: -1px;
      padding-inline-start: 0;
      height: 2px;
      background-color: var(--link-color) !important;
      border-inline-start: none;
      border-radius: 1px;
    }

    .toggle {
      position: relative;
      z-index: 1;
      float: inline-start;
      margin-inline: -12px -8px;
      margin-block: 10px 0;
      padding: 4px;
    }

    .row::after {
      @include mixins.clearafter;
    }

    .move,
    .add {
      margin-inline: 0 5px;
      margin-block: 5px 0;
      opacity: 0;
      transition: opacity linear 100ms;
    }

    .add {
      padding-block: 0;
      padding-inline: 5px;

      &::before {
        content: 'downangle';
        color: var(--dark-hairline-color);
      }

      &:not(.disabled):hover::before,
      &.active::before {
        color: var(--link-color);
      }
    }

    .checkbox {
      float: inline-start;
      margin-inline: 7px 0;
      margin-block: 7px 0;
    }
  }

  ul {
    margin-inline-start: -3px;

    li {
      padding-inline-start: 38px;
      background-repeat: no-repeat;

      --background-position-x: 0;
      --background-position-y: -6px;
      background-position: var(--background-position-x)
        var(--background-position-y);

      body.ltr & {
        background-image: url('../images/branch.png');
      }

      body.rtl & {
        --background-position-x: 100%;
        background-image: url('../images/branch_rtl.png');
      }

      &:not(:last-child, .last) {
        padding-inline-start: 37px;
        border-inline-start: 1px solid var(--gray-200);

        body.ltr & {
          --background-position-x: -1px;
        }

        body.rtl & {
          --background-position-x: calc(100% + 1px);
        }
      }

      &.draginsertion {
        margin-inline-start: 38px;
      }
    }
  }

  .row {
    &.draghover {
      .chip {
        z-index: 2;
        border-radius: 15px;
        box-shadow: inset 0 0 0 2px var(--link-color);
      }
    }

    &.droptarget {
      border-radius: 5px;
      box-shadow: inset 0 0 0 2px var(--link-color);
    }
  }
}

.elementselect .elements:not(:empty),
.componentselect .components:not(:empty) {
  & + .flex {
    margin-block-start: var(--s);
  }
}

/* element select fields */
.elementselect {
  position: relative;
  min-height: 34px;

  .flex,
  .btn {
    clear: both;
  }

  .structure {
    .chip {
      margin-block-start: var(--xs);
    }

    & > li {
      padding-inline-start: 0;
    }

    ul {
      margin-inline-start: 14px;

      li {
        --background-position-y: 0;
      }
    }
  }
}

/* nested element cards */
.nested-element-cards {
  display: flex;
  flex-direction: column;
  gap: var(--s);

  & > .pane {
    margin: 0;
  }
}

/* editable tables */
table.editable {
  border-radius: var(--large-border-radius);
  border: 1px solid var(--gray-200);

  th,
  td.heading,
  td.action {
    color: var(--medium-text-color);
    font-weight: normal;
    background-color: var(--gray-050);

    button:not(.lightswitch),
    a {
      display: inline-flex;
      width: var(--touch-target-size);
      height: var(--touch-target-size);
      align-items: center;
      justify-content: center;
    }

    .flex {
      gap: 0;
    }
  }

  td.action {
    padding-inline: var(--xs) !important;

    &:has(+ td.action) {
      padding-inline-end: 0 !important;
    }

    & + td.action {
      padding-inline-start: 0 !important;
    }
  }

  thead,
  tbody {
    tr {
      th {
        padding-block: 6px;
        padding-inline: 10px;
      }
    }
  }

  thead {
    tr {
      th {
        border-block-end: 1px solid var(--hairline-color);

        &.has-info {
          padding-inline-end: calc(15px + 1em);
        }

        span.info {
          position: absolute;
          margin-inline-start: 5px;
        }
      }
    }
  }

  tbody {
    tr {
      &:not(:first-child) {
        th,
        td {
          border-block-start: 1px solid var(--hairline-color);
        }
      }

      &:last-child {
        td:first-child {
          border-end-start-radius: var(--large-border-radius);

          textarea,
          input.text {
            border-end-start-radius: calc(var(--large-border-radius) - 1px);
          }
        }
      }

      td:not(:first-child),
      th ~ td:not(.hidden) ~ td {
        border-inline-start: 1px solid var(--hairline-color);
      }

      th {
        // Set a dark border-left for the first <td> that follows a <th>, if there is one.
        // This is a ridiculous CSS hack since there's no operator/pseudo-class that mimics jQuery's next(selector) function.
        // If there was it could have been as simple as: th ??? td:not(.hidden) { dark left border }
        // kudos to Mark Huot for coming up with it!
        & ~ td:not(:first-child) {
          border-inline-start: 1px solid #dbdddf;
        }
      }

      td {
        vertical-align: top;
        text-align: center;
        background-color: var(--white);
        padding-block: 4px;
        padding-inline: 10px;

        &.focus {
          position: relative;
          box-shadow: inset 0 0 0 1px var(--hairline-color);
        }

        &.textual {
          padding: 0;

          .editable-table-preview {
            text-align: start;
            white-space: pre-wrap;

            & + textarea {
              opacity: 0;
              height: 0;
              min-height: 0;
              padding-block: 0;
            }
          }

          textarea {
            resize: none;
          }

          pre {
            text-align: start;
            white-space: pre-wrap;
          }
        }

        &.lightswitch-cell {
          padding-block: 9px;

          .lightswitch {
            display: block;
            margin-block: 0;
            margin-inline: auto;
          }
        }

        &.checkbox-cell {
          padding-block: 10px;

          .checkbox-wrapper {
            display: block;
            margin-block: -2px 0;
            margin-inline: auto;
            width: 16px;
            height: 16px;
          }
        }

        &.error {
          box-shadow: inset 0 0 0 1px var(--error-color);
        }

        &.disabled {
          position: relative;
          opacity: 1;

          &::after {
            content: '';
            font-size: 0;
            position: absolute;
            inset-block-start: 0;
            inset-inline-start: 0;
            width: 100%;
            height: 100%;
            background-color: color.adjust(mixins.$grey050, $alpha: -0.25);
            user-select: none;
          }
        }

        &.action {
          padding-block: 4px;
          padding-inline: 7px;

          & + td.action {
            border-inline-start: none;
            padding-inline-start: 0;
          }
        }

        .flex > * {
          margin-block-end: 0;
        }
      }
    }

    textarea,
    textarea.text,
    input.text,
    pre,
    .editable-table-preview {
      display: block;
      width: 100%;
      border: none;
      box-shadow: none;
      border-radius: 0;
      padding-block: 7px;
      padding-inline: 10px;
      line-height: 20px;
      background-color: transparent;
      overflow: hidden;
      transition: none;
      box-sizing: border-box;
    }

    .color-container {
      display: block;
      position: relative;

      & > .color,
      .color-input {
        margin-block-end: 0;
      }

      & > .color {
        position: absolute;
        inset-block-start: 10px;
        inset-inline-start: 10px;
        z-index: 1;
      }

      .color-hex-indicator {
        inset-inline-start: 32px;
      }

      .color-input {
        padding-inline-start: calc(32px + 1em);
      }
    }

    .datewrapper,
    .timewrapper {
      display: block;
      width: calc(100% - 29px);

      .text + div[data-icon] {
        inset-block-start: 6px;
        inset-inline-start: 10px;
      }
    }
  }

  &:not(.static) {
    td.textual {
      cursor: text;
    }
  }

  & + .btn.add {
    display: block;
    width: 100%;
  }

  &:not(.hidden) + .btn.add {
    border-block-start-width: 0;
    border-radius: 0 0 var(--medium-border-radius) var(--medium-border-radius);
  }
}

.border-box,
.shadow-box {
  & + .buttons {
    margin-block-start: 7px;
  }
}

/* ----------------------------------------
/*  Nav
/* ---------------------------------------- */

ul.tree,
.tree ul {
  margin-inline-start: 20px;
}

.tree li .toggle {
  margin-inline: -15px 0;
  margin-block: 7px 0;
}

/* status indicators */
.status {
  flex: 0 0 auto;
  display: inline-block;
  position: relative;
  margin-inline-end: 10px;
  width: calc(12rem / 16);
  height: calc(12rem / 16);
  border-radius: 100%;
  box-sizing: border-box;
  background-clip: border-box;

  body.use-shapes & {
    &.all {
      rotate: 45deg;
      background-image: linear-gradient(30deg, #184cef, #e5422b);
    }

    &.pending {
      background-color: transparent;
      border-style: solid;
      border-width: 0 5px 10px;
      border-color: transparent transparent var(--pending-color) transparent;
      border-radius: 1px;
    }

    &.all,
    &.off,
    &.suspended,
    &.expired {
      border-radius: 2px;
      transform: scale(90%);
    }
  }
}

.status:not(.on, .live, .active, .enabled, .all) {
  &:not(.pending, .warning, .off, .suspended, .expired) {
    &:not(.light, .gray, .red, .orange, .amber, .yellow) {
      &:not(.lime, .green, .emerald, .teal, .cyan, .sky) {
        &:not(.blue, .indigo, .violet, .purple, .fuchsia) {
          &:not(.pink, .rose, .grey, .black, .disabled) {
            &:not(.inactive) {
              border-color: var(--ui-control-color);
            }
          }
        }
      }
    }
  }
}

.status.all {
  background-image: linear-gradient(60deg, #184cef, #e5422b);
  background-origin: border-box;
}

.status.on,
.status.live,
.status.active,
.status.enabled {
  background-color: var(--enabled-color);
}

.status.red,
.bg-red,
.status.off,
.status.suspended,
.status.expired {
  background-color: var(--disabled-color);
}

.status.warning {
  background-color: var(--warning-color);
}

.status.orange,
.bg-orange,
.status.pending {
  background-color: var(--pending-color);
}

.status.amber,
.bg-amber {
  background-color: var(--amber-500);
}

.status.yellow,
.bg-yellow {
  background-color: var(--yellow-500);
}

.status.lime,
.bg-lime {
  background-color: var(--lime-500);
}

.status.green,
.bg-green {
  background-color: var(--green-600);
}

.status.emerald,
.bg-emerald {
  background-color: var(--emerald-500);
}

.status.teal,
.bg-teal,
.status.turquoise,
.bg-turquoise {
  background-color: var(--enabled-color);
}

.status.cyan,
.bg-cyan {
  background-color: var(--cyan-500);
}

.status.sky,
.bg-sky {
  background-color: var(--sky-500);
}

.status.blue,
.bg-blue {
  background-color: var(--blue-600);
}

.status.indigo,
.bg-indigo {
  background-color: var(--indigo-500);
}

.status.violet,
.bg-violet {
  background-color: var(--violet-500);
}

.status.purple,
.bg-purple {
  background-color: var(--purple-500);
}

.status.fuchsia,
.bg-fuchsia {
  background-color: var(--fuchsia-500);
}

.status.pink,
.bg-pink {
  background-color: var(--pink-500);
}

.status.rose,
.bg-rose {
  background-color: var(--rose-500);
}

.status.light {
  background-color: var(--gray-100);
}

.status.white,
.bg-white {
  background-color: var(--white);
}

.status.black,
.bg-black {
  background-color: var(--gray-800);
}

.status.gray,
.bg-gray,
.status.grey,
.bg-grey {
  background-color: var(--gray-300);
}

.status.disabled,
.status.inactive {
  --outline-color: var(--gray-500);
  box-shadow: inset 0 0 0 2px var(--outline-color);

  .sel & {
    --outline-color: var(--gray-200);
  }
}

.status.white {
  opacity: 1;
}

$statusLabelColorShade: '900';
$statusLabelBgShade: '500';

/* status labels */
.status-label {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: var(--xs);
  height: calc(22rem / 16);
  padding-block: 0;
  padding-inline: var(--s);

  &::before {
    position: absolute;
    display: block;
    inset: 0;
    background-color: var(--status-label-bg-color);
    border-radius: calc(11rem / 16);
    content: '';
    opacity: 0.15;
    user-select: none;
    pointer-events: none;
  }

  .status,
  .cp-icon {
    margin-inline: -3px 0;
    margin-block: 0;
  }

  .status-label-text {
    position: relative;
    font-size: 11px;
    font-weight: 500;
    text-transform: uppercase;
    color: var(--status-label-text-color);
  }

  &.red {
    --status-label-text-color: var(--red-#{$statusLabelColorShade});
    --status-label-bg-color: var(--disabled-color);
  }

  &.amber {
    --status-label-text-color: var(--amber-#{$statusLabelColorShade});
    --status-label-bg-color: var(--amber-#{$statusLabelBgShade});
  }

  &.orange {
    --status-label-text-color: var(--orange-#{$statusLabelColorShade});
    --status-label-bg-color: var(--pending-color);
  }

  &.yellow {
    --status-label-text-color: var(--yellow-#{$statusLabelColorShade});
    --status-label-bg-color: var(--yellow-#{$statusLabelBgShade});
  }

  &.lime {
    --status-label-text-color: var(--lime-#{$statusLabelColorShade});
    --status-label-bg-color: var(--lime-#{$statusLabelBgShade});
  }

  &.green {
    --status-label-text-color: var(--green-#{$statusLabelColorShade});
    --status-label-bg-color: var(--green-#{$statusLabelBgShade});
  }

  &.emerald {
    --status-label-text-color: var(--emerald-#{$statusLabelColorShade});
    --status-label-bg-color: var(--emerald-#{$statusLabelBgShade});
  }

  &.teal {
    --status-label-text-color: var(--teal-#{$statusLabelColorShade});
    --status-label-bg-color: var(--enabled-color);
  }

  &.cyan {
    --status-label-text-color: var(--cyan-#{$statusLabelColorShade});
    --status-label-bg-color: var(--cyan-#{$statusLabelBgShade});
  }

  &.sky {
    --status-label-text-color: var(--sky-#{$statusLabelColorShade});
    --status-label-bg-color: var(--sky-#{$statusLabelBgShade});
  }

  &.blue {
    --status-label-text-color: var(--blue-#{$statusLabelColorShade});
    --status-label-bg-color: var(--blue-#{$statusLabelBgShade});
  }

  &.indigo {
    --status-label-text-color: var(--indigo-#{$statusLabelColorShade});
    --status-label-bg-color: var(--indigo-#{$statusLabelBgShade});
  }

  &.violet {
    --status-label-text-color: var(--violet-#{$statusLabelColorShade});
    --status-label-bg-color: var(--violet-#{$statusLabelBgShade});
  }

  &.purple {
    --status-label-text-color: var(--purple-#{$statusLabelColorShade});
    --status-label-bg-color: var(--purple-#{$statusLabelBgShade});
  }

  &.fuchsia {
    --status-label-text-color: var(--fuchsia-#{$statusLabelColorShade});
    --status-label-bg-color: var(--fuchsia-#{$statusLabelBgShade});
  }

  &.pink {
    --status-label-text-color: var(--pink-#{$statusLabelColorShade});
    --status-label-bg-color: var(--pink-#{$statusLabelBgShade});
  }

  &.rose {
    --status-label-text-color: var(--rose-#{$statusLabelColorShade});
    --status-label-bg-color: var(--rose-#{$statusLabelBgShade});
  }

  &.light {
    --status-label-text-color: var(--gray-#{$statusLabelColorShade});
    --status-label-bg-color: var(--gray-#{$statusLabelBgShade});
  }

  &.gray {
    --status-label-text-color: var(--gray-#{$statusLabelColorShade});
    --status-label-bg-color: var(--gray-#{$statusLabelBgShade});
  }
}

.card.sel,
li.sel .card,
table.data tbody tr:not(.disabled).sel,
table.data tbody tr:not(.disabled).active-drop-target {
  .status-label.gray {
    --status-label-bg-color: var(--white);

    &::before {
      opacity: 0.075;
    }
  }
}

/* colors */
.icon,
.cp-icon,
[data-icon],
[data-icon-after] {
  &.warning {
    --icon-color: var(--warning-color);
  }

  &.red {
    --icon-color: var(--disabled-color);
  }

  &.orange {
    --icon-color: var(--pending-color);
  }

  &.amber {
    --icon-color: var(--amber-500);
  }

  &.yellow {
    --icon-color: var(--yellow-500);
  }

  &.lime {
    --icon-color: var(--lime-500);
  }

  &.green {
    --icon-color: var(--green-500);
  }

  &.emerald {
    --icon-color: var(--emerald-500);
  }

  &.teal {
    --icon-color: var(--enabled-color);
  }

  &.cyan {
    --icon-color: var(--cyan-500);
  }

  &.sky {
    --icon-color: var(--sky-500);
  }

  &.blue {
    --icon-color: var(--blue-600);
  }

  &.indigo {
    --icon-color: var(--indigo-500);
  }

  &.violet {
    --icon-color: var(--violet-500);
  }

  &.purple {
    --icon-color: var(--purple-500);
  }

  &.fuchsia {
    --icon-color: var(--fuchsia-500);
  }

  &.pink {
    --icon-color: var(--pink-500);
  }

  &.rose {
    --icon-color: var(--rose-500);
  }

  &.white {
    --icon-color: var(--white);
  }

  &.black {
    --icon-color: var(--gray-800);
  }

  &.gray {
    --icon-color: var(--gray-300);
  }
}

.warning,
.red,
.orange,
.amber,
.yellow,
.lime,
.green,
.emerald,
.teal,
.cyan,
.sky,
.blue,
.indigo,
.violet,
.purple,
.fuchsia,
.pink,
.rose,
.white,
.black,
.gray {
  &[data-icon]::before,
  &[data-icon-after]::after {
    color: var(--icon-color) !important;
  }
}

/* ----------------------------------------
/*  Condition builders
/* ---------------------------------------- */

.condition-container {
  margin-block: 24px;
  margin-inline: 0;
}

.condition-rule,
.condition-footer {
  padding: 7px;
}

.condition-footer {
  border: 1px dashed var(--medium-hairline-color);
  border-radius: var(--large-border-radius);

  .condition:not(:empty) + & {
    border-block-start-width: 0;
    border-start-start-radius: 0;
    border-start-end-radius: 0;
  }

  .spinner:not(.loading) {
    display: none;
  }
}

.condition-rule {
  margin: 0;
  border: 1px solid var(--hairline-color);
  background-color: var(--gray-050);

  &:first-child {
    border-start-start-radius: var(--large-border-radius);
    border-start-end-radius: var(--large-border-radius);
  }

  & + .condition-rule {
    border-block-start-width: 0;
  }

  & > .rule-move,
  & > .rule-actions {
    margin-block-start: 5px;
  }

  & > .rule-body {
    .lightswitch {
      margin-block-start: 6px;
      display: block;
    }

    .selectize {
      min-width: 100px;
    }

    .text.fullwidth {
      min-width: 100px;
      max-width: 100%;
    }
  }
}

/* ----------------------------------------
/*  Progress bar
/* ---------------------------------------- */

.progressbar {
  border-radius: 6px;
  border: 2px solid var(--gray-700);
  padding: 2px;
  position: absolute;
  inset-inline-start: 20%;
  width: 60%;
  z-index: 1000;
}

.progressbar-inner {
  border-radius: 2px;
  height: 4px;
  background-color: var(--gray-700);
}

.progressbar:not(.pending) .progressbar-inner {
  width: 0;
  transition: width linear 100ms;
}

.progressbar.pending .progressbar-inner {
  @include striped-bg(17.6777px, var(--gray-700)); // sqrt(25^2 / 2);
  body.ltr & {
    animation-name: pendingprogress-ltr;
  }

  body.rtl & {
    animation-name: pendingprogress-rtl;
  }

  animation-timing-function: linear;
  animation-duration: 250ms;
  animation-iteration-count: infinite;
}

@keyframes pendingprogress-ltr {
  from {
    background-position: 0;
  }

  to {
    background-position: 25px;
  }
}

@keyframes pendingprogress-rtl {
  from {
    background-position: 0;
  }

  to {
    background-position: -25px;
  }
}

.elementselect .progress-shade {
  background-color: color.adjust(mixins.$white, $alpha: -0.2);
  width: 100%;
  height: 100%;
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  display: none;
}

.elementselect.uploading {
  position: relative;
}

.elementselect.uploading .progress-shade {
  display: block;
  z-index: 2;
}

// Plugin installers

.missing-component {
  padding-block: 7px !important;
  padding-inline: 10px !important;
  max-width: 400px;
  background-color: var(--gray-050) !important;

  .error {
    margin: 0;
  }

  .install-plugin {
    margin-block: 7px -7px;
    margin-inline: 0;
    border-block-start: 1px solid var(--hairline-color);
    position: relative;
    padding-inline: 40px 0;
    padding-block: 10px;

    .icon {
      width: 32px;
      height: 32px;
      position: absolute;
      inset-block-start: calc(50% - 16px);
      inset-inline-start: 0;

      img,
      svg {
        width: 100%;
        height: 100%;
      }
    }

    h3 {
      flex: 1;
      margin-block: 8px !important;
      margin-inline: 0 !important;
    }

    .btn {
      margin: 0;
    }
  }
}

/* ----------------------------------------
/*  Panes, Modals and HUDs
/* ---------------------------------------- */

.pane {
  --pane-padding: var(--xl);
  --border: 1px solid var(--hairline-color);
  @include mixins.pane;
  position: relative;
  margin-block: 14px;
  margin-inline: 0;
  padding: var(--pane-padding);
  border-radius: var(--large-border-radius);
  word-wrap: break-word;
  box-sizing: border-box;

  .pane &,
  .slideout &,
  #content & {
    background-color: var(--gray-050);
    border: 1px solid var(--hairline-color);
    box-shadow: none;
  }

  &.dashed {
    background-color: transparent !important;
    box-shadow: none;
    border: 1px dashed var(--medium-hairline-color) !important;
  }

  &.hairline {
    background-color: transparent !important;
    box-shadow: none;
    border: 1px solid #{color.adjust(mixins.$inputColor, $alpha: -0.75)} !important;
  }

  &.no-border {
    border: none !important;
  }

  &.padding-xs {
    --pane-padding: var(--xs);
  }

  &.padding-s {
    --pane-padding: var(--s);
  }

  &.padding-m {
    --pane-padding: var(--m);
  }

  &.padding-l {
    --pane-padding: var(--l);
  }

  &.loading {
    min-height: 200px;

    &::after {
      display: block;
      content: '';
      font-size: 0;
      position: absolute;
      inset-block-start: 0;
      inset-inline-start: -24px;
      width: calc(100% + #{24 + 24}px);
      height: 100%;
    }
  }
}

.pane-header {
  margin-block: calc(var(--xl) * -1) var(--xl);
  margin-inline: var(--neg-padding);
  padding-block: 5px 6px;
  padding-inline: var(--xl);
  min-height: 45px;
  box-sizing: border-box;
  box-shadow: inset 0 -1px 0 0 color.adjust(mixins.$grey300, $alpha: -0.75);
  border-radius: var(--large-border-radius) var(--large-border-radius) 0 0;
  background-color: var(--gray-050);

  .header-btn {
    margin-block: 2px;
    margin-inline: 0;
    @include mixins.header-btn;
  }

  .spinner {
    margin: 0 !important;
  }
}

.pane-tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-block: -5px -6px;
  margin-inline: calc(var(--padding) * -1);

  &:first-child {
    border-radius: var(--large-border-radius) var(--large-border-radius) 0 0;
    overflow: hidden;
  }

  [role='tablist'] {
    flex: 1;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &.scrollable {
      body.ltr & {
        mask-image: linear-gradient(
          to left,
          rgb(0 0 0 / 0%),
          rgb(0 0 0 / 100%) 24px
        );
      }

      body.rtl & {
        mask-image: linear-gradient(
          to right,
          rgb(0 0 0 / 0%),
          rgb(0 0 0 / 100%) 24px
        );
      }
    }

    [role='tab'] {
      --tab-label-color: var(--light-text-color);

      &.error {
        --highlight-color: var(--error-color) !important;
        --tab-label-color: var(--error-color) !important;
      }

      &,
      .tab-label {
        border-radius: 2px 2px 0 0;
      }

      .tab-label {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        padding-block: 0;
        padding-inline: var(--padding);
        white-space: nowrap;
        height: 45px;
      }

      &:hover {
        text-decoration: none;
      }

      @include mixins.focus-styles {
        box-shadow: none;

        .tab-label {
          box-shadow: var(--inner-focus-ring);
        }
      }

      &:not(.sel) {
        color: var(--custom-text-color, var(--tab-label-color));

        &:hover {
          backdrop-filter: brightness(95%);
        }
      }

      &.sel {
        --highlight-color: var(--gray-500);
        --tab-label-color: var(--text-color);
        position: relative;
        z-index: 1;
        cursor: default;
        box-shadow:
          inset 0 2px 0 var(--custom-text-color, var(--highlight-color)),
          0 0 0 1px mixins.$hairlineColor,
          0 2px 12px var(--custom-sel-tab-shadow-color, var(--gray-200));
        color: var(--tab-label-color);
        background-color: var(--white) !important;
      }

      span[data-icon] {
        margin-inline-start: 4px;
      }
    }
  }

  &#tabs:first-child {
    [role='tablist'] {
      [role='tab'] {
        &:first-child {
          &,
          .tab-label {
            border-start-start-radius: var(--large-border-radius);
          }
        }
      }
    }
  }

  .menubtn {
    margin-block: 0;
    margin-inline: var(--xl);

    &.error {
      span[data-icon] {
        margin-inline-end: 2px;
      }
    }
  }
}

/* detail sidebars */
.details {
  --spacing: var(--l);
  padding-block: 0 var(--spacing);
  padding-inline: var(--padding);

  .meta,
  .field,
  hr {
    margin-inline: var(--neg-padding);
  }

  .meta {
    margin-block-end: var(--spacing);

    &:not(.read-only) {
      background-color: var(--gray-050) !important;
    }

    &.read-only {
      color: var(--medium-text-color);

      & > .data {
        min-height: auto;

        & > .heading,
        & > .value {
          padding-block: 6px;
          padding-inline: 0;
        }

        &:first-child {
          & > .heading,
          & > .value {
            padding-block-start: 0;
          }
        }

        &:last-child {
          & > .heading,
          & > .value {
            padding-block-end: 0;
          }
        }
      }
    }

    &.warning {
      padding-block: var(--m);
      color: var(--text-color) !important;
      background-color: var(--yellow-050) !important;
      box-shadow:
        0 0 0 1px var(--yellow-300),
        0 2px 12px color.adjust(mixins.$grey200, $alpha: -0.5);

      p {
        margin-block-end: 5px;
      }

      .btn {
        background-color: var(--yellow-300);

        &:hover,
        &:focus {
          background-color: color.adjust(mixins.$yellow300, $lightness: -10%);
        }

        &:active,
        &[aria-expanded='true'] {
          background-color: color.adjust(mixins.$yellow300, $lightness: -15%);
        }
      }
    }

    & > .field,
    & > .data {
      margin-block: 0 !important;
      margin-inline: var(--neg-padding) !important;

      & > .heading > label,
      & > .heading {
        color: var(--gray-600);
      }
    }

    & > .field > .status-badge {
      inset-inline-start: 0;
    }

    .text::placeholder,
    .datewrapper .text + div[data-icon],
    .timewrapper .text + div[data-icon] {
      color: var(--gray-500);
    }

    .ui-datepicker {
      margin-inline: -8px 0;
      margin-block: 0;
    }
  }

  hr {
    margin-block: var(--spacing);
    margin-inline: 0;
    border-block-start-color: var(--hairline-color);
  }

  @include mixins.placeholder-styles(var(--gray-500));

  .text {
    background-color: transparent !important;
    resize: none;
  }

  & > .field .heading,
  & > fieldset > legend {
    margin-block: 0 var(--s);
    margin-inline: 0;
  }

  & > .field > .heading > label,
  & > fieldset > legend {
    font-weight: normal;
    color: var(--gray-600);
  }

  & > .field > .heading > label {
    margin: 0;
  }

  & > .field {
    margin-block: 0 var(--spacing);
    margin-inline: 0;

    & > .heading {
      margin-block-start: 0;
    }

    & > .input > .text.fullwidth {
      margin-block: 0;
      margin-inline: var(--neg-padding);
      padding-inline: var(--padding);
      border: none;
      background-color: var(--gray-050) !important;
      width: calc(100% + var(--padding) + var(--padding));
    }
  }

  & > fieldset {
    margin-block: var(--spacing) !important;
    margin-inline: 0 !important;
  }

  & > .text {
    border-radius: var(--large-border-radius);
    margin-block-end: var(--spacing);

    &:not(:focus) {
      border-color: color.adjust(mixins.$inputColor, $alpha: -0.6);
    }
  }
}

/* meta panes */
$min2ColWidth: 400px;

.meta {
  padding-block: 0;
  padding-inline: var(--padding);
  overflow: visible;

  &,
  & > .flex-fields {
    & > .field,
    & > .data {
      display: flex;
      min-height: 44px;
      box-sizing: border-box;
      flex-wrap: wrap; // for error lists
      justify-content: space-between;
      align-items: center;
      margin-block: 0 !important;
      margin-inline: var(--neg-padding) !important;
      padding-block: 0;
      padding-inline: var(--padding);
      transition:
        padding-inline-start linear 100ms,
        padding-inline-end linear 100ms;

      &.nested {
        padding-inline-start: 38px;
      }

      &.add {
        background-color: color.adjust(mixins.$grey050, $lightness: -2%);

        &::before {
          position: absolute;
          inset-inline-start: 0;
          width: 31px;
          text-align: end;
          @include mixins.icon;
          content: 'plus';
          color: var(--light-text-color);
        }

        .input {
          width: 100%;
        }
      }

      & > .heading {
        margin: 0;

        @media screen and (min-width: $min2ColWidth) {
          flex: 0 0 104px;
          margin-inline-end: var(--s);
          line-height: 18px;
        }
      }

      &.lightswitch-field > .heading {
        flex: 1;
      }

      & > .input {
        .flex {
          flex-wrap: nowrap;

          & > * {
            margin-block-end: 0;
          }
        }
      }
    }

    & > .field > .heading {
      padding-block-start: var(--s);

      @media screen and (min-width: $min2ColWidth) {
        padding-block: 12px;
        padding-inline: 0;
      }

      & > .copytextbtn {
        display: none;
      }
    }

    & > .data > .heading {
      padding-block: var(--s);
      padding-inline: 0;
    }

    & > .field > .heading > label,
    & > .data > .heading {
      color: var(--medium-text-color);
    }

    & > .field > .input,
    & > .data > .value {
      padding-block: 5px;
      padding-inline: 0;
      width: 100%;

      @media screen and (min-width: $min2ColWidth) {
        width: calc(100% - 112px);
      }
    }

    & > .data > .value {
      display: flex;
      align-items: center;

      & > [data-icon='draft'] {
        margin-block-start: -2px;
        margin-inline-end: 8px;
      }
    }

    & > .field.lightswitch-field > .input {
      flex: 0;
      width: auto;
    }

    & > .field {
      &.has-errors {
        border: 1px solid var(--error-color) !important;

        &:first-child {
          border-start-start-radius: var(--large-border-radius);
          border-start-end-radius: var(--large-border-radius);
        }

        &:last-child {
          border-end-start-radius: var(--large-border-radius);
          border-end-end-radius: var(--large-border-radius);
        }

        & + .field {
          border-block-start: none !important;
        }
      }

      & > .heading {
        & > label,
        & > legend {
          font-weight: normal;
        }
      }

      & > .input {
        &,
        & > .flex,
        & > .flex > .textwrapper,
        & > .datewrapper,
        & > .timewrapper,
        & > .datetimewrapper > .datewrapper,
        & > .datetimewrapper > .timewrapper {
          & > .text {
            display: block;
            margin-block: -5px;
            margin-inline: 0;
            padding-block: 12px;
            padding-inline: 0;
            border-radius: 0;
            background-color: transparent;
            border: none !important;
          }
        }

        & > .datewrapper,
        & > .timewrapper,
        & > .datetimewrapper > .datewrapper,
        & > .datetimewrapper > .timewrapper {
          background-color: transparent;

          .text + div[data-icon] {
            inset-inline-start: 0;
          }
        }

        & > .datetimewrapper {
          gap: 0;

          & > .datewrapper {
            width: 55%;
          }

          & > .timewrapper {
            width: 45%;
          }
        }

        & > .datewrapper,
        & > .timewrapper {
          display: block;
          width: 100%;
        }
      }

      & > ul.errors {
        margin: 0;
        padding-block: 0 6px;
        padding-inline: 0;
        width: 100%;
        list-style-type: none;
      }

      & > .clear-btn {
        margin-inline-end: var(--neg-padding);
      }
    }
  }

  & > .field:not(:first-child, .first-child),
  & > .flex-fields + .field:not(.first-child) {
    border-block-start: 1px solid var(--hairline-color);
  }

  & > .flex-fields {
    h2,
    blockquote.note {
      margin-block: 0 !important;
      margin-inline: -24px !important;
      padding-block: 14px;
      padding-inline: 24px;
      background-color: color.adjust(mixins.$grey050, $lightness: -2%);
    }

    blockquote.note {
      border-radius: 0;
      border: none;
    }

    hr {
      margin-block: 0;
      margin-inline: -24px;
    }
  }
}

.meta > .field > .input > .select {
  display: block;
  margin-block: calc(var(--s) * -1);
  margin-inline: 0;
  width: 100%;
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;

  &::after {
    inset-inline-end: 0;
  }

  & + .spinner {
    position: absolute;
    inset-block-start: calc(50% - 17px);
    inset-inline-end: -24px;
  }

  select {
    padding-inline: 0 12px;
    padding-block: var(--s);
    width: 100%;
    background-color: transparent;
  }
}

.meta > .field > .input > .custom-select {
  display: block;
  margin-block: calc(var(--s) * -1);
  margin-inline: 0;
  width: 100%;
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;

  .menubtn:not(.action-btn) {
    padding-inline-start: 0;
    justify-content: flex-start;
    width: 100%;
    background-color: transparent;

    &::after {
      position: absolute;
      inset-block-start: calc(50% - 5px);
      inset-inline-end: 0;
    }
  }

  .label {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.body {
  position: relative;
}

.slideout-container,
.slideout,
.modal,
.hud {
  z-index: 100;
  box-sizing: border-box;
}

.modal,
.hud {
  @include mixins.modal;
}

.slideout-shade {
  opacity: 0;
  transition: opacity linear 250ms;

  &.so-visible {
    opacity: 1;
  }
}

.slideout-container {
  position: fixed;
  inset-block-start: 0;
  inset-inline-start: 0;
  width: 100vw;
  height: 100vh;
  height: -webkit-fill-available; // h/t https://twitter.com/AllThingsSmitty/status/1254151507412496384
  pointer-events: none;

  &.so-lp {
    position: absolute;
    inset-block-start: var(--m);
    inset-inline-start: var(--m);
    width: calc(100% - var(--m) * 2);
    height: calc(100vh - var(--m) * 2);
  }

  body.has-debug-toolbar & {
    height: calc(100vh - 42px);
  }
}

.slideout {
  position: absolute;
  background-color: var(--white);
  box-shadow:
    0 0 0 1px color.adjust(mixins.$grey400, $alpha: -0.75),
    0 25px 100px color.adjust(mixins.$grey900, $alpha: -0.5) !important;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-block: 24px;
  padding-inline: var(--padding);
  pointer-events: all;
  container-type: inline-size;

  &.so-mobile,
  &.so-lp {
    width: 100% !important;
    height: 100% !important;
    inset-inline-start: 0;
    transition: inset-block-start linear 250ms;
    will-change: inset-block-start;
  }

  &.so-mobile {
    --padding: 14px;
    --neg-padding: -14px;
  }

  &.so-lp {
    border-radius: var(--large-border-radius);
  }

  &:not(.so-mobile, .so-lp) {
    border-start-start-radius: var(--large-border-radius);
    border-start-end-radius: 0;
    border-end-end-radius: 0;
    border-end-start-radius: var(--large-border-radius);
  }

  &:not(.so-mobile, .so-lp) {
    inset-block-start: 0;
    width: 55%;
    height: 100%;

    @media screen and (prefers-reduced-motion: no-preference) {
      transition: inset-inline-start linear 250ms;
      will-change: inset-inline-start;
    }
  }

  & > .pane-header {
    padding-inline: var(--padding);
    z-index: 2;
    border-radius: 0;

    & > .so-toolbar {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: var(--xs);
      min-height: calc(44px - 16px);

      & > .pane-tabs {
        width: 1px; // give other elements in the header plenty of room before the tabs take up whatever's left
        flex: 1;
        margin-inline-end: 0;
      }
    }
  }

  & > .so-body {
    flex: 1;
    margin-block: -24px;
    margin-inline: var(--neg-padding);
    overflow: hidden auto;
    position: relative;

    &:not(:last-child) {
      margin-block-end: 0;
    }

    & > h1:not(:last-child) {
      padding-block-end: var(--s);
      border-block-end: 1px solid var(--hairline-color);
    }

    &.so-full-details,
    & > .so-sidebar {
      background-color: var(--gray-100) !important;
    }

    &:not(.so-full-details) {
      padding-block: 24px;
      padding-inline: var(--padding);
    }

    & > .so-sidebar,
    &.so-full-details > .so-content > .details {
      box-sizing: border-box;
      padding-block: 0 var(--spacing);
      padding-inline: var(--padding);

      & > .preview-thumb-container {
        margin-block: 0;
        margin-inline: var(--neg-padding);
        height: auto;
        min-height: 54px; // make room for the Preview / Edit buttons

        & + .pane-header {
          border-radius: 0;
        }
      }

      .image-actions {
        &.is-mobile {
          margin-block: calc(var(--spacing) / 2) var(--spacing);
          margin-inline: 0;
        }
      }

      & > .meta.read-only:first-child {
        margin-block-start: var(--padding);
      }

      & > .meta.warning {
        box-shadow: none;
        border-block-end: 1px solid var(--yellow-300);
      }

      & > .field {
        & > .input > .text.fullwidth {
          border-radius: 0;
        }
      }

      .notes {
        padding-block: var(--m);
      }
    }

    & > .so-sidebar {
      position: absolute;
      inset-block-start: 0;
      @include mixins.pane;
      width: 350px;
      height: 100%;
      max-width: 100%;
      overflow: hidden auto;
      z-index: 1;

      body.ltr & {
        transition: inset-inline-end linear 250ms;
      }

      body.rtl & {
        transition: inset-inline-start linear 250ms;
      }
    }
  }

  & > .so-footer {
    position: relative;
    display: flex;
    gap: var(--s);
    justify-content: space-between;
    flex-wrap: wrap;
    margin-block: 0 -24px;
    margin-inline: var(--neg-padding);
    padding-block: 5px;
    padding-inline: var(--padding);
    @include mixins.pane;
    background-color: var(--gray-050);
    z-index: 3;

    & > .so-extra {
      flex: 0 0 100%;
      margin-block: 0;
      margin-inline: var(--neg-padding);
      padding-block: 0 8px;
      padding-inline: var(--padding);
      border-block-end: 1px solid var(--hairline-color);
    }
  }
}

@container (width > 700px) {
  .slideout {
    &.showing-sidebar {
      .so-body {
        display: flex;
        flex-direction: row;
        padding: 0;
        overflow: hidden;

        & > .so-content {
          position: relative;
          z-index: 2;
          padding: 24px;
          width: calc(100% - 350px);
          height: 100%;
          box-sizing: border-box;
          border-inline-end: 1px solid var(--gray-200);
          overflow: hidden auto;
        }

        & > .so-sidebar {
          position: relative;
          display: block !important;
          inset-block-start: auto;
          inset-inline: auto !important;
          height: 100%;
          box-shadow: none;
        }
      }
    }

    & > .so-footer {
      & > .so-extra {
        margin: 0;
        padding: 0;
        border: none;
        flex: auto 0 1;
      }
    }
  }
}

.header,
.hud-header,
.footer,
.hud-footer {
  position: relative;
  z-index: 1;
  box-sizing: border-box;
}

.header,
.hud-header,
.footer,
.hud-footer {
  background-color: var(--gray-100);
}

.header,
.hud-header {
  border-radius: var(--large-border-radius) var(--large-border-radius) 0 0;
  padding: 24px;
  box-shadow: inset 0 -1px 0 var(--hairline-color);

  h1 {
    margin: 0;
  }
}

.footer,
.hud-footer {
  border-radius: 0 0 var(--large-border-radius) var(--large-border-radius);
  padding-block: 5px;
  padding-inline: var(--pane-padding, var(--xl));
  box-shadow: inset 0 1px 0 var(--hairline-color);

  &.flex {
    & > * {
      margin-block-end: 0;
    }
  }
}

.modal .body,
.hud .main {
  padding: 24px;
  overflow: hidden;
  box-sizing: border-box;
}

.pane,
.modal .body {
  .header {
    margin-block: calc(var(--pane-padding, 24px) * -1) var(--pane-padding, 24px);
    margin-inline: calc(var(--pane-padding, 24px) * -1);
  }

  .footer {
    margin-block: var(--pane-padding, 24px) calc(var(--pane-padding, 24px) * -1);
    margin-inline: calc(var(--pane-padding, 24px) * -1);
  }
}

.slideout-shade,
.modal-shade,
.hud-shade {
  z-index: 100;
  position: fixed;
  inset-block-start: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 100%;
  display: none;
}

.slideout-shade,
.modal-shade {
  &:not(.dark) {
    background-color: color.adjust(mixins.$grey400, $alpha: -0.65) !important;
  }

  &.dark {
    background-color: color.adjust(mixins.$grey900, $alpha: -0.5) !important;
  }

  &.blurred {
    backdrop-filter: blur(10px);
  }
}

.modal {
  position: fixed;
  overflow: hidden;

  &:not(.fitted, .fullscreen) {
    width: 66%;
    height: 66%;
    min-width: 600px;
    min-height: 400px;
  }

  &.fitted {
    width: auto;
    height: auto;
    min-width: 0;
    min-height: 0;
  }

  &.fullscreen {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  &.alert .body {
    padding-inline-start: 76px;

    &::before {
      @include mixins.icon;
      margin-inline: -58px 0;
      margin-block: -6px 0;
      float: inline-start;
      content: 'alert';
      font-size: 40px;
      color: var(--light-text-color);
    }
  }

  &.secure .body {
    padding-inline-start: 76px;

    &::before {
      @include mixins.icon;
      margin-inline: -56px 0;
      margin-block: -14px 0;
      float: inline-start;
      content: 'secure';
      font-size: 58px;
      color: var(--light-text-color);
    }
  }

  .resizehandle {
    position: absolute;
    z-index: 1;
    inset-block-end: 0;
    inset-inline-end: 0;
    width: 24px;
    height: 24px;
    cursor: nwse-resize;
    padding: var(--xs);

    path {
      fill: var(--ui-control-color);
    }

    body.rtl & {
      .ltr {
        display: none;
      }
    }

    body.ltr & {
      .rtl {
        display: none;
      }
    }
  }
}

.hud {
  position: absolute;
  display: none;
  inset-block-start: 0;

  &.tooltip-hud {
    display: block;
  }

  &.has-footer .tip-bottom {
    background-image: url('../images/hudtip_bottom_gray.png');
  }

  .tip {
    position: absolute;
    z-index: 101;
    background: no-repeat 0 0;
  }

  .tip-left {
    inset-inline-start: -15px;
    width: 15px;
    height: 30px;
    background-image: url('../images/hudtip_left.png');
  }

  .tip-top {
    inset-block-start: -15px;
    width: 30px;
    height: 15px;
    background-image: url('../images/hudtip_top.png');
  }

  .tip-right {
    inset-inline-end: -15px;
    width: 15px;
    height: 30px;
    background-image: url('../images/hudtip_right.png');
  }

  .tip-bottom {
    inset-block-end: -15px;
    width: 30px;
    height: 15px;
    background-image: url('../images/hudtip_bottom.png');
  }
}

.hud .hud-header,
.hud .hud-footer {
  padding-block: var(--s);
  padding-inline: var(--xl);
}

.hud .body {
  overflow: hidden;

  ::-webkit-scrollbar {
    appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 11px;
  }

  ::-webkit-scrollbar:horizontal {
    height: 11px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid transparent;
    background-color: color.adjust(mixins.$black, $alpha: -0.5);
  }

  ::-webkit-scrollbar-track {
    background-color: var(--gray-050);
  }
}

/* inline asset previews */

.preview-thumb-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 190px;
  background-color: var(--gray-900);
  margin-block: 0 var(--spacing);
  margin-inline: var(--neg-padding);

  &.checkered img {
    background-color: var(--white);
    @include mixins.checkered-bg(17px);
  }

  &.editable {
    cursor: pointer;
  }

  &.loading {
    &::after {
      content: '';
      font-size: 0;
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      inset-inline-start: 0;
      inset-block-start: 0;
      background-color: color.adjust(mixins.$grey900, $alpha: -0.2);
    }

    .spinner {
      color: var(--white);
      z-index: 1;
    }
  }

  #details & {
    border-radius: var(--large-border-radius);
    overflow: hidden;
  }

  .preview-thumb {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;

    img {
      display: block;
      max-width: 100%;
      max-height: 190px;
    }
  }
}

.image-actions {
  &.is-mobile {
    margin-block: calc((var(--spacing) / 2) * -1) var(--spacing);
    margin-inline: var(--neg-padding);
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.button-fade {
  .buttons {
    opacity: 0;
    position: absolute;
    inset-block-start: 10px;
    inset-inline-end: 10px;
    margin: 0;
    transition: opacity linear 100ms;

    .btn {
      --ui-control-color: var(--white);
      --ui-control-hover-color: var(--white);
      --ui-control-active-color: var(--white);
      --interaction-background-color: var(--gray-700);
      background-color: var(--gray-600);
      color: var(--white);
      @include mixins.light-on-dark-text;
      @include mixins.two-color-focus-ring($light-button: false);

      &:hover {
        background-color: var(--interaction-background-color) !important;
      }

      &:not(.disabled, .loading, .dashed) {
        &:focus,
        &.focus,
        &:hover {
          background-color: var(--interaction-background-color);
        }
      }
    }
  }

  &:hover,
  &:focus-within {
    .buttons {
      opacity: 1;
    }
  }
}

/* element selector modals */
.elementselectormodal {
  --content-padding: 24px;
  padding-block-end: 44px;
  user-select: none;

  & > .header {
    padding-block: 14px;
    text-align: center;

    & + .body {
      height: calc(100% - 48px) !important;
    }
  }

  .body {
    position: relative;
    height: 100%;

    .spinner.big {
      position: absolute;
      inset-block-start: 50%;
      inset-inline-start: 50%;
      margin-block: -24px 0;
      margin-inline: -24px 0;
    }

    .content {
      height: calc(100% + 48px);

      .sidebar {
        position: absolute;
        inset-block-start: 0;
        margin-inline-start: -249px;
        height: 100%;
        overflow: auto;
        padding-block: var(--content-padding);
        padding-inline: 0;
      }

      .main {
        margin: -24px;
        padding: var(--content-padding);
        height: 100%;
        box-sizing: border-box;
        overflow: auto;
        position: relative;

        .elements {
          &.busy {
            min-height: calc(100% - 48px);

            .update-spinner {
              z-index: 101;
            }
          }

          .header {
            margin-block: 0 var(--m);
            margin-inline: 0;
          }

          .tableview table {
            tr {
              th,
              td {
                cursor: default;
              }

              // prevent double focus ring (the row already gets it)
              .checkbox::before {
                box-shadow: none !important;
              }
            }
          }

          .structure .row {
            margin-block-start: 1px;
          }
        }
      }
    }
  }

  .footer {
    position: absolute;
    inset-block-end: 0;
    inset-inline-start: 0;
    width: 100%;
    margin: 0;
    box-sizing: border-box;

    .spinner {
      float: inline-end;
      margin-inline-end: -24px;
    }
  }
}

/* element selector modals & customize sources modal */
.elementselectormodal,
.customize-sources-modal {
  .main-header {
    display: flex;
    align-items: center;
    margin-block-end: var(--s);
  }

  .main-heading {
    margin-block-end: 0;
  }

  .nav-toggle {
    margin-inline-start: 4px;
    @include mixins.touch-target;
    color: var(--ui-control-color);

    &:hover {
      color: var(--gray-500);
    }
  }

  .sidebar-header {
    display: flex;
    justify-content: flex-end;
    padding-block: 0;
    padding-inline: var(--s);
    margin-block-end: var(--s);
  }

  .nav-close {
    @include mixins.touch-target;
  }
}

/* element editing HUD */
.element-hud-form {
  .buttons {
    position: relative;

    .spinner {
      position: absolute;
      inset-block-start: 0;
      inset-inline-end: -24px;
    }
  }
}

/* recent activity container */
.activity-container {
  margin-inline: var(--s);

  ul {
    display: flex;
    flex-flow: row wrap;
    gap: var(--xs);

    li {
      .activity-btn {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: block;

        .elementthumb {
          position: relative;
          width: 100%;
          height: 100%;
          border-radius: 50px;
          background: var(--gray-050);
          overflow: hidden;
          border: 2px solid var(--white);
          box-shadow: 0 1px 2px 1px color.adjust(mixins.$grey900, $alpha: -0.9);

          img,
          svg {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
      }

      &:not(:last-child) {
        margin-inline-end: -10px;
      }
    }
  }
}

/* element index view menu */
.element-index-view-menu {
  width: 400px;
  max-width: calc(100% - 20px);
  padding: 0 !important;

  .meta {
    padding-block: var(--s);
  }

  .table-columns-field {
    align-items: flex-start;

    .input {
      padding-block: 13px;
      padding-inline: 0;
    }
  }

  .menu-footer {
    margin: 0 !important;
    padding-block: var(--s) !important;
    padding-inline: var(--xl) !important;
    background-color: var(--gray-050);
  }
}

.element-index-view-menu-table-column {
  display: flex;
  align-items: flex-start;
  align-content: stretch;
  margin-block-end: 4px;

  .icon.move {
    margin-inline-end: 10px;
    position: relative;
    z-index: 1;
  }
}

/* sort fields */
.sort-field {
  .input {
    .flex {
      .btngroup {
        .btn {
          width: 42px;
        }
      }

      .flex-grow {
        max-width: calc(100% - 85px - var(--s));
      }

      @media screen and (width <= 350px) {
        flex-direction: column;

        & > * {
          width: 100%;
        }

        .flex-grow {
          max-width: none;
        }
      }
    }
  }
}

/* element filter HUD */
.element-filter-hud {
  &.loading .body {
    padding: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .body,
  .main {
    overflow: visible;
  }
}

/* Address Cards */
.address-cards {
  display: grid;
  gap: var(--m) var(--m);
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-auto-rows: minmax(0, 1fr);

  .so-content & {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }
}

.address-cards__add-btn {
  padding: 1rem;
  min-height: 8rem;
  height: auto;
  width: 100%;
}

.address-card.error {
  border: 1px solid #cf1124;
}

.address-card.error:hover {
  border-color: #cf1124;
}

@media (width >= 1024px) {
  .address-cards {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (width >= 1280px) {
  .address-cards {
    grid-template-columns: repeat(3, minmax(0, 1fr));

    .so-content & {
      grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
    }
  }
}

@media (width >= 1536px) {
  .address-cards {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

.address-card {
  border: 1px solid #eee;
  border-radius: 0.375rem;
  padding: 1rem;
  min-height: 4rem;
}

.address-card:hover {
  border-color: #ddd;
  background-color: #fafafa;
  cursor: pointer;
}

.address-card .address-card-header {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.address-card .address-card-header-actions {
  display: flex;
  justify-content: flex-end;
}

.address-card .address-card-label {
  background: #e6f1fe;
  color: #2563eb;
  padding-block: 0.125rem;
  padding-inline: 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 500;
  margin-block-end: 0.75rem;
  margin-inline-end: 0.5rem;
}

/* logout warning/login/elevated session modals */
.logoutwarningmodalshade,
.login-modal-shade,
#logoutwarningmodal,
.login-modal,
#elevatedsessionmodal,
.prompt {
  z-index: 1001;
}

#logoutwarningmodal,
#elevatedsessionmodal,
.prompt {
  width: 500px;
}

.prompt {
  height: auto;
  min-height: auto;
}

.cpmodal {
  & > .cpmodal-body {
    padding: var(--padding);
  }

  & > .cpmodal-footer {
    position: relative;
    display: flex;
    gap: 8px;
    justify-content: space-between;
    flex-wrap: wrap;
    @include mixins.pane;
    padding-block: var(--s);
    padding-inline: var(--padding);
    background-color: var(--gray-050);
  }
}

/* delete user modal */
.deleteusermodal {
  .content-summary {
    margin-block: -24px 24px;
    margin-inline: -24px;
    padding: 24px;
    background-color: var(--gray-050);
  }

  .options {
    label {
      display: inline-block;
      line-height: 30px;
    }
  }

  .elementselect {
    margin-inline-start: 10px;
    display: inline-block;
    vertical-align: middle;
  }

  .buttons {
    .spinner {
      margin-inline-end: -20px;
    }
  }

  div.elements {
    list-style: none;
  }
}

.dropdownsettingsmodal {
  width: auto;
  height: auto;
  min-width: 0;
  min-height: 0;
  max-width: 400px;

  .body {
    max-height: 100%;
    overflow-y: auto;
  }
}

.previewmodal {
  &.zilch {
    padding-block: 100px;
    padding-inline: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/* ----------------------------------------
/*  Alternative Text
/* ---------------------------------------- */
.video-transcript {
  background-color: var(--gray-050);
  overflow-y: scroll;
  font-size: 1rem;
  position: relative;

  @include mixins.focus-styles {
    // Unset default
    box-shadow: none;
    color: var(--white);
    background-color: var(--gray-800);
  }
}

.video-transcript__inner {
  margin-inline: auto;
  max-width: 550px;
  padding: var(--xl);
}

/* ----------------------------------------
/*  Menus
/* ---------------------------------------- */

.menu,
.ui-datepicker,
.ui-timepicker-list {
  @include mixins.menu-styles;
}

.ui-datepicker,
.ui-timepicker-list {
  padding: 0;
}

.menu {
  display: none;
  position: absolute;

  &.padded {
    padding-block: var(--s);
    padding-inline: calc(var(--m) + var(--s));

    hr {
      margin-block: var(--s);
      margin-inline: calc((var(--m) + var(--s)) * -1);
    }

    ul {
      li {
        margin-block: 0;
        margin-inline: calc(var(--m) * -1);
        padding-block: 0;
        padding-inline: var(--m);

        a {
          border-radius: var(--large-border-radius);
        }
      }
    }

    .extralight {
      margin-block-start: 2px;
    }
  }

  h6,
  .h6 {
    &:first-child {
      margin-block-start: 14px !important;
    }
  }

  ul {
    &.padded {
      li {
        a,
        .menu-item,
        .menu-option {
          padding-inline-start: calc(var(--m) + 18rem / 16);

          &.sel {
            &:not([data-icon])::before {
              float: inline-start;
              margin-inline: calc(-18rem / 16 - 4px) 0;
              margin-block: 4px 0;
              font-size: 14px;
              width: 14px;
              content: 'check';
              color: currentcolor;
              margin-block-start: 3px !important;
            }
          }
        }
      }
    }

    li {
      a,
      .menu-item,
      .menu-option {
        margin-block: 0;
        margin-inline: -14px;
        padding-block: 10px;
        padding-inline: 14px;
        white-space: nowrap;
        font-size: 14px;
        appearance: none;

        &:not(:last-child) {
          margin-inline-end: 0;
        }

        &:not(.flex, .hidden) {
          display: block;
          width: calc(100% + 28px);
          text-align: start;
        }

        &.flex {
          [data-icon] {
            margin-block-start: -2px;
          }
        }

        &.sel {
          cursor: default;
        }

        .shortcut {
          float: inline-end;
          margin-inline-start: 14px;
          padding-block: 0;
          padding-inline: 4px;
          border-radius: var(--medium-border-radius);
          box-shadow:
            0 0 0 1px color.adjust(mixins.$grey600, $alpha: -0.75),
            0 1px 3px -1px color.adjust(mixins.$grey600, $alpha: -0.5);
        }
      }
    }
  }

  & > .flex {
    margin-block: 10px;
    position: relative;

    &.padded {
      margin-inline-start: -14px;
      padding-inline-start: 24px;

      &.sel {
        &::before {
          position: absolute;
          inset-block-start: 36px;
          inset-inline-start: 7px;
          content: 'check';
          font-size: 14px;
          color: var(--light-text-color);
        }
      }
    }
  }

  hr {
    margin-block: 5px;
    margin-inline: -14px;
  }

  .go::after {
    color: inherit;
  }

  &:not(.menu--disclosure) ul li a,
  ul li .menu-item,
  ul li .menu-option {
    color: mixins.$menuOptionColor;
    text-decoration: none;
    cursor: default;
  }
}

.menu li {
  & > a,
  & > button,
  & > .menu-item {
    &[data-icon]::before,
    [data-icon]::before,
    span.icon:not([data-icon]) {
      display: inline-block;
      width: calc(14rem / 16);
      height: calc(14rem / 16);
      margin-inline: -1px 9px;
    }

    &[data-icon]::before,
    [data-icon]::before {
      position: relative;
      inset-block-start: -2px;
      text-align: center;
      font-size: 14px;
      color: currentcolor;
    }

    span.icon:not([data-icon]) svg {
      display: block;
      position: relative;
      inset-block-start: 1px;
      width: 100%;
      height: 100%;
      @include mixins.svg-mask(var(--icon-color, var(--ui-control-color)));
    }

    &.error {
      &[data-icon]::before,
      [data-icon]::before {
        color: var(--error-color);
      }

      span.icon:not([data-icon]) svg {
        @include mixins.svg-mask(var(--error-color));
      }
    }
  }
}

/* prettier-ignore */
.menu:not(.menu--disclosure) ul li a:not(.disabled):hover,
.menu:not(.menu--disclosure) ul li .menu-item:not(.sel, .disabled):hover,
.menu:not(.menu--disclosure) ul li .menu-option:not(.sel, .disabled):hover,
.menu:not(.menu--disclosure, :hover) ul li a:not(.disabled).hover,
.menu:not(.menu--disclosure, :hover) ul li .menu-item:not(.sel, .disabled).hover,
.menu:not(.menu--disclosure, :hover) ul li .menu-option:not(.sel, .disabled).hover {
  @include mixins.menu-item-active-styles;

  // Pretty gnarly, but needs to override the default hover styles with this selector
  .status:not(.on, .live, .active, .enabled, .all) {
    &:not(.pending, .warning, .off, .suspended, .expired) {
      &:not(.light, .gray, .red, .orange, .amber, .yellow) {
        &:not(.lime, .green, .emerald, .teal, .cyan, .sky) {
          &:not(.blue, .indigo, .violet, .purple, .fuchsia) {
            &:not(.pink, .rose, .grey, .black, .disabled) {
              &:not(.inactive) {
                border-color: currentcolor;
              }
            }
          }
        }
      }
    }
  }
}

.menu {
  hr.padded,
  .h6.padded,
  h6.padded {
    margin-inline-start: 20px;
  }
}

.menu--disclosure ul li {
  & > a:not(.crumb-link),
  & > .menu-item,
  & > .menu-option {
    --focus-ring: var(--inner-focus-ring);

    &:hover {
      @include mixins.disclosure-link-hover-styles;
    }
  }
}

/* tag select fields */
.tagselect {
  .elements {
    li {
      display: inline;
    }
  }

  .chip.small {
    clear: none;
    margin-inline-end: 7px;
  }

  .add {
    position: relative;
    z-index: 1;
    margin-inline: 0 7px;
    margin-block: 7px 0;
    display: inline-block;
    width: 12em;

    .text {
      padding-inline-end: 30px;
    }

    .spinner {
      position: absolute;
      inset-block-start: 0;
      inset-inline-end: 5px;
    }
  }
}

.tagmenu {
  ul {
    li {
      a {
        padding-inline-start: 26px;

        &::before {
          float: inline-start;
          margin-inline: -18px 0;
          margin-block: 3px 0;
        }
      }
    }
  }
}

/* selectize */
.selectize-control.single .selectize-input:not(.no-arrow)::after {
  display: none;
}

/* ----------------------------------------
/*  Fields
/* ---------------------------------------- */

.shadow-box {
  border-radius: var(--large-border-radius);
  border: 1px solid var(--gray-200);
  @include mixins.shadow;
}

table.shadow-box,
table.editable {
  border-collapse: separate;
  border-spacing: 0;

  thead,
  tbody:first-child,
  caption + tbody {
    tr:first-child {
      th:first-child,
      td:first-child {
        &,
        &.disabled::after {
          border-start-start-radius: var(--medium-border-radius);
        }
      }

      th:last-child,
      td:last-child {
        &,
        &.disabled::after {
          border-start-end-radius: var(--medium-border-radius);
        }
      }
    }
  }

  thead:last-child,
  tbody:last-child {
    tr:last-child {
      th:first-child,
      td:first-child {
        &,
        &.disabled::after {
          border-end-start-radius: calc(var(--large-border-radius) - 1px);
        }
      }

      th:last-child,
      td:last-child {
        &,
        &.disabled::after {
          border-end-end-radius: calc(var(--large-border-radius) - 1px);
        }
      }
    }
  }
}

.text,
.passwordwrapper,
.border-box,
.selectize.multiselect .selectize-input,
.multiselect > select {
  @include mixins.input-styles;
}

.text,
.passwordwrapper,
.border-box,
.selectize.multiselect .selectize-input {
  &.focus {
    @include mixins.input-focused-styles;
  }

  @include mixins.focus-styles {
    @include mixins.input-focused-styles;
  }
}

input.text,
textarea.text,
.text > input,
.text > textarea,
table.editable textarea,
.selectize.multiselect .selectize-input {
  font-size: 14px;
  line-height: 20px;
  color: var(--text-color);
  min-height: 3px;
  box-sizing: border-box;
  appearance: none;
}

.selectize.multiselect .selectize-input {
  line-height: 18px;
}

textarea.text.fullwidth {
  display: block;
}

.multitext .multitextrow {
  &::after {
    @include mixins.clearafter;
  }

  &:first-child .text {
    &:first-child {
      border-start-start-radius: var(--medium-border-radius);
    }

    &:last-child {
      border-start-end-radius: var(--medium-border-radius);
    }
  }

  &:last-child .text {
    &:first-child {
      border-end-start-radius: var(--medium-border-radius);
    }

    &:last-child {
      border-end-end-radius: var(--medium-border-radius);
    }
  }

  &:not(:first-child) .text {
    margin-block-start: -1px;
  }

  .text {
    border-radius: 0;
    float: inline-start;
    box-sizing: border-box;

    &:not(:first-child) {
      margin-inline-start: -1px;
    }

    &:first-child {
      &:nth-last-child(1) {
        width: 100%;
      }

      &:nth-last-child(2) {
        width: 50%;
      }

      &:nth-last-child(2) ~ .text {
        width: calc(50% + 1px);
      }
    }

    &.error {
      position: relative;
      z-index: 1;
    }
  }

  .text:focus,
  .selectize.multiselect .selectize-input.focus {
    position: relative;
    z-index: 2;
  }
}

.link-input {
  container-type: inline-size;

  & > .flex {
    & > div {
      &.text-link {
        border: 1px solid color.adjust(mixins.$inputColor, $alpha: -0.75);
        border-radius: var(--large-border-radius);
        cursor: text;

        &:has(.text:focus) {
          box-shadow: var(--focus-ring);
        }

        & > .text,
        & > .chip {
          margin-block: -1px;
          background-color: transparent;
        }

        & > .text {
          border: none;
          margin-inline: -1px;

          &:focus {
            position: relative;
          }

          &:focus {
            box-shadow: none !important;
          }
        }

        .chip-content {
          a {
            word-break: break-word;
          }
        }
      }
    }
  }

  @container (max-width: 399px) {
    & {
      flex-direction: column;
      align-items: start;

      & > .select,
      & > .select select,
      & > div > .text {
        width: 100%;
      }
    }
  }
}

.chars-left {
  position: relative;
  float: inline-end;
  margin-inline: 7px;
  margin-block: calc(-27rem / 16) 0;
  color: var(--light-text-color);

  .input.ltr > & {
    float: inline-end;
  }

  .input.rtl > & {
    float: inline-start;
  }

  &.negative-chars-left {
    color: var(--error-color);
  }
}

.field,
fieldset {
  position: relative;
  margin-block: 24px;
  margin-inline: 0;

  .flex > & {
    margin-block: 0;
  }
}

.field {
  min-inline-size: initial;

  & > .status-badge {
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 0;
    width: 2px;
    height: 100%;
    border-radius: 1px;
    content: '';
    cursor: help;

    &.modified {
      background-color: var(--blue-600);
      box-shadow: 0 0 5px hsl(221deg 83% 53% / 15%);
    }

    &.outdated {
      background-color: var(--pending-color);
      box-shadow: 0 0 5px hsl(27deg 96% 61% / 15%);
    }
  }

  & > .heading {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    margin-block: -5px 5px;

    & > label,
    & > legend {
      font-weight: bold;

      code {
        font-size: 1em !important;
      }

      .info {
        margin-inline-start: 5px;
      }
    }

    .t9n-indicator {
      position: relative;
      inset-block-start: calc(-1rem / 16);
      margin-inline-start: 7px;
      color: var(--light-text-color);
    }

    & + .instructions {
      margin-block-start: -3px;
    }

    // BC
    & > .instructions {
      width: 100%;
    }
  }

  & > .instructions {
    margin-block-end: 5px;
  }

  & > .input {
    position: relative;

    & + .instructions {
      margin-block: 5px 0;
      margin-inline: 0;
    }

    input:disabled,
    textarea:disabled {
      cursor: not-allowed;
    }
  }

  & > .notice,
  & > .warning {
    margin-block: 5px 0;
    margin-inline: 0;
  }
}

.field > .instructions,
  // BC
.field > .heading > .instructions,
.checkboxfield .instructions {
  color: var(--custom-text-color, var(--medium-text-color));

  img,
  video,
  embed,
  iframe {
    max-width: 100% !important;
  }

  ul,
  ol {
    margin-block: 1em;
    margin-inline: 0;
    padding-inline-start: 2em;
  }

  ul li {
    list-style-type: disc;
  }

  li + li {
    margin-block-start: 0.25em;
  }
}

.expand-status-btn {
  margin-inline-start: 5px;
  width: 30px;
  height: 17px;
  padding: 0;
  line-height: 16px;
  border-radius: var(--small-border-radius);
  color: var(--text-color);

  &::before {
    margin: 0;
  }
}

/* toggles and nested fields */
.nested-fields {
  margin: -24px;
  padding-block: 24px 0;
  padding-inline: 24px;

  &.hidden {
    display: block;
    height: 0;
  }

  & > .field:last-child {
    padding-block-end: 24px;
  }
}

/* checkbox */
input.checkbox {
  opacity: 0;
  position: absolute;
  width: var(--checkbox-size);
  height: var(--checkbox-size);
}

input.checkbox + label,
div.checkbox {
  display: inline-block;
  clear: none;
  position: relative;
  padding-inline-start: calc(1rem + 5px);
  line-height: 1rem;
  min-height: 1rem;
  cursor: pointer;

  &,
  &::before {
    // set the border radius on the container too, for (some) focus rings
    border-radius: var(--small-border-radius);
  }

  &::before {
    display: block;
    position: absolute;
    inset-inline-start: 0;
    inset-block-start: 0;
    width: var(--checkbox-size) !important;
    height: var(--checkbox-size);
    box-sizing: border-box;
    content: '';
    font-size: 0;
    background-color: hsl(212deg 50% 99%);
    border: 1px solid color.adjust(mixins.$inputColor, $alpha: -0.6);
    background-clip: padding-box;
  }

  &:empty {
    padding-inline-start: var(--checkbox-size);

    &::after {
      content: '';
      font-size: 0;
    }
  }

  .info {
    height: 16px;
  }
}

input.checkbox:disabled + label,
.disabled div.checkbox {
  cursor: not-allowed;
}

input.checkbox:checked + label::before,
div.checkbox.checked::before,
.sel div.checkbox::before,
input.checkbox:indeterminate + label::before,
div.checkbox.indeterminate::before,
.elementselectormodal
  .body
  .content
  .main
  .elements
  .disabled
  .checkbox::before {
  @include mixins.icon;
  line-height: var(--checkbox-size);
  color: var(--gray-900);
}

input.checkbox:checked:not(:indeterminate) + label::before,
div.checkbox.checked:not(.indeterminate)::before,
.sel:not(.matrixblock) div.checkbox:not(.indeterminate)::before,
.sel.matrixblock > .actions div.checkbox:not(.indeterminate)::before,
.elementselectormodal
  .body
  .content
  .main
  .elements
  .disabled
  .checkbox::before {
  content: 'check';
  font-size: 14px;
}

input.checkbox:indeterminate + label::before,
div.checkbox.indeterminate::before {
  content: 'minus';
  font-size: 7px;
  text-align: center;
}

body:not(.reduce-focus-visibility) {
  input.checkbox:focus + label::before,
  :focus div.checkbox::before {
    @include mixins.input-focused-styles;
  }
}

body.reduce-focus-visibility {
  input.checkbox:focus-visible + label::before,
  :focus-visible div.checkbox::before {
    @include mixins.input-focused-styles;
  }
}

.checkbox-icon {
  display: inline-flex;
  padding: 3px;
  background-color: var(--enabled-color);
  color: var(--white);
  border-radius: var(--small-border-radius);

  &::before {
    --checkbox-size: 14px;
    @include mixins.icon;
    content: 'check';
    line-height: var(--checkbox-size);
    font-size: var(--checkbox-size);
  }
}

.checkbox-preview-label {
  display: none;
}

.monaco-mouse-cursor-text {
  @include mixins.focus-styles {
    box-shadow: none !important;
  }
}

fieldset {
  .checkboxfield {
    margin-block: 5px;
    margin-inline: 0;
  }
}

.checkboxfield {
  .instructions,
  .notice,
  .warning {
    margin-block-start: 2px;
    padding-inline-start: calc(1rem + 5px);
  }
}

/* radio */
input.radio {
  opacity: 0;
  position: absolute;
  width: var(--radio-size);
  height: var(--radio-size);
}

input.radio + label,
div.radio {
  display: inline-block;
  clear: none;
  position: relative;
  padding-inline-start: calc(1rem + 5px);
  line-height: calc(24 / 14);
  cursor: pointer;
}

input.radio:disabled + label,
.disabled div.radio {
  cursor: not-allowed;
}

input.radio + label:empty,
div.radio:empty {
  padding-inline-start: var(--radio-size);
}

/* fixes a RTL bug */
input.radio + label::before,
input.radio + label::after,
div.radio::before,
div.radio::after {
  display: block;
  position: absolute;
  content: '';
  box-sizing: border-box;
  background-clip: padding-box;
  border-radius: 100%;
}

input.radio + label::before,
div.radio::before {
  inset-block-start: 50%;
  inset-inline-start: 0;
  transform: translateY(-50%);
  width: var(--radio-size);
  height: var(--radio-size);
  background-color: hsl(212deg 50% 99%);
  border: 1px solid color.adjust(mixins.$inputColor, $alpha: -0.6);
}

input.radio + label::after,
div.radio::after {
  inset-block-start: calc(50%);
  inset-inline-start: 4px;
  transform: translateY(-50%);
  width: calc(var(--radio-size) - 8px);
  height: calc(var(--radio-size) - 8px);
}

input.radio:checked + label::after,
div.radio.checked::after,
.sel div.radio::after {
  background: var(--gray-900);
}

body:not(.reduce-focus-visibility) {
  input.radio:focus + label::before,
  :focus div.radio::before {
    @include mixins.input-focused-styles;
  }
}

body.reduce-focus-visibility {
  input.radio:focus-visible + label::before,
  :focus-visible div.radio::before {
    @include mixins.input-focused-styles;
  }
}

/* multiselect */
.multiselect > select {
  color: var(--text-color);
  font-size: 14px;
  appearance: none;

  @include mixins.focus-styles {
    @include mixins.input-focused-styles;
  }

  option {
    padding-block: 1px;
    padding-inline: 8px;
  }
}

.text,
.selectize.multiselect .selectize-input {
  padding-block: 6px;
}

.text:not(.color-input),
.selectize.multiselect .selectize-input {
  padding-inline: 9px;
}

.text {
  background-color: var(--white);

  &:not(.small) {
    box-sizing: border-box;
    min-height: calc(34rem / 16);
  }

  &.small {
    padding: 3px;
  }

  &.readable {
    padding-block: 16px;
    padding-inline: 18px;
    font-size: 16px;
    line-height: 22px;

    & + .chars-left {
      margin-block-start: -23px;
    }
  }

  &.clearable {
    padding-inline-end: var(--touch-target-size);
  }

  input {
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;
  }
}

td.errors .text,
.input.errors > .text,
.input.errors > .border-box,
.input.errors > .passwordwrapper,
.input.errors > .autosuggest-container .text,
.text.error {
  border: 1px solid var(--error-color) !important;
}

.texticon {
  position: relative;
  cursor: text;
  min-width: 130px;

  &.icon {
    &::before {
      position: absolute;
      inset-block-start: calc(50% - 7rem / 16);
      inset-inline-start: 9px;
      color: var(--ui-control-color);
    }
  }

  .texticon-icon {
    position: absolute;
    inset-block-start: calc((34rem / 16) / 2);
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    inset-inline-start: 9px;
  }

  .text {
    padding-inline-start: calc(26rem / 16);
  }

  .clear-btn {
    position: absolute;
    inset-block-start: calc(
      (var(--touch-target-size) - 34px) / -2
    ); /* Responds to min-height of text input */
    inset-inline-end: 0;
    width: var(--touch-target-size);
    height: var(--touch-target-size);
  }

  &.has-filter-btn {
    .text {
      padding-inline-end: 26px;
    }

    .filter-btn {
      position: absolute;
      inset-block-start: calc((34rem / 16) / 2);
      transform: translateY(-50%);
      inset-inline-end: 4px;
      padding-block: 0 4px;
      padding-inline: 7px;
      font-size: 14px;
      line-height: 22px;
      cursor: pointer;
      border-radius: 13px;

      &::before {
        @include mixins.icon;
        content: 'filter';
        color: var(--medium-text-color);
      }

      &:hover {
        background-color: var(--gray-100);
      }

      &:active,
      &.active,
      &[aria-expanded='true'] {
        background-color: var(--gray-350);

        &::before {
          color: var(--white);
        }
      }
    }

    .text {
      &.clearable {
        padding-inline-end: calc(30px + var(--touch-target-size));
      }
    }

    .clear-btn {
      inset-inline-end: 30px;
    }
  }
}

.texthint-container {
  position: relative;
  height: 0;
}

.texthint {
  position: absolute;
  inset-block-start: -1px;
  width: 100%;
  color: var(--light-text-color);
  cursor: text;
}

.passwordwrapper {
  position: relative;

  .password {
    border: none;
    background: transparent;
    padding-inline-end: 4rem;
    box-shadow: none;
  }

  .password-toggle {
    color: var(--link-color);
    position: absolute;
    inset-block: 0;
    inset-inline-end: 9px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.datetimewrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 5px;
}

.clear-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  cursor: pointer;
  color: var(--ui-control-color);
  border: none;
  padding: 0;
  background: transparent;

  &::before {
    @include mixins.icon;
    content: 'remove';
  }

  &:hover {
    color: var(--ui-control-hover-color);
  }

  &:active {
    color: var(--ui-control-active-color);
  }
}

.close-btn {
  position: relative;

  &::before,
  &::after {
    position: absolute;
    content: '';
    inset-block-start: 50%;
    inset-inline-start: 50%;
    height: 15px;
    width: 2px;
    background-color: var(--ui-control-color);
  }

  &::before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:hover {
    &::before,
    &::after {
      background-color: var(--ui-control-hover-color);
    }
  }

  &:active {
    &::before,
    &::after {
      background-color: var(--ui-control-active-color);
    }
  }
}

.datewrapper,
.timewrapper {
  display: inline-block;
  position: relative;

  .text {
    position: relative;
    z-index: 1;
    width: 100%;

    & + div[data-icon] {
      display: none;
    }

    &:placeholder-shown,
    &.empty-value {
      & + div[data-icon] {
        display: block;
        position: absolute;
        inset-block-start: calc(50% - 12px);
        inset-inline-start: 14px;
        z-index: 0;
        color: var(--light-text-color);

        &,
        &::before {
          user-select: none;
          pointer-events: none;
          z-index: 1;
        }
      }
    }
  }
}

.datewrapper {
  width: 8em;
}

.timewrapper {
  width: 7em;
}

@include mixins.placeholder-styles(var(--gray-400));

/* Kill IE's special text features */
::-ms-reveal,
::-ms-clear {
  display: none;
}

/* Asset indexing related */
tr.indexingSession td {
  height: 34px;
  padding-block: 1px;
  padding-inline: 10px;
}

tr.indexingSession td.progress div.progressContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

tr.indexingSession td.progress div.progressContainer .progressbar {
  width: 70%;
  position: relative;
  inset-inline-start: 0;
  height: 12px;
  z-index: 1;
}

tr.indexingSession td.progress div.progressContainer div.progressInfo {
  width: 20%;
}

// Selects
.select:not(.selectize),
.select:not(.selectize) select {
  @include mixins.select-styles;
}

.select:not(.selectize) {
  @include mixins.select-container-styles;
}

.select:not(.selectize)::after {
  @include mixins.select-arrow-styles;
}

.select:not(.selectize) select {
  @include mixins.select-input-styles;
  white-space: pre;
}

.select:not(.selectize).fullwidth select {
  @include mixins.select-input-fullwidth-styles;
}

.select:not(.selectize) select {
  &:hover {
    @include mixins.select-input-focused-styles;
  }

  @include mixins.focus-styles {
    @include mixins.select-input-focused-styles;
  }
}

.select:not(.selectize).small::after {
  inset-block-start: 9px;
}

.select:not(.selectize).small select {
  padding-block: 4px !important;
  font-size: 11px;
}

.selectize-control .selectize-input.disabled,
.custom-select.disabled {
  opacity: 1;
  cursor: not-allowed !important;
  pointer-events: auto !important;

  * {
    cursor: not-allowed !important;
    pointer-events: auto !important;
  }
}

.selectize .selectize-input {
  display: block;
  border-color: inherit;
  box-shadow: none;
  background-color: transparent;

  .item {
    max-width: 100%;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.selectize.select .selectize-input::after {
  display: none;
}

body .selectize-dropdown {
  border: none;
  z-index: 101;
}

/* single select styles */
.selectize.select {
  @include mixins.placeholder-styles(var(--gray-700));
  height: 34px;

  &:not(.fullwidth) {
    width: 25em;
    max-width: 100%;
  }
}

.datetimewrapper .selectize.select:not(.fullwidth) {
  max-width: calc(100% - 29px);
}

.selectize.select .selectize-control {
  @include mixins.select-container-styles;

  &::after {
    @include mixins.select-arrow-styles;
  }
}

.selectize.select .selectize-input {
  @include mixins.select-styles;

  &.focus {
    box-shadow: var(--focus-ring);
  }
}

.selectize.select .selectize-input {
  @include mixins.select-input-styles;
}

.selectize.select .selectize-control,
.selectize.select .selectize-input {
  width: 100%;
}

/* multi select styles */
.selectize.multiselect .selectize-input {
  min-height: 34px;
  padding-block-end: 3px;

  &.focus {
    @include mixins.input-focused-styles;
  }

  & > .item {
    @include mixins.token-styles;
    display: inline-flex;
    flex-direction: row;
    padding-block: 3px !important;
    padding-inline: 7px !important;

    &.active {
      @include mixins.active-token-styles;
    }

    & > .remove {
      position: static;
      margin-inline: 3px -3px;
      margin-block: -1px 0;
      border-inline-start: none;
      padding: 0;
      font-size: 0;
      color: var(--ui-control-color);

      &:hover {
        color: var(--ui-control-hover-color);
        background-color: transparent;
      }

      &::before {
        font-size: 14px;
        @include mixins.icon;
        content: 'remove';
      }
    }
  }
}

/* menu styles */
body {
  .selectize-dropdown {
    margin-block-start: 1px;

    [data-selectable],
    .option,
    .optgroup-header {
      @include mixins.menu-item-styles;
    }

    .optgroup {
      &:not(:first-child) {
        border-block-start: 1px solid var(--hairline-color);
      }

      padding-block-start: 5px;

      .option {
        padding-inline-start: 24px;
      }
    }

    .optgroup-header {
      @include mixins.h6-styles;
      margin: 0;
      padding-block: 4px;
      padding-inline: 0;
    }

    .active:not(.selected),
    .option:hover {
      background-color: var(--gray-100);
    }

    .selected {
      @include mixins.menu-item-active-styles;
      cursor: default !important;

      .light {
        color: inherit !important;
      }
    }
  }

  .selectize-dropdown-content {
    @include mixins.menu-styles;
    max-height: 70vh;

    .option {
      min-height: 18px;
    }

    & > div[data-value='new'] {
      &::before {
        @include mixins.icon;
        content: 'plus';
        margin-inline-end: 5px;
      }

      &::after {
        content: '…';
      }
    }
  }

  &.no-scroll .selectize-dropdown-content {
    max-height: 200px; // default for selectize
  }
}

/* datepicker */
.ui-datepicker {
  position: fixed;
  inset-block-start: -300px;
  margin-inline-start: 1px;
  width: 210px;
  height: 242px;
  z-index: 101 !important;
}

.ui-datepicker-header {
  padding-block: 8px 4px;
  padding-inline: 8px;
}

.ui-datepicker-prev {
  float: inline-start;
}

.ui-datepicker-next {
  float: inline-end;
}

.ui-datepicker-prev span,
.ui-datepicker-next span {
  display: none;
}

.ui-datepicker-prev,
.ui-datepicker-next {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover::after {
    border-color: var(--link-color);
  }
}

.ui-datepicker-prev::after {
  @include mixins.angle(left);
}

.ui-datepicker-next::after {
  @include mixins.angle(right);
}

.ui-datepicker-title {
  text-align: center;
}

.ui-datepicker-calendar th,
.ui-datepicker-calendar td {
  padding: 2px !important;
}

.ui-datepicker-calendar th span,
.ui-datepicker-calendar td a {
  display: block;
  width: calc(26 / 16 * 1rem);
  line-height: 26px;
  text-align: center;
  color: var(--text-color);
}

.ui-datepicker-calendar th span {
  color: var(--medium-text-color);
  font-weight: normal;
}

.ui-datepicker-calendar td a {
  border-radius: 2px;
}

.ui-datepicker-calendar td a:hover {
  background-color: var(--light-sel-color);
  text-decoration: none;
}

.ui-datepicker-calendar td a.ui-state-active {
  background-color: var(--dark-sel-color);
  color: var(--white);
  @include mixins.light-on-dark-text;
  cursor: default;
}

.ui-datepicker-calendar td.ui-datepicker-today a {
  border-radius: 13px;
  box-shadow: inset 0 0 0 2px var(--light-sel-color);
}

/* timepicker */
.ui-timepicker-wrapper {
  z-index: 101;
}

.ui-timepicker-list {
  margin-inline-start: 1px;
  overflow-y: auto;
  width: calc(14px + 6em);
  height: 210px;
  z-index: 100;
}

.ui-timepicker-list li {
  padding-inline: 14px 0;
  padding-block: 2px;
  white-space: nowrap;
  cursor: pointer;
}

.ui-timepicker-list li:hover {
  background-color: var(--light-sel-color);
}

.ui-timepicker-list li.ui-timepicker-selected {
  background-color: var(--dark-sel-color);
  color: var(--white);
  @include mixins.light-on-dark-text;
  cursor: default;
}

/* slide picker */
.slide-picker {
  display: flex;
  height: 1rem;
  white-space: nowrap;

  a {
    position: relative;
    border: 1px solid var(--hairline-color);
    border-inline-start: none;
    background-image: none;
    width: calc(8rem / 16 - 1px);
    height: calc(1rem - 2px);
    margin-inline-start: 0;
    margin-block: 1px;
    box-sizing: border-box;
    cursor: pointer;

    &:first-child {
      width: calc(8rem / 16);
      border-inline-start: 1px solid var(--gray-400) !important;
    }

    &::before {
      content: '';
      position: absolute;
      inset-block-start: calc(-4rem / 16 - 1px);
      inset-inline-start: -1px;
      width: calc(100% + 2px);
      height: var(--touch-target-size);
    }
  }

  &:not(:hover) a.active,
  &:hover a.active-hover {
    border-block-start-color: var(--gray-400);
    border-block-end-color: var(--gray-400);
    height: 1rem;
    margin-block: 0;

    &:first-child {
      border-inline-start: 1px solid var(--gray-400);
      border-start-start-radius: var(--small-border-radius);
      border-end-start-radius: var(--small-border-radius);
    }
  }

  &:not(:hover) a.last-active,
  &:hover a.last-active-hover {
    border-inline-end: 1px solid var(--gray-400);
    border-start-end-radius: var(--small-border-radius);
    border-end-end-radius: var(--small-border-radius);
  }

  &:focus {
    a.active {
      background-color: var(--light-sel-color);
    }
  }
}

/* icon picker */
.icon-picker {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--xs);
}

.icon-picker--icon {
  display: flex;
  align-items: center;
  justify-content: center;
  @include mixins.input-styles;
  border-radius: var(--ui-control-border-radius);
  width: var(--ui-control-height);
  height: var(--ui-control-height);
  background: var(--gray-050);

  svg {
    width: calc(20rem / 16);
    height: calc(20rem / 16);
    @include mixins.svg-mask(var(--ui-control-color));
  }
}

.icon-picker-modal {
  --width: calc(var(--ui-control-height) * 10 + var(--s) * 9 + var(--xl) * 2);
  width: var(--width) !important;
  min-width: 0 !important;
  max-width: calc(100% - 20px) !important;

  .body {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--l);

    .icon-picker-modal--list {
      flex: 1;
      position: relative;
      overflow: hidden;

      &:not(.loading) {
        .spinner {
          display: none;
        }
      }

      &.loading {
        &::after {
          position: absolute;
          inset-block-start: 0;
          inset-inline-start: 0;
          width: 100%;
          height: 100%;
          content: '';
          background-color: rgb(255 255 255 / 75%);
        }

        .spinner {
          inset-block-start: calc(50% - 10px);
          z-index: 1;
        }
      }

      ul {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        gap: var(--s);
        max-height: 100%;
        overflow: auto;

        .icon-picker--icon {
          --focus-ring: var(--inner-focus-ring);

          &:hover {
            border-color: var(--link-color);
            background-color: var(--blue-100);
          }
        }
      }
    }
  }
}

/* errors */
ul.errors {
  margin-block-start: 5px;
  list-style-type: square;
  padding-inline-start: 20px;
}

ul.errors li {
  color: var(--error-color);
}

/* message pages */
.message-container {
  position: absolute;
  z-index: 100;
  inset-block-start: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 100%;

  &.no-access {
    background-color: color.adjust(mixins.$grey900, $alpha: -0.5);
  }

  .pane {
    inset-block-start: 50%;
    margin-block-start: -33px !important;
    margin-inline: auto;
    width: 320px;
    box-shadow: 0 25px 100px color.adjust(mixins.$grey900, $alpha: -0.5);
  }
}

/* 2FA setup page */
body.setup-2fa {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;

  #setup-2fa {
    display: flex;
    flex-direction: column;
    gap: var(--l);
    width: 550px;
    max-width: 100%;
    padding: var(--padding);

    #setup-2fa-intro {
      text-align: center;
    }

    #setup-2fa-btn {
      display: flex;
      justify-content: center;
    }
  }
}

/* licensing issues page */
body.licensing-issues {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  padding: var(--padding);

  #licensing-issues {
    display: flex;
    flex-direction: row;
    max-width: 50em;

    .buttons {
      justify-content: space-between;

      [data-icon] {
        margin-inline: 0;
      }
    }
  }

  #continue {
    margin: 0;
  }
}

/* auto-suggest */
.autosuggest-container {
  position: relative;
}

.autosuggest__results-container {
  position: absolute;
  z-index: 2;
  width: 100%;
  border-radius: var(--large-border-radius);
  background-color: var(--white);
  @include mixins.shadow;
  box-sizing: border-box;
  padding-block: 0;
  padding-inline: 14px;
  text-align: start;
  @include mixins.sans-serif-font;
  font-size: 1em !important;

  .autosuggest__results-before {
    @include mixins.h6-styles;
    margin-block-start: 14px !important;
  }

  .autosuggest__results-item {
    @include mixins.menu-item-styles;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover,
    &.autosuggest__results-item--highlighted {
      @include mixins.menu-item-active-styles;
      cursor: pointer;
    }
  }
}

/* ----------------------------------------
/* Matrix
/* ---------------------------------------- */

/* Matrix fields */
.matrix {
  & > .buttons {
    margin-block-start: 10px;
  }
}

.matrixblock {
  position: relative;
  margin-block-end: 10px;
  padding-block: 0 var(--m);
  padding-inline: var(--m);
  border-radius: var(--large-border-radius);
  border: 2px solid var(--gray-100);
  background-color: var(--white);

  &.static {
    .titlebar {
      padding-inline: 14px 0;
      margin-block-end: 14px;
    }
  }

  & > .titlebar > .matrixblock-tabs .pane-tabs .menubtn:not(.action-btn),
  & > .actions .action-btn {
    padding: 0;
    width: var(--touch-target-size);
    height: var(--touch-target-size);
    color: var(--custom-text-color, var(--ui-control-color));
    background-color: transparent;

    &:hover {
      backdrop-filter: brightness(90%);
    }

    &:active,
    &[aria-expanded='true'] {
      backdrop-filter: brightness(85%);
    }
  }

  .flex-fields {
    --row-gap: var(--m) !important;
    --padding: var(--m);
  }

  // no margin when a .field comes after a hidden item.
  .hidden + .field {
    margin-block-start: 0;
  }

  & > .titlebar {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: var(--m);
    margin-block: 0;
    margin-inline: calc(var(--m) * -1);
    box-sizing: border-box;
    border-radius: calc(var(--large-border-radius) - 2px)
      calc(var(--large-border-radius) - 2px) 0 0;
    padding-inline: var(--m) calc(var(--m) + 70px);
    padding-block: 5px;
    color: var(--custom-text-color, var(--light-text-color));
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-wrap: normal;
    cursor: default;
    user-select: none;
    position: relative;
    background-color: var(--gray-050);
    min-height: calc(var(--lh) + 10px);

    & > .preview {
      flex-grow: 1;
      min-width: 0;
      opacity: 0;
      transition: opacity linear 100ms;
      overflow: hidden;

      &:empty {
        display: none;
      }

      body.ltr & {
        mask-image: linear-gradient(
          to left,
          rgb(0 0 0 / 0%),
          rgb(0 0 0 / 100%) 12px
        );
      }

      body.rtl & {
        mask-image: linear-gradient(
          to right,
          rgb(0 0 0 / 0%),
          rgb(0 0 0 / 100%) 12px
        );
      }

      span {
        opacity: 0.5;
      }
    }

    & > .matrixblock-tabs {
      flex: 1;
      min-width: 0;
      margin-block: -5px;
      margin-inline: 0;

      .pane-tabs {
        margin: 0;
        padding-inline-end: 3px;

        [role='tablist'] {
          padding-block: 0;
          padding-inline: 12px;

          &.scrollable {
            mask-image: linear-gradient(
              to right,
              rgb(0 0 0 / 0%),
              rgb(0 0 0) 12px,
              rgb(0 0 0) calc(100% - 12px),
              rgb(0 0 0 / 0%) 100%
            );
          }

          [role='tab'] {
            background-color: transparent;

            .tab-label {
              height: calc(var(--lh) + 10px);
              padding-block: 0;
              padding-inline: var(--m);
            }
          }
        }

        .menubtn:not(.action-btn) {
          margin-inline: 0;

          &:not(:empty, .btn-empty)::after {
            margin-inline-start: 0;
          }
        }
      }
    }

    & > .preview:first-child:empty + .matrixblock-tabs {
      margin-inline-start: calc((var(--m) + 12px) * -1);

      [role='tab']:first-child {
        &,
        .tab-label {
          border-start-start-radius: calc(var(--large-border-radius) - 2px);
        }
      }
    }
  }

  &.disabled-entry {
    & > .titlebar {
      padding-inline-end: 100px;
    }

    & > .actions {
      & > .status {
        &.off {
          display: block;
        }
      }
    }
  }

  &.collapsed {
    & > .titlebar {
      border-radius: calc(var(--large-border-radius) - 2px);

      & > .preview {
        opacity: 1;
      }
    }
  }

  & > .actions {
    display: flex;
    align-items: center;
    gap: var(--xs);
    position: absolute;
    inset-block-start: 0;
    inset-inline-end: var(--m);
    cursor: default;
    min-height: calc(var(--lh) + 10px);

    & > * {
      margin: 0 !important;
    }

    & > .status {
      &.off {
        display: none;
      }
    }

    a:not([data-action]) {
      padding: 0;
      height: var(--touch-target-size);
      text-align: center;
      color: var(--dark-hairline-color);
      transform: color linear 100ms;

      &:hover {
        color: var(--link-color);

        &.settings::after {
          border-color: var(--link-color);
        }
      }
    }
  }

  &:not(.static) {
    & > .fields {
      padding-block-start: var(--l);
    }
  }

  & > .fields > .flex-fields > .field {
    &::before {
      display: none;
    }

    & > .status-badge {
      inset-inline-start: -2px;
    }
  }

  & > .buttons {
    margin-block-start: 0;
    height: 30px;
  }
}

/* categories */
.add-category-form {
  margin-block-start: 24px;
}

.add-category-form .texticon {
  width: 200px;
  float: inline-start;
  margin-inline-end: 5px;
}

.add-category-form .texticon .text {
  padding-inline-end: 30px;
}

.add-category-form .texticon .spinner {
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: 5px;
}

/* site pickers */
body.sitepicker {
  #main-content {
    padding: 30px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  #content-container {
    max-width: 400px;
  }
}

.sitepicker-group {
  li {
    &:not(:first-child) {
      a {
        margin-block-start: -1px;
        border-start-start-radius: 0;
        border-start-end-radius: 0;

        &:not(:hover) {
          border-block-start-color: transparent;
        }
      }
    }

    &:not(:last-child) {
      a {
        border-end-start-radius: 0;
        border-end-end-radius: 0;
      }
    }

    a {
      display: block;
      position: relative;
      text-align: start;
      width: 100%;
      box-sizing: border-box;
      border: 1px solid var(--hairline-color);
      border-radius: var(--medium-border-radius);
      padding-inline: 15px 42px;
      padding-block: 9px;
      font-size: 16px;
      line-height: 1.4;

      &::after {
        font-size: 14px;
        position: absolute;
        inset-block-start: calc(50% - 7px);
        inset-inline-end: 12px;
        margin: 0;
        padding: 0;
      }

      &:hover {
        border-color: var(--link-color);
        text-decoration: none;
        z-index: 1;
      }
    }
  }
}

.entry-mover-modal {
  --width: calc(var(--ui-control-height) * 10 + var(--s) * 9 + var(--xl) * 2);
  width: var(--width) !important;
  min-width: 0 !important;
  max-width: calc(100% - 20px) !important;
  max-height: 200px;

  .header {
    padding-block: calc(var(--m));
    text-align: center;
  }

  .body {
    .entry-mover-modal--list {
      flex: 1;
      position: relative;
      overflow: hidden;
    }
  }

  .footer {
    position: absolute;
    inset-block-end: 0;
    inset-inline-start: 0;
    width: 100%;
    margin: 0;
    box-sizing: border-box;
  }
}

/* ----------------------------------------
/* IE hacks
/* ---------------------------------------- */

/* Fix layout of modal element selectors for IE8 */
.elementselectormodal .body .main {
  float: left \9;
  width: 445px \9;
}

/* ----------------------------------------
/*  Retina graphics
/* ---------------------------------------- */

@media only screen and (resolution >= 1.5dppx) {
  .structure ul li {
    background-size: 40px;

    body.ltr & {
      background-image: url('../images/branch_2x.png');
    }

    body.rtl & {
      background-image: url('../images/branch_rtl_2x.png');
    }
  }

  .hud .tip-left {
    background-image: url('../images/hudtip_left_2x.png');
    background-size: 15px 30px;
  }

  .hud .tip-top {
    background-image: url('../images/hudtip_top_2x.png');
    background-size: 30px 15px;
  }

  .hud .tip-right {
    background-image: url('../images/hudtip_right_2x.png');
    background-size: 15px 30px;
  }

  .hud .tip-bottom {
    background-image: url('../images/hudtip_bottom_2x.png');
    background-size: 30px 15px;
  }

  .hud.has-footer .tip-bottom {
    background-image: url('../images/hudtip_bottom_gray_2x.png');
  }
}
