craft-spinner {
  display: flex;
  justify-content: center;

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
