@use 'sass:color';
@use 'sass:list';
@use 'sass:string';
$white: #fff;
$black: #000;

$grey050: hsl(212, 60%, 97%);
$grey100: hsl(212, 50%, 93%);
$grey200: hsl(212, 30%, 85%);
$grey300: hsl(211, 13%, 65%);
$grey350: hsl(211, 11%, 59%);
$grey400: hsl(210, 10%, 53%);
$grey500: hsl(211, 12%, 43%);
$grey550: hsl(210, 13%, 40%);
$grey600: hsl(209, 14%, 37%);
$grey700: hsl(209, 18%, 30%);
$grey800: hsl(209, 20%, 25%);
$grey900: hsl(210, 24%, 16%);
$grey1000: hsl(210, 24%, 10%);

$red050: #fef2f2;
$red100: #fee2e2;
$red200: #fecaca;
$red300: #fca5a5;
$red400: #f87171;
$red500: #ef4444;
$red600: #dc2626;
$red700: #b91c1c;
$red800: #991b1b;
$red900: #7f1d1d;

$orange050: #fff7ed;
$orange100: #ffedd5;
$orange200: #fed7aa;
$orange300: #fdba74;
$orange400: #fb923c;
$orange500: #f97316;
$orange600: #ea580c;
$orange700: #c2410c;
$orange800: #9a3412;
$orange900: #7c2d12;

$yellow050: #fefce8;
$yellow100: #fef9c3;
$yellow200: #fef08a;
$yellow300: #fde047;
$yellow400: #facc15;
$yellow500: #eab308;
$yellow600: #ca8a04;
$yellow700: #a16207;
$yellow800: #854d0e;
$yellow900: #713f12;

$green050: #f0fdf4;
$green100: #dcfce7;
$green200: #bbf7d0;
$green300: #86efac;
$green400: #4ade80;
$green500: #22c55e;
$green600: #16a34a;
$green700: #15803d;
$green800: #166534;
$green900: #14532d;

$teal050: #f0fdfa;
$teal100: #ccfbf1;
$teal200: #99f6e4;
$teal300: #5eead4;
$teal400: #2dd4bf;
$teal500: #14b8a6;
$teal550: #11a697;
$teal600: #0d9488;
$teal700: #0f766e;
$teal800: #115e59;
$teal900: #134e4a;

$cyan050: #ecfeff;
$cyan100: #cffafe;
$cyan200: #a5f3fc;
$cyan300: #67e8f9;
$cyan400: #22d3ee;
$cyan500: #06b6d4;
$cyan600: #0891b2;
$cyan700: #0e7490;
$cyan800: #155e75;
$cyan900: #164e63;

$blue050: #eff6ff;
$blue100: #dbeafe;
$blue200: #bfdbfe;
$blue300: #93c5fd;
$blue400: #60a5fa;
$blue500: #3b82f6;
$blue600: #2563eb;
$blue700: #1d4ed8;
$blue800: #1e40af;
$blue900: #1e3a8a;

$violet050: #f5f3ff;
$violet100: #ede9fe;
$violet200: #ddd6fe;
$violet300: #c4b5fd;
$violet400: #a78bfa;
$violet500: #8b5cf6;
$violet600: #7c3aed;
$violet700: #6d28d9;
$violet800: #5b21b6;
$violet900: #4c1d95;

$pink050: #fdf2f8;
$pink100: #fce7f3;
$pink200: #fbcfe8;
$pink300: #f9a8d4;
$pink400: #f472b6;
$pink500: #ec4899;
$pink600: #db2777;
$pink700: #be185d;
$pink800: #9d174d;
$pink900: #831843;

// submit button colors
$primaryColor: $red600;
$secondaryColor: $grey500;

$inputColor: hsl(212, 25%, 50%);

// text colors
$textColor: $grey700;
$mediumDarkTextColor: $grey550;
$mediumTextColor: $grey550;
$lightTextColor: $grey500;
$linkColor: #1f5fea;

// menu colors
$menuOptionColor: $textColor;
$menuOptionActiveColor: $textColor;
$menuOptionActiveBackgroundColor: $grey050;

// hairline colors
$hairlineColor: color.adjust($grey800, $alpha: -0.9);
$mediumHairlineColor: color.adjust($grey600, $alpha: -0.75);
$darkHairlineColor: color.adjust($grey400, $alpha: -0.5);

// focus colors
$lightFocusColor: $blue300;
$mediumFocusColor: $blue500;
$darkFocusColor: #0f74b1;

// focus rings
$lightFocusRing:
  0 0 0 1px $lightFocusColor,
  0 0 0 3px color.adjust($lightFocusColor, $alpha: -0.3);
$mediumFocusRing:
  0 0 0 1px $mediumFocusColor,
  0 0 0 3px color.adjust($mediumFocusColor, $alpha: -0.3);
$darkFocusRing:
  0 0 0 1px $darkFocusColor,
  0 0 0 3px color.adjust($darkFocusColor, $alpha: -0.3);

// selection colors
$lightSelColor: $grey200;
$darkSelColor: $grey500;

// alert/notice colors
$errorColor: $red600;
$warningColor: $yellow800;
$successColor: $teal500;
$noticeColor: $blue800;

// UI element styles
$smallBorderRadius: 3px;
$mediumBorderRadius: 4px;
$largeBorderRadius: 5px;

$menuBorderRadius: $mediumBorderRadius;

@mixin sans-serif-font {
  font-family:
    system-ui,
    BlinkMacSystemFont,
    -apple-system,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
}

@mixin fixed-width-font {
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier,
    monospace;
  font-size: 0.9em !important;
}

@function toRem($values...) {
  @warn "torem mixin is deprecated. Use calc($value / 16 * 1rem) instead.";
  $max: list.length($values);
  $remValues: '';

  @for $i from 1 through $max {
    $remValues: #{$remValues + calc(list.nth($values, $i) / 16)}rem;

    @if $i < $max {
      $remValues: #{$remValues + ' '};
    }
  }

  @return $remValues;
}

@mixin fontSize($size) {
  @warn "fontsize mixin is deprecated. Use CSS values instead.";
  font-size: toRem($size);
}

// Other

@mixin focus-styles {
  body:not(.reduce-focus-visibility) &:focus,
  body.reduce-focus-visibility &:focus-visible {
    @content;
  }
}

@mixin svg-mask($color) {
  rect,
  circle,
  ellipse,
  line,
  polyline,
  polygon,
  path,
  text {
    fill: $color;
    stroke-width: 0;
  }
}

@mixin icon {
  font-family: 'Craft';
  speak: never;
  -webkit-font-feature-settings: 'liga', 'dlig';
  -moz-font-feature-settings: 'liga=1, dlig=1';
  -moz-font-feature-settings: 'liga', 'dlig';
  -ms-font-feature-settings: 'liga', 'dlig';
  -o-font-feature-settings: 'liga', 'dlig';
  font-feature-settings: 'liga', 'dlig';
  text-rendering: optimizeLegibility;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  direction: ltr; // Fixes a rendering issue in Chrome/Win
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  display: inline-block;
  text-align: center;
  font-style: normal;
  vertical-align: middle;
  word-wrap: normal !important;
  user-select: none;
}

@mixin angle($dir: down, $color: currentColor, $width: calc(2rem / 16)) {
  display: block;
  content: '';
  font-size: 0;
  width: calc(7rem / 16);
  height: calc(7rem / 16);
  border: solid $color;
  border-width: 0 $width $width 0;
  opacity: 0.8;
  position: relative;

  @if $dir == up {
    transform: rotate(225deg);
    inset-block-start: 2px;
  } @else if $dir == down {
    transform: rotate(45deg);
  } @else if $dir == left {
    body.ltr & {
      transform: rotate(135deg);
    }
    body.rtl & {
      transform: rotate(-45deg);
    }
  } @else if $dir == right {
    body.ltr & {
      transform: rotate(-45deg);
    }
    body.rtl & {
      transform: rotate(135deg);
    }
  }
}

@mixin clearafter {
  content: '';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

@mixin shadow {
  box-shadow: 0 1px 5px -1px color.adjust($grey900, $alpha: -0.8);
}

@mixin pane {
  background: $white;
  box-shadow:
    0 0 0 1px $grey200,
    0 2px 12px color.adjust($grey200, $alpha: -0.5);

  &:focus {
    box-shadow: var(--focus-ring);
  }
}

@mixin modal {
  border-radius: $largeBorderRadius;
  background-color: $white;
  box-shadow: 0 25px 100px color.adjust($grey900, $alpha: -0.5) !important;
}

@mixin light-on-dark-text() {
  // Make light on dark text sharp on Macs
  // (sub-pixel antialiasing looks too bold/blurry with light text on dark background)
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

@mixin light-focus-ring() {
  --focus-ring: 0 0 0 1px
      hsl(
        #{color.hue($blue200)},
        #{color.saturation($blue200)},
        #{color.lightness($blue200)}
      ),
    0 0 0 3px hsla(var(--light-focus-hsl), 1);
}

@mixin dark-btn-light-bg-focus-ring() {
  --focus-ring: 0 0 0 1px
      hsl(
        #{color.hue($blue200)},
        #{color.saturation($blue200)},
        #{color.lightness($blue200)}
      ),
    0 0 0 3px hsla(var(--dark-focus-hsl), 0.8);
}

@mixin custom-color-focus-ring($primary, $secondary: null) {
  @if $secondary != null {
    --focus-ring: 0 0 0 1px #{$primary}, 0 0 0 3px #{$secondary};
  } @else {
    --focus-ring: 0 0 0 3px #{$primary};
  }
}

@mixin two-color-focus-ring($light-button: true) {
  // Creates a two-color focus ring, with a white
  // If button is light, the dark box shadow is adjacent to the button
  // Else the light box shadow is adjacent to the button
  --light-color: var(--white);
  --dark-color: var(--gray-800);

  @if $light-button {
    --focus-ring: 0 0 0 3px var(--dark-color), 0 0 0 6px var(--light-color);
  } @else {
    --focus-ring: 0 0 0 3px var(--light-color), 0 0 0 6px var(--dark-color);
  }
}

// RTL stuff

@mixin left($left) {
  @warn "left mixin is deprecated. Use inset-inline-start instead.";
  inset-inline-start: $left;
}

@mixin right($right) {
  @warn "right mixin is deprecated. Use inset-inline-end instead.";
  inset-inline-end: $right;
}

@mixin alignleft {
  @warn "alignleft mixin is deprecated. Use text-align: start instead.";
  text-align: start;
}

@mixin alignright {
  @warn "alignright mixin is deprecated. Use text-align: end instead.";
  text-align: end;
}

@mixin border-left($params...) {
  @warn "border-left mixin is deprecated. Use border-inline-start instead.";
  border-inline-start: $params;
}

@mixin border-right($params...) {
  @warn "border-right mixin is deprecated. Use border-inline-end instead.";
  border-inline-end: $params;
}

@mixin border-left-width($param) {
  @warn "border-left-width mixin is deprecated. Use border-inline-start-width instead.";
  border-inline-start-width: $param;
}

@mixin border-right-width($param) {
  @warn "border-right-width mixin is deprecated. Use border-inline-end-width instead.";
  border-inline-end-width: $param;
}

@mixin border-radius($tl, $tr, $br, $bl) {
  @warn "border-radius mixin is deprecated. Use logical border properties instead.";
  border-start-start-radius: $tl;
  border-start-end-radius: $tr;
  border-end-end-radius: $br;
  border-end-start-radius: $bl;
}

@mixin border-top-left-radius($params...) {
  @warn "border-top-left-radius mixin is deprecated. Use border-start-start-radius instead.";
  border-start-start-radius: $params;
}

@mixin border-top-right-radius($params...) {
  @warn "border-top-right-radius mixin is deprecated. Use border-end-start-radius instead.";
  border-start-end-radius: $params;
}

@mixin border-bottom-left-radius($params...) {
  @warn "border-bottom-left-radius mixin is deprecated. Use border-start-end-radius instead.";
  border-start-end-radius: $params;
}

@mixin border-bottom-right-radius($params...) {
  @warn "border-bottom-right-radius mixin is deprecated. Use border-end-end-radius instead.";
  border-end-end-radius: $params;
}

@mixin floatleft {
  @warn "floatleft mixin is deprecated. Use float: inline-start instead.";
  float: inline-start;
}

@mixin floatright {
  @warn "floatright mixin is deprecated. Use float: inline-end instead.";
  float: inline-end;
}

@mixin margin($t, $r, $b, $l, $important: '') {
  @warn "margin mixin is deprecated. Use logical margin properties instead.";
  margin-block: $t $b string.unquote($important);
  margin-inline: $l $r string.unquote($important);
}

@mixin margin-left($margin...) {
  @warn "margin-left mixin is deprecated. Use margin-inline-start instead.";
  margin-inline-start: $margin;
}

@mixin margin-right($margin...) {
  @warn "margin-left mixin is deprecated. Use margin-inline-end instead.";
  margin-inline-end: $margin;
}

@mixin padding($t, $r, $b, $l, $important: '') {
  @warn "padding mixin is deprecated. Use padding logical properties instead.";
  padding-block: $t $b string.unquote($important);
  padding-inline: $l $r string.unquote($important);
}

@mixin padding-left($padding...) {
  @warn "padding-left mixin is deprecated. Use padding-inline-start properties instead.";
  padding-inline-start: $padding;
}

@mixin padding-right($padding...) {
  @warn "padding-right mixin is deprecated. Use padding-inline-end properties instead.";
  padding-inline-end: $padding;
}

// Misc

@mixin dark-inputs {
  @include placeholder-styles($grey400);

  .btn,
  .select:not(.selectize) select {
    background-color: $grey200;

    &:focus,
    &:hover {
      background-color: color.adjust($grey200, $lightness: -5%);
    }

    &:active,
    &.active,
    &[aria-expanded='true'] {
      background-color: color.adjust($grey200, $lightness: -10%);
    }
  }

  .text {
    background-color: $grey200;

    &:focus {
      background-color: color.adjust($grey200, $lightness: -5%);
    }
  }
}

@mixin header-btn {
  width: calc(30rem / 16);
  height: calc(30rem / 16);
  padding-left: 0;
  padding-right: 0;

  &:not(:hover):not(:active):not(.active):not([aria-expanded='true']) {
    background-color: transparent;
  }
  &:not(:active):not(.active):not([aria-expanded='true']):hover {
    background-color: color.adjust($grey300, $alpha: -0.85);
  }
}

@mixin h6-styles {
  margin: 14px 0 3px;
  font-size: 11px;
  line-height: 1.2;
  color: $lightTextColor;
  text-transform: uppercase;
}

@mixin token-styles {
  display: inline-block;
  border-radius: $smallBorderRadius;
  padding: 3px 7px;
  font-size: 12px;
  line-height: 14px;
  color: $textColor;
  background-color: $grey100;
}

@mixin active-token-styles {
  background-color: $grey200;
}

@mixin menu-styles {
  z-index: 100;
  border-radius: $menuBorderRadius;
  padding: 0 14px;
  overflow: auto;
  background: $white;
  user-select: none;
  box-shadow:
    0 0 0 1px color.adjust($grey900, $alpha: -0.9),
    0 5px 20px color.adjust($grey900, $alpha: -0.75);
}

@mixin menu-item-styles {
  margin: 0 -14px;
  padding: 10px 14px;
  color: $menuOptionColor;
  text-decoration: none;
  white-space: nowrap;
}

@mixin menu-item-active-styles {
  color: var(--white) !important;
  --medium-text-color: rgba(255, 255, 255, 0.8);
  --light-text-color: rgba(255, 255, 255, 0.8);
  background-color: var(--dark-sel-color);

  svg {
    @include svg-mask(var(--white));
  }
}

@mixin disclosure-link-hover-styles {
  color: $menuOptionColor;
  background-color: $grey050;
}

@mixin input-styles {
  border-radius: $smallBorderRadius;
  border: 1px solid color.adjust($inputColor, $alpha: -0.75);
  background-color: hsl(212, 50%, 99%);
  background-clip: padding-box;
}

@mixin input-focused-styles {
  box-shadow: var(--focus-ring);
}

@mixin placeholder-styles($color) {
  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    color: $color;
  }

  input:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    color: $color;
  }

  input::-ms-input-placeholder,
  textarea::-ms-input-placeholder {
    color: $color;
  }

  input:-moz-placeholder,
  textarea:-moz-placeholder {
    color: $color;
  }

  input::-moz-placeholder,
  textarea::-moz-placeholder {
    color: $color;
  }

  input::placeholder,
  textarea::placeholder {
    color: $color;
  }
}

@mixin select-styles {
  position: relative;
  border-radius: $largeBorderRadius;
  white-space: nowrap;
}

@mixin select-container-styles {
  max-width: 100%;
  position: relative;
  :not(.flex) > & {
    display: inline-block;
  }
}

@mixin select-arrow-styles {
  @include angle;
  position: absolute;
  z-index: 1;
  inset-block-start: calc(50% - 5px);
  inset-inline-end: 9px;
  user-select: none;
  pointer-events: none;
  color: var(--ui-control-color);
}

@mixin select-input-styles {
  display: block;
  position: relative;
  max-width: 100%;
  border: none;
  padding-block: 7px;
  padding-inline: 10px 22px;
  font-size: 14px;
  line-height: 20px;
  color: $textColor;
  background-color: hsl(212, 25%, 90%);
  appearance: none;
  // from https://stackoverflow.com/a/15933790/1688568
  &::-ms-expand {
    display: none;
  }
}

@mixin select-input-fullwidth-styles {
  min-width: 100%;
}

@mixin select-input-focused-styles {
  outline-color: transparent;
  background-color: hsl(212, 25%, 85%);
  box-shadow: var(--focus-ring);
}

@mixin touch-target {
  height: var(--touch-target-size);
  width: var(--touch-target-size);
}

// https://tailwindcss.com/docs/screen-readers#undoing-screen-reader-only-elements
@mixin visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

@mixin not-visually-hidden {
  position: static;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

@mixin readable {
  font-size: 16px;
  line-height: 22px;

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    margin: 24px 0 16px;
    font-weight: 600;
  }

  h1,
  .h1 {
    font-size: 32px;
    line-height: 40px;
    color: #000;
  }

  h2,
  .h2 {
    font-size: 24px;
    line-height: 30px;
  }

  h3,
  .h3 {
    font-size: 20px;
    line-height: 24px;
  }

  h4,
  .h4 {
    font-size: 16px;
    line-height: 20px;
  }

  h5,
  .h5 {
    font-size: 14px;
    line-height: 18px;
  }

  h6,
  .h6 {
    font-size: 13.6px;
    line-height: 17px;
    color: $mediumTextColor;
  }

  ul,
  ol {
    margin: 1em 0;
    padding-inline-start: 2em;
  }

  ul li {
    list-style-type: disc;
  }

  li + li {
    margin-top: 0.25em;
  }

  .tip-dismiss-btn {
    position: absolute;
    top: 12px;
    inset-inline-end: 12px;

    & + p {
      margin-top: 0;
    }
  }

  blockquote {
    margin: 16px 0;

    &:not(.note) {
      padding: 0 16px;
      color: $mediumTextColor;
      border-inline-start: 4px solid $hairlineColor;
    }

    &.note {
      position: relative;
      border-radius: 4px;
      padding: 1em;
      padding-inline-start: 50px;
      border: 1px solid;

      &.dismissible {
        padding-inline-end: 36px;
      }

      &:not(.tip):not(.warning) {
        border-color: $errorColor;
        color: #bf503f;

        &:before {
          content: 'alert';
          color: $errorColor;
        }
      }

      &.tip {
        border-color: $linkColor;
        color: $linkColor;

        &:before {
          content: 'lightbulb';
          color: $linkColor;
        }
      }

      &.warning {
        border-color: $warningColor;
        color: #cf783a;

        &:before {
          content: 'alert';
          color: $warningColor;
        }
      }

      &:before {
        @include icon;
        opacity: 1;
        position: absolute;
        top: 15px;
        inset-inline-start: 16px;
        font-size: 24px;
        width: 24px;
      }

      a[href] {
        color: currentColor;
        text-decoration: underline;
      }
    }
  }

  .go:after {
    font-size: 14px;
  }
}

@mixin checkered-bg($size) {
  // h/t https://gist.github.com/dfrankland/f6fed3e3ccc42e3de482b324126f9542
  $halfSize: $size * 0.5;
  background-image: linear-gradient(
      45deg,
      #{color.adjust($grey300, $alpha: -0.75)} 25%,
      transparent 25%
    ),
    linear-gradient(
      135deg,
      #{color.adjust($grey300, $alpha: -0.75)} 25%,
      transparent 25%
    ),
    linear-gradient(
      45deg,
      transparent 75%,
      #{color.adjust($grey300, $alpha: -0.75)} 75%
    ),
    linear-gradient(
      135deg,
      transparent 75%,
      #{color.adjust($grey300, $alpha: -0.75)} 75%
    );
  background-size: $size $size;
  background-position:
    0 0,
    $halfSize 0,
    $halfSize -#{$halfSize},
    0 $halfSize;
}
